import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// const showNotification = (type, message) => {
//     switch (type) {
//         case "success":
//             toast.success(message, {
//                 position: toast.POSITION.TOP_RIGHT,
//             });
//             break;
//         case "danger":
//             toast.error(message, {
//                 position: toast.POSITION.TOP_RIGHT,
//             });
//             break;
//         case "warning":
//             toast.warning(message, {
//                 position: toast.POSITION.TOP_RIGHT,
//             });
//             break;
//         case "default":
//             toast(message, {
//                 position: toast.POSITION.TOP_RIGHT,
//             });
//             break;
//         default:
//             break;
//     }
// };

// export default showNotification;



const showNotification = (type, message) => {
    // Set the time frame for preventing the same message
    const timeFrame = 3000; // 3000 milliseconds (3 seconds)
  
    // Get the last notification details from localStorage
    const lastNotification = JSON.parse(localStorage.getItem('lastNotification')) || {};
  
    // Check if the same message was shown recently
    if (lastNotification.message === message && Date.now() - lastNotification.timestamp < timeFrame) {
      return;
    }
  
    // Show the notification based on the type
    switch (type) {
      case 'success':
        toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        break;
      case 'danger':
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        break;
      case 'warning':
        toast.warning(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        break;
      case 'default':
        toast(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        break;
      default:
        break;
    }
  
    // Update the last notification details in localStorage
    localStorage.setItem('lastNotification', JSON.stringify({ message, timestamp: Date.now() }));
  };
  
  export default showNotification;
  