import React from 'react'

import HeaderTab from "../../layouts/HeaderTab"
// import { useSelector } from 'react-redux'

const Dashboard = () => {
    // const tokenData = useSelector((state) => state.logInToken);
    // console.log(tokenData.logInTokenData);
    return (
        <div>
            <HeaderTab/>
        </div>
    )
}

export default Dashboard;