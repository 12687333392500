import React, { useEffect, useMemo, useState } from "react";

import Chart from 'react-apexcharts';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, makeStyles } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";

import './MainDashboard.css';
import { getCFPListing } from "../../../services/Services";
import { dispatchCFPTableData } from "../../../redux/actions/fundingPlansAction";
import showNotification from "../../common/Notification";

const useStyles = makeStyles({
    oddRows: {
        backgroundColor: '#f9f9f9',
    },
    evenRows: {
        backgroundColor: '#fff',
    },
    hoverRow: {
        '&:hover': {
            backgroundColor: '#f9f9f9'
        }
    },
    tableHead: {
        borderTop: '2px solid #111',
        borderBottom: '2px solid #111 !important',
    },
    tableHeadCells: {
        textAlign: 'center',
        padding: '10px 18px',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#333'
    },
    tableBodyCells: {
        textAlign: 'center'
    },
    bodyRow: {
        borderBottom: '1px solid #ddd !important'
    },
    tablePadding: {
        '& th, & td': {
            padding: '6px 12px',
        },
    },
    green: {
        background: 'rgb(155, 245, 155)'
    },
    orange: {
        background: 'rgb(255, 235, 153)'
    },
    red: {
        background: 'rgb(242, 91, 61)'
    }
})

const MainDashboard = (props) => {
    // const { cfpTableData } = useSelector((state) => state.fundingPlans);
    const { activeScenarioId } = useSelector((state) => state.initialParameter);
    const { cfpTableData, cfpCheckData, cfpPercentData, cfpUniformPercentData, cfpCustomValueData } = useSelector((state) => state.fundingPlans);
    const dispatch = useDispatch();
    const [CFPTableData, setCFPTableData] = useState(cfpTableData);
    const CommunityDetails = useSelector((state) => state?.accountInfo?.email?.community_info);

    let params = {
        "reserve_contribution":
        {
            "first_year": cfpCheckData,
            "percentage_change": cfpPercentData,
            "default_value": cfpUniformPercentData ? cfpUniformPercentData : 3,
            "custom_value": cfpCustomValueData
        },
        "special_assessments": {}
    }


    useEffect(() => {
        getCFPListing(activeScenarioId, params).then((res) => {
            // props.setLoading(true);
            setCFPTableData(res?.data?.data);
            dispatch(dispatchCFPTableData(res?.data?.data));
            props.setLoading(false);

        }).catch((err) => {
            // props.setLoading(true);
            if (err.response.status == 401) {
                showNotification("danger",err?.response?.data?.error ? err?.response?.data?.error : "Session Expired, Please Login Again")
            }
        })
    }, [])

    // console.log("CFPTableData", CFPTableData)

    const classes = useStyles();

    const startDate = new Date(props?.dashboardData?.connected_communities_of_scenario[0]);
    const startDateMonth = startDate.toLocaleString('default', { month: 'short' });
    const startDateDay = startDate.getDate();
    const startDateYear = startDate.getFullYear();
    const completeStartDate = startDateMonth + ' ' + startDateDay + ',' + ' ' + startDateYear;
    const endDate = new Date(props?.dashboardData?.connected_communities_of_scenario[1]);
    const endDateMonth = endDate.toLocaleString('default', { month: 'short' });
    const endDateDay = endDate.getDate();
    const endDateYear = endDate.getFullYear();
    const completeEndDate = endDateMonth + ' ' + endDateDay + ',' + ' ' + endDateYear;
    // const radialBarValue = cfpTableData[0]?.percent_funded;
    const radialBarValue = CFPTableData[0]?.percent_funded;
    const currentYr = props?.dashboardData?.current_year;
    const nextFiveYr = props?.dashboardData?.recommendations_for_next_years_reserve_contribution;

    const [chartColor, setChartColor] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const pieCart = useMemo(() => {
        const chartOptions = {
            chart: {
                // height: 600,
                offsetY: -80,
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        strokeWidth: '100%', // Adjust the strokeWidth value to increase thickness
                        background: '#F5F5F5', // Customize the background color of the track
                    },
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                            show: false
                        },
                        value: {
                            formatter: function (val) {
                                return val?.toFixed(2) + '%';
                            },
                            color: '#8e918f',
                            fontSize: '24px',
                            fontFamily: 'Roboto, sans-serif',
                            show: true,
                        },
                    },
                },
            },
            series: [radialBarValue == undefined ? 0 : radialBarValue],                // Value for the radial bar
            colors: chartColor,                   // Placeholder for custom colors
        };
        return chartOptions
    }, [chartColor, CFPTableData])


    useEffect(() => {
        const seriesValue = pieCart?.series[0];
        if (seriesValue <= 25 && seriesValue >= 0) {
            setChartColor(['#FF4560'])
        } else if (seriesValue <= 50 && seriesValue >= 25) {
            setChartColor(['#FFA500'])
        } else if (seriesValue <= 75 && seriesValue >= 50) {
            setChartColor(['#FFD700'])
        } else {
            setChartColor(['#00E396'])
        }
    }, [CFPTableData]);

    const formatePercent = (value) => {
        return `${value?.toFixed(2)}%`
    }

    const formatDollar = (value) => {
        return `$${value?.toLocaleString()}`
    }

    useEffect(() => {
        CFPTableData;
    }, [CFPTableData])

    return (
        <>

            <div className="main_card">

                <div className="card_dashboard">
                    <div>
                        <div className="main_dashboard_header_div">
                            <p>{CommunityDetails?.community_name} — {startDateYear} Reserve Study</p>
                            <p>{completeStartDate} to {completeEndDate}</p>
                        </div>
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="content_box">
                                        <h3 className="inner_content">{formatDollar(currentYr?.starting_balance)} <strong>Starting Balance</strong></h3>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="content_box">
                                    <div className="inner_content">
                                        <h3>{formatDollar(currentYr?.cfp_reserve_contribution)}</h3>
                                        <p><strong>Annual Reserve Contribution</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-2">
                                <div className="content_box">
                                    <div className="inner_content">
                                        <h3>{currentYr?.units}</h3>
                                        <p><strong>Units</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="content_box">
                                    <div className="inner_content">
                                        <h3>{formatDollar(currentYr?.fully_funded_balance)}</h3>
                                        <p><strong>Fully Funded Balance</strong></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="content_box">
                                    <div className="inner_content">
                                        <h3>{formatDollar(currentYr?.avg_per_unit_monthly_reserve_contribution?.toFixed(2))}</h3>
                                        <p><strong>AVG per Unit Monthly Reserve Contribution</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="content_box">
                                    <div className="inner_content">
                                        <h3>{currentYr?.approved_special_assessments}</h3>
                                        <p><strong>Approved Special Assessments</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{padding:'25px'}} >
                        <div className="radialbar_container">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="inner_content_radialbar">
                                        <div className="contribution_pd">
                                            <h3>{formatDollar(nextFiveYr?.anual_contribution)}</h3>
                                            <p><strong>Annual Contribution</strong></p>
                                        </div>
                                        <div className="contribution_pd">
                                            <h3>{nextFiveYr?.special_assessment_recommended}</h3>
                                            <p><strong>Special Assessment Recommended</strong></p>
                                        </div>
                                        <div className="contribution_pd">
                                            <h3>{formatDollar(nextFiveYr?.avg_per_unit_monthly_reserve_contribution.toFixed(2))}</h3>
                                            <p><strong>AVG per Unit Monthly Reserve Contribution</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="radial_title">
                                        <p>Current Percent Funded</p>
                                    </div>
                                    <div style={{ maxHeight: '290px' }}>
                                        <Chart
                                            options={pieCart}
                                            series={pieCart?.series}
                                            type="radialBar"
                                            height="600"
                                        />
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <h3 style={{ fontSize: '24px', fontWeight: '600', color: 'var(--primary-color)' }}>Recommendations for Next Years Reserve Contribution</h3>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="current_funding_Box">
                            <div style={{ padding: '15px' }}>
                                <h3>Current Funding Plan 5 Year Summary</h3>
                            </div>
                            <div style={{ padding: '15px' }}>
                                <TableContainer component={Paper} className={classes.tablePadding}>
                                    <Table>
                                        <TableHead>
                                            <TableRow className={classes.tableHead}>
                                                <TableCell className={classes.tableHeadCells}>Year</TableCell>
                                                <TableCell className={classes.tableHeadCells}>Starting Balance</TableCell>
                                                <TableCell className={classes.tableHeadCells}>Reserve Contribution</TableCell>
                                                <TableCell className={classes.tableHeadCells}>Percent Change</TableCell>
                                                <TableCell className={classes.tableHeadCells}>Special Assessments</TableCell>
                                                <TableCell className={classes.tableHeadCells}>Interest Earned</TableCell>
                                                <TableCell className={classes.tableHeadCells}>Expenditures</TableCell>
                                                <TableCell className={classes.tableHeadCells}>Ending Balance</TableCell>
                                                <TableCell className={classes.tableHeadCells}>Fully Funded Balance</TableCell>
                                                <TableCell className={classes.tableHeadCells}>Percent Funded</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {CFPTableData
                                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Slice data based on pagination
                                                .map((item, index) => (
                                                    <TableRow
                                                        className={`${index % 2 === 0
                                                            ? classes.evenRows
                                                            : classes.oddRows} ${classes.hoverRow} ${classes.bodyRow}`}
                                                        key={index}
                                                    >
                                                        {/* ... TableRow content ... */}
                                                        <TableCell className={classes.tableBodyCells} align="center">{item.year}</TableCell>
                                                        {item?.starting_balance >= 0 ? (
                                                            <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item.starting_balance)}</TableCell>
                                                        ) : (
                                                            <TableCell className={classes.tableBodyCells} align="center" style={{ color: 'red' }}>{formatDollar(item.starting_balance)}</TableCell>
                                                        )}
                                                        {/* <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item.starting_balance)}</TableCell> */}
                                                        <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item.reserve_contribution)}</TableCell>
                                                        <TableCell className={classes.tableBodyCells} align="center">{formatePercent(item.percent_change)}</TableCell>
                                                        <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item.special_assessments)}</TableCell>
                                                        <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item.interest_earned)}</TableCell>
                                                        <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item.expenditures)}</TableCell>
                                                        {
                                                            item?.ending_balance >= 0 ? (
                                                                <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item.ending_balance)}</TableCell>
                                                            ) : (
                                                                <TableCell className={classes.tableBodyCells} align="center" style={{ color: 'red' }}>{formatDollar(item.ending_balance)}</TableCell>
                                                            )
                                                        }
                                                        {/* <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item.ending_balance)}</TableCell> */}
                                                        <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item.fully_funded_balance)}</TableCell>
                                                        <TableCell className={`${classes.tableBodyCells} ${item.percent_funded > 70 ? classes.green : ''} ${item.percent_funded > 30 && item.percent_funded < 70 ? classes.orange : ''} ${item.percent_funded < 30 ? classes.red : ''}`} align="center">{item.percent_funded}%</TableCell>

                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={CFPTableData?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={(event, newPage) => setPage(newPage)}
                                    onRowsPerPageChange={(event) => {
                                        setRowsPerPage(parseInt(event.target.value, 10));
                                        setPage(0);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </div>

            </div >

        </>
    );
}

export default MainDashboard;

