import User from '../../configurations/Constant';

const initialState = {
    logInTokenData: {},
    isAuth : false
}

const logInTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case User.LOGINTOKENDATA:
            return {
                ...state,
                logInTokenData: action.logInTokenData,
            };
        case User.ISAUTH : 
            return {
                ...state,
                isAuth : action.isAuth,
            };
        default:
            return state;
    }
}

export default logInTokenReducer;