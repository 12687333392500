import React, { useState, useEffect } from "react";
import "./registratioPage.css";
import { useNavigate, Link } from "react-router-dom";
import { createUser } from "../../services/Services";

function RegistrationPage() {

    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [password, setPassword] = useState('');
    const [emailValid, setEmailValid] = useState(null);
    const [passwordValid, setPasswordValid] = useState(null);

    const handleFNameChange = (e) => {
        let Fname = e.target.value;
        setFName(Fname);
    }
    const handleLNameChange = (e) => {
        let LName = e.target.value;
        setLName(LName);
    }
    const handleEmailChange = (e) => {
        setEmailValid(false);
        let Email = e.target.value;
        setEmail(Email);
        validateEmail(Email)
    }
    const handleNumberChange = (e) => {
        let Number = e.target.value;
        setNumber(Number);
    }
    const handlePasswordChange = (e) => {
        let Password = e.target.value;
        setPassword(Password);
        validatePassword(Password);

    }

    const validateEmail = (_email) => {
        if (
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(_email) &&
            _email !== ""
        ) {
            setEmailValid(true);
            return true;
        }
        setEmailValid(false);
        return false;
    }

    const validatePassword = (_password) => {
        if (_password !== "" && _password.length >= 5) {
            setPasswordValid(true);
            return true;
        }
        setPasswordValid(false);
        return false;
    };

    const navigate = useNavigate()
    const handleRegisterClick = (e) => {
        if(fName && lName && number && validateEmail(email) && validatePassword(password)){
        e.preventDefault()
        let payload = {
            "first_name": fName,
            "last_name": lName,
            "email": email,
            "phone_no": number,
            "password": password
        }

        createUser(payload).then((res) => {
            alert("User created successfully",res);
            navigate("/");            
        }).catch((err) => {
            if(err.response.status == 400) {
                alert("User with this email already exist");
            }else{
                alert("Not able to create new user");
            }

        })
    } 
}

    
    useEffect(() => {
        fName; lName; email; password; number;
    }, [fName, lName, email, password, number])
    return (
        <>
            <div className="main_login">
                <div className="inner_div">
                    <div style={{ padding: '20px' }}>
                        <div className="login_header">
                            <h3> Community Planning Application</h3>
                        </div>
                        <div>
                            <form action="" className="login_form">
                                <div className="input_div">
                                    <input type='text' name='fname' id="fname" required placeholder="Enter Firstname" onChange={handleFNameChange} />
                                </div>
                                <div className="input_div">
                                    <input type='text' name='lname' id="lname" required placeholder="Enter Lastname" onChange={handleLNameChange} />
                                </div>
                                <div className="input_div">
                                    <input type='email' name='email' id="email" required placeholder="Enter Email" onChange={handleEmailChange} />
                                </div>
                                {emailValid === false && <div className="error">Invalid email address</div>}
                                <div className="input_div">
                                    <input type='text' name='number' id="number" required placeholder="Enter Phone No" onChange={handleNumberChange} />
                                </div>
                                <div className="input_div">
                                    <input type='text' name='password' id="password" required placeholder="Enter Password" onChange={handlePasswordChange} />
                                </div>
                                {passwordValid === false && <div className="error">Invalid Password</div>}
                                <div className="login_btn">
                                    <button type="submit" className="form-control btn" onClick={(e) => handleRegisterClick(e)}>Register</button>
                                </div>
                                <div  style={{display :'flex', justifyContent: 'center'}}>
                                    <Link to={"/"}>Already have an account ?</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RegistrationPage;