import React, { useRef, useState } from "react";

// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import {Typography, Tab, Tabs} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { HotTable } from "@handsontable/react";

import './AdvancedFundingParameters.css';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AdvancedFundingPlans = (props) => {
    const [value, setValue] = useState(0);
    const hotTableComponent = useRef(null);
    const percentageChange = ['Uniform', 'Custom'];


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
        
        {/* <Box className="transition-down" sx={{ p: 2 }}> */}
        {/* <div className="box"> */}
            <div className="AFP_main_div">
                <h3>Advanced Funding Parameters</h3>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Reserve Contribution" {...a11yProps(0)}
                        sx={{
                            border: '1px solid grey',
                            borderBottom: 'none',
                            borderRadius: '5%',
                            marginRight: "2px",
                            textTransform: 'none !important',
                            color: 'var(--primary-color) !important',
                            background: 'white',
                            '&.Mui-selected': {
                                background: 'var(--primary-color) !important',
                                color: 'var(--secondary-color) !important'
                            }
                        }} />
                    <Tab label="Sp. Assessments" {...a11yProps(1)}
                        sx={{
                            border: '1px solid grey',
                            borderBottom: 'none',
                            borderRadius: '5%',
                            marginRight: "2px",
                            textTransform: 'none !important',
                            color: 'var(--primary-color) !important',
                            background: 'var(--secondary-color) !important',
                            '&.Mui-selected': {
                                background: 'var(--primary-color) !important',
                                color: 'var(--secondary-color) !important'
                            }
                        }} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <div >
                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <label>
                                <input style={{backgroundColor:'var(--primary-color)'}} type='checkbox' onChange={props.handleFirstYear} defaultChecked={props.checkboxValue} />
                                <span style={{ fontWeight: '400', fontSize: '14px', paddingLeft: '5px' }}>Use Proposed Yearly Reserve Contribution for the 1-st year</span>
                            </label>
                        </div>

                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Percent change over 30 years</label>
                            <select className="form-select" style={{ fontSize: '14px' }} onChange={props.handlePercentageChange} value={props.percentageValue} >
                                {percentageChange?.map((item, index) => {
                                    return (
                                        <option selected style={{ fontSize: '14px' }} value={item} key={index}> {item} </option>
                                    )
                                })}
                            </select>
                        </div>
                        {props.percentageValue == 'Custom' ? (
                            <HotTable
                            ref={hotTableComponent}
                            data={props.customData}
                            columns={[
                                {
                                    title: 'Year',
                                    type: 'text',
                                },
                                {
                                    title: 'Percent Change',
                                    type: 'numeric',
                                    numericFormat: {
                                        pattern: '0.00',
                                        culture: 'en-US',
                                    },
                                }
                            ]}
                            rowHeaders={false}
                            stretchH="all"
                            height="auto"
                            afterChange={props.afterChange}
                            licenseKey="non-commercial-and-evaluation"
                        />
                        ) : ''}

                        <div className="AFP_second_input">
                            <label>Uniform percent change</label>
                            <input type="number" onChange={props.handleUniformPercentChange} value={props.uniformPercentageValue} />
                        </div>

                        <div className="AFP_reser_btn">
                            <button style={{backgroundColor:'var(--primary-color)',color:'white'}}onClick={props.handleReset}>Reset to default</button>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div style={{ fontSize: '14px' }}>
                        Special Assessments for each year can be set in the tab Data Entry / Special Assessments
                    </div>
                </TabPanel>
            </div>
            
        {/* </Box> */}
        </>
    );
}
export default AdvancedFundingPlans;