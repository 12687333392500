
import React from 'react'
import { useState } from "react";

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';

import { deleteScenerios, lockScenerio, updateScenarioTableListing } from "../../../services/Services";

import './EditScenario.css';

function EditScenarios(props) {
    //useStates
    const [scenarioName, setScenarioName] = useState('');
    const [scenarioNotes, setScenarioNotes] = useState('');
    const [deleteSnackbar, setDeleteSnackbar] = useState(false);
    const [updateSnackBar, setUpdateSnackbar] = useState(false);
    const [lockSnackBar, setlockSnackbar] = useState(false);


    //declare variables
    let disableInput = props.scenarioItem.status == false ? true : false;

    //functions
    const handleClose = () => {
        props.setEditScenario(false);
    }

    const handleEditScenario = () => {
        const params = {
            "scenario_name": scenarioName ? scenarioName : props?.scenarioItem?.scenario_name,
            "notes": scenarioNotes ? scenarioNotes : props?.scenarioItem?.notes,
            // "last_saved_by": scenarioLastSavedBy ? scenarioLastSavedBy : props?.scenarioItem?.last_saved_by,
        }

        updateScenarioTableListing(params, props?.scenarioItem?.id).then((res) => {
            setUpdateSnackbar(true);
            props.fetchScenarioListingData();
            setTimeout(() => {
                setUpdateSnackbar(false)
                props.setEditScenario(false);
            }, 1000);
            return res;
        }).catch((err) => {
            console.error(err);
        })
    }

    const handleScenarioName = (e) => {
        setScenarioName(e.target.value);
    }

    const handleScenarioNotes = (e) => {
        setScenarioNotes(e.target.value)
    }

    const handleCloseSnackbar = () => {
        setUpdateSnackbar(false);
        setDeleteSnackbar(false);
        setlockSnackbar(false);

    };

    const handleDeleteScenerio = () => {
        deleteScenerios(props?.scenarioItem?.id).then((res) => {
            props.fetchScenarioListingData();
            setDeleteSnackbar(true);
            setTimeout(() => {
                props.setEditScenario(false);
                setDeleteSnackbar(false);
            }, 500);
            return res;
        }).catch((err) => {
            console.error(err);
        })
    }

    const handleLockscenerio = () => {
        const params = {
            "status": props?.scenarioItem?.status,
        }

        lockScenerio(params, props?.scenarioItem?.id).then((res) => {
            props.fetchScenarioListingData();
            setlockSnackbar(true);
            setTimeout(() => {
                props.setEditScenario(false);
                setlockSnackbar(false);
            }, 1000);
            return res;
        }).catch((err) => {
            console.error(err);
        })
    }

    return (
        <>
            <Dialog
                open={props.openEditScenario}
                onClose={handleClose}
                maxWidth='lg'
                PaperProps={{
                    sx: {
                        width: "50%",
                        overflow: 'hidden !important',
                    }
                }}
            >
                <DialogTitle style={{ cursor: 'move', fontWeight: '600', borderBottom: '1px solid lightgray' }}>
                    <div className="d-flex">
                        <div style={{ width: '100%' }}>
                            Modify information for the selected scenario
                        </div>
                        <div>
                            <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
                        </div>
                    </div>

                </DialogTitle>

                <DialogContent className={disableInput ? 'cursur_disable' : ''}>
                    <DialogContentText>
                        <div className="edit_modal_inputs" >
                            <div>
                                <h6><strong>Scenario Name</strong></h6>
                                <input type='text' className="input_width" onChange={(e) => handleScenarioName(e)} defaultValue={props?.scenerioEditListing?.scenario_name} disabled={disableInput} />
                            </div>
                            <div>
                                <h6><strong>Additional Info</strong></h6>
                                <input type='text' className="input_width" onChange={(e) => handleScenarioNotes(e)} defaultValue={props?.scenerioEditListing?.notes} disabled={disableInput} />
                            </div>
                            <div>
                                <h6><strong>Last Saved Date</strong></h6>
                                <input type='email' className="input_width" value={props?.scenerioEditListing?.last_saved_date} style={{ background: '#eee' }} disabled={disableInput} />
                            </div>
                            <div>
                                <h6><strong>Last Saved by</strong></h6>
                                <input type='email' className="input_width" value={props?.scenerioEditListing?.last_saved_by_email} style={{ background: '#eee' }} disabled={disableInput} />
                            </div>
                        </div>

                    </DialogContentText>

                    <Snackbar
                        open={updateSnackBar}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        Scenerio Updated Succesfully.
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={deleteSnackbar}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                           Scenerio Deleted Succesfully.
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={lockSnackBar}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                           Scenerio Locked Succesfully.
                        </Alert>
                    </Snackbar>

                </DialogContent>

                <DialogActions sx={{ borderTop: '1px solid lightgray' }}>
                    <div className="d-flex edit_model_footer">
                        <div className="edit_model_footer_inner_div">
                            <div>
                                <Button className={disableInput ? 'delete_btn' : 'disable_button'} onClick={() => handleDeleteScenerio({
                                    vertical: 'top',
                                    horizontal: 'right',
                                })} disabled={props?.scenerioEditListing?.status == true ? true : false}>
                                    <DeleteIcon />
                                    Delete Scenario
                                </Button>

                            </div>
                            <div>
                                <Button className={disableInput ? 'disable_button' : 'lock_button'} onClick={() => handleLockscenerio({
                                    vertical: 'top',
                                    horizontal: 'right',
                                })}>
                                    <LockIcon />
                                    Lock Scenario
                                </Button>
                            </div>
                        </div>
                        <div className="edit_model_footer_inner_div">
                            <div>
                                <Button className={disableInput ? 'disable_button' : 'save_button'} autoFocus onClick={() => handleEditScenario({
                                    vertical: 'top',
                                    horizontal: 'right',
                                })} disabled={disableInput}>
                                    Save
                                </Button>
                            </div>
                            <div>
                                <Button className='save_button' autoFocus onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default EditScenarios;