import React, { useEffect, useState } from 'react';

import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { TablePagination, makeStyles } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import SearchBar from "material-ui-search-bar";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';
import { getExpendituresTable } from '../../services/Services';
// import '../scenarioManagementTable/ScenarioManagementTable.css';
import "../data-entry/dataEntryTable/scenarioManagementTable/ScenarioManagementTable.css";


const useStyles = makeStyles({
    table: {
        '& th, & td': {
            padding: '5px 5px',
        },
    },
    oddRows: {
        backgroundColor: '#f9f9f9',
    },
    evenRows: {
        backgroundColor: '#fff',
    },
    hoverRow: {
        '&:hover': {
            backgroundColor: '#f9f9f9'
        }
    },
    rowSelect: {
        backgroundColor: '#aab7d1'
    },
    bodyRow: {
        borderBottom: '1px solid #ddd !important'
    },
    tableCell: {
        fontSize: '14px !important',
        fontWeight: '600 !important'
    },
    tableHeader: {
        borderBottom: '2px solid black',
        borderTop: '1px solid black',
    },
    searchBody: {
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'end'
    },
    searchBar: {
        width: '18%',
        height: '45px',
        marginTop: '10px',
        // border: '1px solid black',
        boxShadow: '0px 4px 8px -1px rgba(0,0,0,0.2), 0px 3px 3px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important'
    }
})

const ExpenditureTab = (props) => {
    const [expandedAccordions, setExpandedAccordions] = useState([1]);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [expendituresApiData, setExpendituresApiData] = useState([]);
    const [selectedRowsOne, setSelectedRowsOne] = useState('');
    const [selectedRowsTwo, setSelectedRowsTwo] = useState('');
    const [selectedRowsThree, setSelectedRowsThree] = useState('');

    const [searchQuery1, setSearchQuery1] = useState('');
    const [searchQuery2, setSearchQuery2] = useState('');
    const [searchQuery3, setSearchQuery3] = useState('');

    const classes = useStyles();
    const { activeScenarioId } = useSelector((state) => state.initialParameter);

    useEffect(() => {
        getExpendituresTable(activeScenarioId).then((res) => {
            props.setLoading(true);
            setExpendituresApiData(res.data);
            props.setLoading(false);
        }).catch((err) => {
            props.setLoading(true);
            console.error(err);
        })
    }, [])


    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleAccordionClick = (accordionId) => {
        if (expandedAccordions.includes(accordionId)) {
            setExpandedAccordions(expandedAccordions.filter(id => id !== accordionId));
        } else {
            setExpandedAccordions([...expandedAccordions, accordionId]);
        }
    };

    const handleSelectedRowsOne = (index) => {
        if (selectedRowsOne?.includes(index)) {
            setSelectedRowsOne(selectedRowsOne?.filter(data => data !== index));
            // dispatch(dispatchCFPSelectedRowData(selectedRows.filter(data => data !== index)));
        } else {
            setSelectedRowsOne([...selectedRowsOne, index]);
            // dispatch(dispatchCFPSelectedRowData([...selectedRows, index]));
        }
    };
    const handleSelectedRowsTwo = (index) => {
        if (selectedRowsTwo?.includes(index)) {
            setSelectedRowsTwo(selectedRowsTwo?.filter(data => data !== index));
            // dispatch(dispatchCFPSelectedRowData(selectedRows.filter(data => data !== index)));
        } else {
            setSelectedRowsTwo([...selectedRowsTwo, index]);
            // dispatch(dispatchCFPSelectedRowData([...selectedRows, index]));
        }
    };
    const handleSelectedRowsThree = (index) => {
        if (selectedRowsThree?.includes(index)) {
            setSelectedRowsThree(selectedRowsThree?.filter(data => data !== index));
            // dispatch(dispatchCFPSelectedRowData(selectedRows.filter(data => data !== index)));
        } else {
            setSelectedRowsThree([...selectedRowsThree, index]);
            // dispatch(dispatchCFPSelectedRowData([...selectedRows, index]));
        }
    };

    const formatDollar = (value) => {
        return `$${value.toLocaleString()}`
    }

    const handleSearch = (value, field) => {
        if (field === 1) {
            setSearchQuery1(value);
        } else if (field === 2) {
            setSearchQuery2(value);
        } else if (field === 3) {
            setSearchQuery3(value);
        }
    };

    const sortedData = [...expendituresApiData]?.sort((a, b) => {
        if (orderBy) {
            if (order === 'asc') {
                return a[orderBy] > b[orderBy] ? 1 : -1;
            } else {
                return a[orderBy] < b[orderBy] ? 1 : -1;
            }
        } else {
            return 0;
        }
    });

    const paginatedData = sortedData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const filteredDataOne = paginatedData?.filter((item) => item.component?.toLowerCase()?.includes(searchQuery1?.toLowerCase())
    );
    const filteredDataTwo = paginatedData?.filter((item) => item.component?.toLowerCase()?.includes(searchQuery2?.toLowerCase())
    );
    const filteredDataThree = paginatedData?.filter((item) => item.component?.toLowerCase()?.includes(searchQuery3?.toLowerCase())
    );

    return (

        <>
            {/* <div > */}
            {props.loading && <CircularProgress className='circular_loader' />}
            {/* </div> */}
            {/* {!props.loading &&
                <div className='main_div_data_entry_tables'> */}
            <div className='main_div_scenario buttonright' style={{ marginTop: '55px' }}>
                {!props.loading &&
                    <>
                        <Accordion className='scenario_container' expanded={expandedAccordions.includes(1)} sx={{ my: '1rem' }}>
                            <AccordionSummary style={{ color: 'var(--primary-color)' }} expandIcon={expandedAccordions.includes(1) ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={() => handleAccordionClick(1)} className='units_header'>
                                Expenditures Yr 1-10
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.searchBody}>
                                    <SearchBar
                                        className={classes.searchBar}
                                        onChange={(e) => handleSearch(e, 1)}
                                        value={searchQuery1}

                                    />
                                </div>
                                <TableContainer>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow className={classes.tableHeader}>
                                                <TableCell className={classes.tableCell}>
                                                    <TableSortLabel active={orderBy === 'component'} direction={orderBy === 'component' ? order : 'asc'} onClick={() => handleSort('component')} >
                                                        Component
                                                    </TableSortLabel>
                                                </TableCell>
                                                {expendituresApiData[0]?.values?.map((item, index) => {
                                                    return (
                                                        index < 10 ? (
                                                            <TableCell key={item.year} className={classes.tableCell}>
                                                                <TableSortLabel active={orderBy === item.year} direction={orderBy === item.year ? order : 'asc'} onClick={() => handleSort(item.year)} >
                                                                    {item.year}
                                                                </TableSortLabel>
                                                            </TableCell>
                                                        ) : ''
                                                    )
                                                }
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredDataOne?.map((row, index) => {
                                                return (
                                                    <TableRow onClick={() => handleSelectedRowsOne(index)} className={`${index % 2 == 0 ? classes.evenRows : classes.oddRows} ${selectedRowsOne?.includes(index) ? '' : classes.hoverRow} ${selectedRowsOne?.includes(index) ? classes.rowSelect : ''} ${classes.bodyRow}`} key={index}>
                                                        <TableCell>{row.component}</TableCell>
                                                        {row?.values?.map((item, i) => (
                                                            i < 10 ?
                                                                (<TableCell key={item.year}>{formatDollar(item.expense)}</TableCell>)
                                                                : ''
                                                        ))}
                                                    </TableRow>
                                                )
                                            }
                                            )}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={expendituresApiData?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableContainer>

                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="scenario_container" expanded={expandedAccordions.includes(2)} sx={{ my: '1rem' }}>
                            <AccordionSummary style={{ color: 'var(--primary-color)' }} expandIcon={expandedAccordions.includes(2) ? <RemoveIcon style={{ color: "var(--primary-color)" }} /> : <AddIcon style={{ color: "var(--primary-color)" }} />} onClick={() => handleAccordionClick(2)} className='units_header'>
                                Expenditures Yr 11-20
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.searchBody}>
                                    <SearchBar
                                        className={classes.searchBar}
                                        onChange={(e) => handleSearch(e, 2)}
                                        value={searchQuery2}
                                    />
                                </div>
                                <TableContainer>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow className={classes.tableHeader}>
                                                <TableCell className={classes.tableCell}>
                                                    <TableSortLabel active={orderBy === 'component'} direction={orderBy === 'component' ? order : 'asc'} onClick={() => handleSort('component')} >
                                                        Component
                                                    </TableSortLabel>
                                                </TableCell>
                                                {expendituresApiData[0]?.values?.map((item, index) => {
                                                    return (
                                                        index > 9 && index < 20 ? (
                                                            <TableCell key={item.year} className={classes.tableCell}>
                                                                <TableSortLabel active={orderBy === item.year} direction={orderBy === item.year ? order : 'asc'} onClick={() => handleSort(item.year)} >
                                                                    {item.year}
                                                                </TableSortLabel>
                                                            </TableCell>
                                                        ) : ''
                                                    )
                                                }
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredDataTwo?.map((row, index) => {
                                                return (
                                                    <TableRow onClick={() => handleSelectedRowsTwo(index)} className={`${index % 2 == 0 ? classes.evenRows : classes.oddRows} ${selectedRowsOne?.includes(index) ? '' : classes.hoverRow} ${selectedRowsOne?.includes(index) ? classes.rowSelect : ''} ${classes.bodyRow}`} key={index}>
                                                        <TableCell>{row.component}</TableCell>
                                                        {row?.values?.map((item, i) => (
                                                            i > 9 && i < 20 ?
                                                                (<TableCell key={item.year}>{formatDollar(item.expense)}</TableCell>)
                                                                : ''
                                                        ))}
                                                    </TableRow>
                                                )
                                            }
                                            )}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={expendituresApiData?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableContainer>

                            </AccordionDetails>
                        </Accordion>

                        <Accordion className='scenario_container' expanded={expandedAccordions.includes(3)} sx={{ my: '1rem' }}>
                            <AccordionSummary style={{ color: 'var(--primary-color)' }} expandIcon={expandedAccordions.includes(3) ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={() => handleAccordionClick(3)} className='units_header'>
                                Expenditures Yr 21-30
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.searchBody}>
                                    <SearchBar
                                        className={classes.searchBar}
                                        onChange={(e) => handleSearch(e, 3)}
                                        value={searchQuery3}

                                    />
                                </div>
                                <TableContainer>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow className={classes.tableHeader}>
                                                <TableCell className={classes.tableCell}>
                                                    <TableSortLabel active={orderBy === 'component'} direction={orderBy === 'component' ? order : 'asc'} onClick={() => handleSort('component')} >
                                                        Component
                                                    </TableSortLabel>
                                                </TableCell>
                                                {expendituresApiData[0]?.values?.map((item, index) => {
                                                    return (
                                                        index > 19 && index < 30 ? (
                                                            <TableCell key={item.year} className={classes.tableCell}>
                                                                <TableSortLabel active={orderBy === item.year} direction={orderBy === item.year ? order : 'asc'} onClick={() => handleSort(item.year)} >
                                                                    {item.year}
                                                                </TableSortLabel>
                                                            </TableCell>
                                                        ) : ''
                                                    )
                                                }
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredDataThree?.map((row, index) => {
                                                return (
                                                    <TableRow onClick={() => handleSelectedRowsThree(index)} className={`${index % 2 == 0 ? classes.evenRows : classes.oddRows} ${selectedRowsOne?.includes(index) ? '' : classes.hoverRow} ${selectedRowsOne?.includes(index) ? classes.rowSelect : ''} ${classes.bodyRow}`} key={index}>
                                                        <TableCell>{row.component}</TableCell>
                                                        {row?.values?.map((item, i) => (
                                                            i > 19 && i < 30 ?
                                                                (<TableCell key={item.year}>{formatDollar(item.expense)}</TableCell>)
                                                                : ''
                                                        ))}
                                                    </TableRow>
                                                )
                                            }
                                            )}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={expendituresApiData?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableContainer>

                            </AccordionDetails>
                        </Accordion>
                    </>
                }
            </div>
        </>
    );
};

export default ExpenditureTab;
