import User from "../../configurations/Constant";

export const dispatchCFPTableData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.CFPTABLEDATA,
        cfpTableData: json,
      })
    } catch (e) {
      dispatch({
        type: User.CFPTABLEDATA,
        cfpTableData: [],
      });
    }
  }

  export const dispatchCFPCheckData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.CFPCHECKDATA,
        cfpCheckData: json,
      })
    } catch (e) {
      dispatch({
        type: User.CFPCHECKDATA,
        cfpCheckData: false,
      });
    }
  }

  export const dispatchCFPPercentData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.CFPPERCENTDATA,
        cfpPercentData: json,
      })
    } catch (e) {
      dispatch({
        type: User.CFPPERCENTDATA,
        cfpPercentData: '',
      });
    }
  }

  export const dispatchCFPUniformPercentData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.CFPUNIFORMPERCENTDATA,
        cfpUniformPercentData: json,
      })
    } catch (e) {
      dispatch({
        type: User.CFPUNIFORMPERCENTDATA,
        cfpUniformPercentData: 0,
      });
    }
  }

  export const dispatchCFPAdvanceParameterData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.CFPADVANCEDPARAMETERDATA,
        cfpAdvanceParameterData: json,
      })
    } catch (e) {
      dispatch({
        type: User.CFPADVANCEDPARAMETERDATA,
        cfpAdvanceParameterData: false,
      });
    }
  }

  export const dispatchCFPSelectedRowData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.CFPSELECTEDROWDATA,
        cfpSelectedRowData: json,
      })
    } catch (e) {
      dispatch({
        type: User.CFPSELECTEDROWDATA,
        cfpSelectedRowData: [],
      });
    }
  }

  export const dispatchCFPCustomValueData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.CFPCUSTOMDATAVALUE,
        cfpCustomValueData: json,
      })
    } catch (e) {
      dispatch({
        type: User.CFPCUSTOMDATAVALUE,
        cfpCustomValueData: [],
      });
    }
  }

  export const dispatchTFPTableData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.TFPTABLEDATA,
        tfpTableData: json,
      })
    } catch (e) {
      dispatch({
        type: User.TFPTABLEDATA,
        tfpTableData: [],
      });
    }
  }

  export const dispatchTFPAdvanceParameterData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.TFPADVANCEPARAMETERDATA,
        tfpAdvanceParameterData: json,
      })
    } catch (e) {
      dispatch({
        type: User.TFPADVANCEPARAMETERDATA,
        tfpAdvanceParameterData: false,
      });
    }
  }

  export const dispatchTFPMinimalEndingData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.TFPMINIMALENDINGDATA,
        tfpMinimalEndingData: json,
      })
    } catch (e) {
      dispatch({
        type: User.TFPMINIMALENDINGDATA,
        tfpMinimalEndingData: 0,
      });
    }
  }

  export const dispatchTFPPercentChangeData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.TFPPERCENTCHANGEDATA,
        tfpPercentChangeData: json,
      })
    } catch (e) {
      dispatch({
        type: User.TFPPERCENTCHANGEDATA,
        tfpPercentChangeData: '',
      });
    }
  }

  export const dispatchTFPSelectedRowData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.TFPSELECTEDROWDATA,
        tfpSelectedRowData: json,
      })
    } catch (e) {
      dispatch({
        type: User.TFPSELECTEDROWDATA,
        tfpSelectedRowData: [],
      });
    }
  }

  export const dispatchTFPDefaultValueData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.TFPDEFAULTVALUEDATA,
        tfpDefaultValueData: json,
      })
    } catch (e) {
      dispatch({
        type: User.TFPDEFAULTVALUEDATA,
        tfpDefaultValueData: [],
      });
    }
  }

  export const dispatchTFPCustomObjData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.TFPCUSTOMOBJDATA,
        tfpCustomObjData: json,
      })
    } catch (e) {
      dispatch({
        type: User.TFPCUSTOMOBJDATA,
        tfpCustomObjData: {},
      });
    }
  }

  export const dispatchTFPCustomObjDatamin = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.TFPCUSTOMOBJDATAMIN,
        tmin: json,
      })
    } catch (e) {
      dispatch({
        type: User.TFPCUSTOMOBJDATAMIN,
        tmin: [],
      });
    }
  }

  export const dispatchTFPCustomObjDatamax = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.TFPCUSTOMOBJDATAMAX,
        tmax: json,
      })
    } catch (e) {
      dispatch({
        type: User.TFPCUSTOMOBJDATAMAX,
        tmax: [],
      });
    }
  }

  export const dispatchFFPTableData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPTABLEDATA,
        ffpTableData: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPTABLEDATA,
        ffpTableData: [],
      });
    }
  }

  export const dispatchFFPAdvanceParameterData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPADVANCEPARAMETERDATA,
        ffpAdvanceParameterData: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPADVANCEPARAMETERDATA,
        ffpAdvanceParameterData: false,
      });
    }
  }

  export const dispatchFFPMinimalEndingData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPMINIMALENDINGDATA,
        ffpMinimalEndingData: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPMINIMALENDINGDATA,
        ffpMinimalEndingData: 0,
      });
    }
  }

  export const dispatchFFPPercentChangeData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPPERCENTCHANGEDATA,
        ffpPercentChangeData: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPPERCENTCHANGEDATA,
        ffpPercentChangeData: '',
      });
    }
  }

  export const dispatchFFPSelectedRowData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPSELECTEDROWDATA,
        ffpSelectedRowData: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPSELECTEDROWDATA,
        ffpSelectedRowData: [],
      });
    }
  }

  export const dispatchFFPMinimalPercentData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPMINIMALPERCENTDATA,
        ffpMinimalPercentData: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPMINIMALPERCENTDATA,
        ffpMinimalPercentData: 0,
      });
    }
  }

  export const dispatchTFPMinEndBalYearData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.TFPMINENDINGBALANCEYEARDATA,
        tfpMinBalanceYearData: json,
      })
    } catch (e) {
      dispatch({
        type: User.TFPMINENDINGBALANCEYEARDATA,
        tfpMinBalanceYearData: '',
      });
    }
  }

  export const dispatchTFPSpecialAssYearData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.TFPSPECIALASSYEARDATA,
        tfpSpecialAssYearData: json,
      })
    } catch (e) {
      dispatch({
        type: User.TFPSPECIALASSYEARDATA,
        tfpSpecialAssYearData: '',
      });
    }
  }

  export const dispatchTFPFundingPriorityData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.TFPFUNDINGPRIORITYDATA,
        tfpFundingPriority: json,
      })
    } catch (e) {
      dispatch({
        type: User.TFPFUNDINGPRIORITYDATA,
        tfpFundingPriority: '',
      });
    }
  }

  export const dispatchFFPMinEndBalYearData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPMINENDINGBALANCEYEARDATA,
        ffpMinBalanceYearData: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPMINENDINGBALANCEYEARDATA,
        ffpMinBalanceYearData: '',
      });
    }
  }

  export const dispatchFFPSpecialAssYearData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPSPECIALASSYEARDATA,
        ffpSpecialAssYearData: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPSPECIALASSYEARDATA,
        ffpSpecialAssYearData: '',
      });
    }
  }

  export const dispatchFFPFundingPriorityData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPFUNDINGPRIORITYDATA,
        ffpFundingPriority: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPFUNDINGPRIORITYDATA,
        ffpFundingPriority: '',
      });
    }
  }

  export const dispatchFFPDefaultValueData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPDEFAULTVALUEDATA,
        ffpDefaultValueData: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPDEFAULTVALUEDATA,
        ffpDefaultValueData: [],
      });
    }
  }

  export const dispatchFFPCustomObjData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPCUSTOMOBJDATA,
        ffpCustomObjData: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPCUSTOMOBJDATA,
        ffpCustomObjData: {},
      });
    }
  }

  export const dispatchFFPCustomObjDatamin = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPCUSTOMOBJDATAMIN,
        min: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPCUSTOMOBJDATAMIN,
        min: [],
      });
    }
  }
  export const dispatchFFPCustomObjDatamax = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FFPCUSTOMOBJDATAMAX,
        max: json,
      })
    } catch (e) {
      dispatch({
        type: User.FFPCUSTOMOBJDATAMAX,
        max: [],
      });
    }
  }

  export const dispatchFULLFunding = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.FULLFUNDING,
        full_funding_plan: json,
      })
    } catch (e) {
      dispatch({
        type: User.FULLFUNDING,
        full_funding_plan: {},
      });
    }
  }

  export const dispatchTHRESHOLDFunding = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.THRESHOLDFUNDING,
        threshold_funding_plan: json,
      })
    } catch (e) {
      dispatch({
        type: User.THRESHOLDFUNDING,
        threshold_funding_plan: {},
      });
    }
  }