import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./resetPassword.css"
import { resetPassword } from "../../services/Services";

function ResetPassword() {

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [conPassword, setConPassword] = useState("");
    const [oldError, setOldError] = useState("");
    const [newError, setNewError] = useState("");
    const [conError, setConError] = useState("");

    const handleOldChange = (e) => {
        let OLD = e.target.value;
        setOldPassword(OLD);
        setOldError("");
    }
    const handleNewChange = (e) => {
        let NEW = e.target.value;
        setNewPassword(NEW);
        setNewError("");
    }
    const handleConChange = (e) => {
        let CON = e.target.value;
        setConPassword(CON);
        setConError("");
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();

        if (newPassword.length < 5 && newPassword !== "" ) {
            setNewError("Password must be at least 5 characters long.");
            return;
        }

        if (newPassword !== conPassword) {
            setConError("Password doesn't match.");
            return;
        }
        let payload = {
            "old_password": oldPassword,
            "new_password": newPassword
        }

        resetPassword(1, payload,).then((res) => {
            alert(res.data.message);
        }).catch((err) => {
            alert(err.response.data.old_password)
            if (err.response.status == 400) {
                if (err.response.data.old_password) {
                    setOldError(err.response.data.old_password)
                }
            }
        })
    }


    return (
        <>
            <div className="main_login">
                <div className="inner_div">
                    <div style={{ padding: '20px' }}>
                        <div className="login_header">
                            <h3>Community Planning Header</h3>
                        </div>
                        <div>
                            <form action="" className="login_form" >
                                <div className="input_div">
                                    <input type='text' name="oldPassword" id="oldPassword" placeholder="Old Password" onChange={handleOldChange} required />
                                </div>
                                {oldError ? <div className="error">{oldError}</div> : ""}

                                <div className="input_div">
                                    <input type="text" name='newPassword' id="newPassword" placeholder="New Password" onChange={handleNewChange} required />
                                </div>
                                <div className="error">{newError}</div>
                                <div className="input_div">
                                    <input type="text" name="conPassword" id="conPassword" placeholder="Confirm Password" onChange={handleConChange} required />
                                </div>
                                <div className="error">{conError}</div>
                                <div style={{ display: 'flex', justifyContent: 'right', margin: '10px' }}>
                                    <Link to={"/forgotPassword"}>Forgot Password ?</Link>
                                </div>
                                <div className="login_btn">
                                    <button type="submit" className="form-control btn" onClick={handleSubmitClick}>Submit</button>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
                                    <Link to={"/"}>Back to login</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;