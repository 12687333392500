import React, { useState } from "react";

import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@mui/material";

import './AccountInfo.css';
import { createCommunityInfo } from "../../services/Services";
import showNotification from '../common/Notification'


const CreateDialog = (props) => {
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [subscription, setSubscription] = useState('');
    const [maxScenarios, setMaxScenarios] = useState('');
    const [status, setStatus] = useState('');
    const [updateSnackBar, setUpdateSnackbar] = useState(false);


    const handleChangeName = (e) => {
        setName(e.target.value);
    }
    const handleChangeAddress = (e) => {
        setAddress(e.target.value);
    }
    const handleChangeSubscription = (e) => {
        setSubscription(e.target.value);
    }
    const handleChangeMaxScenarios = (e) => {
        setMaxScenarios(e.target.value);
    }
    const handleChangeStatus = (e) => {
        setStatus(e.target.value);
    }

    const handleCreateAccountInfo = () => {
        const params = {
            "name": name,
            "address": address,
            "subscription": subscription,
            "maxscenarios": maxScenarios,
            "status": status,
            "user_id": [props.userId]
        }

        createCommunityInfo(params).then(() => {
            showNotification("success","Successfully created");
            props.fetchAccountCommunityInfo();
            props.fetchCommunityInfoList();
        }).catch((err) => {
            showNotification("danger",err.response.data.error);
        })
    }

    const handleCloseSnackbar = () => {
        setUpdateSnackbar(false);
    };

    return (
        <>
            <Dialog
                open={props.openCreateDialog}
                onClose={props.handleCreateDialog}
                maxWidth='lg'
                PaperProps={{
                    sx: {
                        width: "50%",
                        overflow: 'hidden !important',
                    }
                }}
            >
                <DialogTitle style={{ borderBottom: '1px solid #e5e5e5', padding: '15px', }}>
                    <h4 style={{ margin: 0, fontSize: '18px' }}>Information on your account and subscription</h4>
                </DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        <div>
                            <form style={{ width: '50%' }} className="account_info_form">
                                <div>
                                    <label htmlFor="name">Community Name</label><br />
                                    <input id='name' className="form-control" type="text" onChange={handleChangeName} style={{ width: '200%' }} />
                                </div>
                                <div>
                                    <label htmlFor="address">Community Address</label><br />
                                    <input id="address" className="form-control" type="text" onChange={handleChangeAddress} style={{ width: '200%' }} />
                                </div>
                                <div>
                                    <label htmlFor="subscription">Subscription Type / Period</label><br />
                                    <input id="subscription" className="form-control" type="text" onChange={handleChangeSubscription} style={{ width: '200%' }} />
                                </div>
                                <div>
                                    <label htmlFor="maxScenarios">Maximum number of Scenarios</label><br />
                                    <input id="maxScenarios" className="form-control" type="text" onChange={handleChangeMaxScenarios} style={{ width: '200%' }} />
                                </div>
                                <div>
                                    <label htmlFor="status">Subscription Status</label><br />
                                    <select id="status" name="myDropdown" className="form-control" onChange={handleChangeStatus} style={{ width: '200%' }} >
                                        <option value="" disabled selected>Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Expired">Expired</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                    </DialogContentText>
                    <Snackbar
                        open={updateSnackBar}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                            Scenerio Updated Succesfully.
                        </Alert>
                    </Snackbar>
                </DialogContent>
                <DialogActions style={{ borderTop: '1px solid #e5e5e5' }}>
                    <div className="d-flex" style={{ float: 'right', gap: '5%', padding: '10px' }}>
                        <div>
                            <button className="button_class" onClick={handleCreateAccountInfo}>Create</button>
                        </div>
                        <div>
                            <button className="button_class" onClick={props.handleCreateDialog}>Cancel</button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CreateDialog;