import React from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { Download } from "@mui/icons-material";
import { DownloadComponents } from "../../services/Services";

function UploadCustom({ handleCloseUpload ,handleUploadCSV}) {
    const handleDownload = () => {
        DownloadComponents().then((res) => {
            const csvBlob = new Blob([res?.data], { type: 'text/csv' });
            const csvUrl = URL.createObjectURL(csvBlob);

            const link = document.createElement('a');
            link.href = csvUrl;
            link.download = 'Component-List.csv';
            link.click();
            URL.revokeObjectURL(csvUrl);
        })
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="div">
                        <div style={{display:'flex'}}>
                            <div>
                                <h4>Select File to upload</h4>
                            </div>
                            <div>
                                <button className="button_class " style={{ marginRight:'3%',position: 'absolute', right: '0' }} onClick={handleCloseUpload}>X</button>
                            </div>
                        </div>
                        <p style={{ width: '100%', height: '1px', backgroundColor: 'gray', marginTop: "20px" }}></p>
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        <div>
                            <p><strong>Choose Csv File</strong></p>
                            <div>
                                <input type='file' style={{ border: '1px solid gray', backgroundColor: 'lightgray' }} onChange={handleUploadCSV} />
                                <p style={{ width: '100%', height: '1px', backgroundColor: 'gray', marginTop: "20px" }}></p>
                            </div>
                            <div style={{ display: 'flex', marginTop:'2%' }}>
                                <div style={{ width: '60%',fontSize: '14px' }}>
                                    <p>The file should have one of the following formats:<strong>CSV</strong>. It should contain the following columns:</p>
                                    <ol>
                                        <li style={{ listStyle: "initial" }}>Category</li>
                                        <li style={{ listStyle: "initial" }}>ComponentTitle</li>
                                        <li style={{ listStyle: "initial" }}>Description</li>
                                        <li style={{ listStyle: "initial" }}>Useful Life</li>
                                        <li style={{ listStyle: "initial" }}>Replacement Cost</li>
                                    </ol>
                                </div>
                                <div style={{ margin: "5%", position: 'absolute', right:'0',bottom:'0' }}>
                                    <button className="button_class " onClick={handleDownload}><Download style={{ fontSize: '17px', margin: '2px 2px' }} /> Download Csv template</button>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </>
    )

}
export default UploadCustom