import axios from 'axios';
// const logInTokenData = localStorage.getItem('token');
// console.log("token",logInTokenData)
// // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg2MjA0ODA3LCJpYXQiOjE2ODYxMzkwNTMsImp0aSI6ImFkODBkMzNjOWQzYTQ4MTVhYWY0N2UwMDAzOTcxODVhIiwidXNlcl9pZCI6Nn0.WXjYk4pEyLZipCu1NzV-E9E51r-4f0B97_uI4C_PaKI'
// export const axiosClient = axios.create(
//   {
//     headers: {
//       'Authorization': `Bearer ${logInTokenData}`, 
//     }
//   }
// );

export const axiosClient = axios.create();

axiosClient.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

// scenario management api end points
export const getScenarioTableListing = async(id) => {
    return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/view-scenarios/${id}/`)
}

export const getScenariosList = async(communityId) => {
    return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/user-scenarios-list/${communityId}/`)
}

export const getAccessUserList = async(scenarioID) => {
    return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/get-access-user-names/${scenarioID}/`)
}

export const copyScenarioTableListing = async(params,communityId) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/create/${communityId}/`, params)
}

export const createNewScenarioTableListing = async(communityId) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/create/${communityId}/`)
}

export const grantAccess = async(scenario_id,data) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/scenarios/${scenario_id}/grant-access/`,data)
}

export const revokeScenarioAccess = async(id) => {
  return await axiosClient.delete(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/scenario-access-control/${id}/delete/`)
}

export const updateScenarioTableListing = async(params, scenarioId) => {
    return await axiosClient.put(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/update/${scenarioId}/`, params)
}

export const updateScenerioActive = async(scenarioActiveId) => {
  return await axiosClient.put(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/update-active-field/${scenarioActiveId}/`)
}
 
export const deleteScenerios = async(scenarioId) => {
  return await axiosClient.delete(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/delete/${scenarioId}/`)
}

export const recordScenerios = async(scenarioId) => {
  return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/view-scenarios/${scenarioId}/`)
}

export const lockScenerio = async(params, scenarioId) => {
  return await axiosClient.put(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/locks-scenario/${scenarioId}/`, params)
}


//intial parameters api end points

export const getIntialParameterTableListing = async(scenarioActiveId) => {
  return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/initial-parameter/view/${scenarioActiveId}/`)
}

export const updateInitialParameterTable = async(params, scenarioId) => {
  return await axiosClient.put(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/initial-parameter/update/${scenarioId}/`, params)
}

// Unit Wise Reserve Contribution

export const getUnitWiseReserveTableListing = async(scenarioActiveId) => {
  return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/unit-wise-reserve-contribution/view/${scenarioActiveId}/`)
}

export const updateUnitWiseContribution = async(params,scenarioId) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/create-or-update-unit-wise-contribution/${scenarioId}/`,params)
}

export const deleteUnitWiseContribution = async(scenarioId) => {
  return await axiosClient.delete(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/delete-unit-wise-contribution/${scenarioId}/`)
}




//components api end points

export const getComponentTableListing = async(scenarioActiveId) => {
  return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/components/view/${scenarioActiveId}/`)
}

export const createAndUpdateComponentTable = async(params,scenarioId) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/components/create-or-update/${scenarioId}/`,params)
}

export const downloadComponentList = async(scenarioActiveId) => {
  return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/componenets/download-csv/${scenarioActiveId}/`)
}

export const uploadComponentList = async(scenarioActiveId, payload) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/components/upload/${scenarioActiveId}/`,payload)
}

export const deleteComponents = async(id) => {
  return await axiosClient.delete(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/components/delete/${id}/`)
}


//special assessment api end points

export const getSpecialAssessmentDefaultTableListing = async() => {
  return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/special-assessments/view/`)
}

export const getSpecialAssessmentTableListing = async(scenarioId) => {
  return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/special-assessments/view/${scenarioId}/`)
}

export const updateSpecialAssessmentTable = async(params, scenarioId) => {
  return await axiosClient.put(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/special-assessments/update/${scenarioId}/`, params)
}
// export const updateSpecialAssessmentTable = async(params) => {
//   return await axiosClient.put(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/special-assessments/update/`,params)
// }


//units variable api end points

export const getUnitsVariableTableListing = async(scenarioId) => {
  return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/units-if-variable/view/${scenarioId}/`)
}

export const createAndUpdateUnitsVariableTable = async(params,scenarioId) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/units-if-variable/create-or-update/${scenarioId}/`,params)
}

export const deleteUnitsIfVariable = async(id) => {
  return await axiosClient.delete(`${process.env.REACT_APP_BASE_URL}` +  `scenario-management/units-if-variable/delete/${id}/`)
}

//loans and expenditure api end points

export const getloansAndExpTableListing = async(scenarioId) => {
  return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/loan-other-expenditures/view/${scenarioId}/`)
}

export const createAndUpdateloansAndExpTable = async(params,scenarioId) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/loan-other-expenditures/update-or-create/${scenarioId}/`,params)
}

export const deleteLoansExpenditure = async(id) => {
  return await axiosClient.delete(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/loan-other-expenditures/delete/${id}/`)
}

//monthly common expenses api end points

export const getMCETableListing = async(scenarioActiveId) => {
  return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/monthly-common-expenses/view/${scenarioActiveId}/`)
}

export const createAndUpdateMCETable = async(params,scenarioId) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/monthly-common-expenses/create-or-update/${scenarioId}/`,params)
}

export const MCEStatus = async(scenarioActiveId, params) => {
  return await axiosClient.put(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/monthly-common-expenses/status/${scenarioActiveId}/`, params)
}

export const deleteMonthlyExpense = async(id) => {
  return await axiosClient.delete(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/monthly-common-expenses/delete/${id}/`)
}

//current funding plan api end points

export const getCFPListing = async(scenarioActiveId, params) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `funding-plan/current-funding-plan/view/${scenarioActiveId}/`, params)
}

//Threshold funding plan api end points

export const getTFPListing = async(scenarioActiveId, params) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `funding-plan/threshold-funding-plan/view/${scenarioActiveId}/`, params)
}

//full funding plan api end points

export const getFFPListing = async(scenarioActiveId, params) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `funding-plan/full-funding-plan/view/${scenarioActiveId}/`, params)
}

//years-for-funding-plan api end point

export const getYearFundingPlanListing = async(scenarioActiveId) => {
  return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `funding-plan/years-for-funding-plan/${scenarioActiveId}/`)
}

// Dashboard api end points 

export const getThirtyYrExpenditure = async(scenarioActiveId) => {
  return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `dashboard/thirty-year-expenditure/${scenarioActiveId}/`);
} 

export const getPercentFunded = async(scenarioActiveId,params) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `dashboard/percent-funded/${scenarioActiveId}/`,params);
}

export const getFundingPlansVSFFBalance = async(scenarioActiveId,params) => {
  return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `dashboard/funding-plans-vs-fully-funded-balance/${scenarioActiveId}/`,params);

}
  // Component deatils api end points 

  export const getCFPComponentDetails = async(scenarioActiveId) => {
    return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `component-detail/current-funding-plan/view/${scenarioActiveId}/`);
  }

  export const getTFPComponentDetails = async(scenarioActiveId) => {
    return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `component-detail/threshold-funding-plan/view/${scenarioActiveId}/`);
  }
 
  export const getFFPComponentDetails = async(scenarioActiveId) => {
    return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `component-detail/full-funding-plan/view/${scenarioActiveId}/`);
  }
  

  // Expenditures api end points
  export const getExpendituresTable = async(scenarioActiveId) => {
    return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `expenditures/view/${scenarioActiveId}/`);
  }

  // Acount info api end points
  export const getAccountCommunityInfo = async(communityId) => {
    return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `accounts/communityInfo/view/${communityId}/`);
  }
  
  export const createCommunityInfo = async(params) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `accounts/communityInfo-create/`,params);
  }

  // export const getCommunityInfoID = async() => {
  //     return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `accounts/fetch-community-id/`);
  // }

  export const getCommunityInfoID = async(params) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `accounts/fetch-community/`,params);
  }

  export const getCommunityInfoList = async() => {
    return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `accounts/communityInfo-list/`);
  }

  export const updateCommunityInfo = async(community_id, params) => {
    return await axiosClient.put(`${process.env.REACT_APP_BASE_URL}` + `accounts/communityInfo-update/${community_id}/`,params)
  }

  export const updateColorChoice = async(community_id,params) => {
    return await axiosClient.put(`${process.env.REACT_APP_BASE_URL}` + `accounts/communityInfo/update-colors/${community_id}/`,params)
  }

  export const updateAccountInfo = async(userId, params) => {
    return await axiosClient.patch(`${process.env.REACT_APP_BASE_URL}` + `accounts/update/${userId}/`,params)
  }

  export const getUserList = async(communityId) => {
    return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `accounts/users-list/${communityId}/`)
  }

  export const deleteCommunityInfo = async(communityId) => {
    return await axiosClient.delete(`${process.env.REACT_APP_BASE_URL}` + `accounts/communityInfo-delete/${communityId}/`)
  }

  export const componentListapi = async(page) => {
    return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/components/reference/databaselist/?page=${page}`)
  }

  export const deleteMultipleRowsComponents = async(params) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/components/delete/reference-database/`,params)
  }

  export const UpdateRefrenceComponent = async(params) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/components/create-or-update/reference-database/`,params)
  }

  export const DownloadComponents = async() => {
    return await axiosClient.get(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/components/download-csv/reference-database/`)
  }

  export const AddSelectedComponents = async(params,scenarioActiveId) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/components/create_components_from_reference/${scenarioActiveId}/`,params)
  }

  export const ImportComponents = async(params) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/components/create_selected_components/`,params)
  }
  
  export const UpdateCSV = async(params) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `scenario-management/components/upload/reference-database/`,params)
  }

  // log in api end points

  export const loginUser = async(params) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}` + `accounts/login/`,params)
  }

  export const loginUserRails = async(params) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}` + `accounts/react_login/`,params)
  }

  export const logoutUser = async(params) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}` + `accounts/logout/`,params)
  }

  export const forgotPassword = async(params) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}` + `accounts/forget-password/`,params)
  }

  export const createUser = async(params) => {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}` + `accounts/create/`,params)
  }

  export const refreshToken = async(payload) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `accounts/token/refresh/`, payload )
  }

  export const resetPassword = async(id,payload) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `accounts/password-reset/${id}/`, payload )
  }

  export const verifyOtp = async(payload) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `accounts/verify-otp/`, payload )
  }

  export const changePassword = async(payload) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `accounts/change-password/`, payload )
  }



  //reports api end points
  export const getGeneratePdf = async(scenarioActiveId,params) => {
    return await axiosClient.post(`${process.env.REACT_APP_BASE_URL}` + `reports/generate-pdf/${scenarioActiveId}/`,params);
  }

