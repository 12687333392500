import React, { useState } from "react";

import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@mui/material";
import { updateAccountInfo } from "../../services/Services";
import { useSelector } from "react-redux";

import './AccountInfo.css';


const ModifyDialog = (props) => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [contact, setContact] = useState('');
    const [accInfo, setAccInfo] = useState('');
    const [updateSnackBar, setUpdateSnackbar] = useState(false);
    const login_user_id = useSelector((state) => state?.accountInfo?.communitydata?.user_Detail?.user_id);


    const handleChangeName = (e) => {
        setName(e.target.value);
    }
    const handleChangeSurname = (e) => {
        setSurname(e.target.value);
    }
    const handleChangeCompany = (e) => {
        setCompany(e.target.value);
    }
    const handleChangePosition = (e) => {
        setPosition(e.target.value);
    }
    
    const handleChangeContact = (e) => {
        setContact(e.target.value);
    }
    const handleChangeAccInfo = (e) => {
        setAccInfo(e.target.value);
    }

    const handleSaveAccountInfo = () => {
        const params = {
            "email": props.accountCummunityApiInfo?.user_Detail?.email,
            "first_name":name ? name : props.accountCummunityApiInfo.user_Detail?.first_name,
            "last_name": surname ? surname : props.accountCummunityApiInfo.user_Detail?.last_name ,
            "phone_no": contact ? contact :  props.accountCummunityApiInfo.user_Detail?.phone_no,
            "company": company ? company :  props.accountCummunityApiInfo.user_Detail?.company,
            "position": position ? position :  props.accountCummunityApiInfo.user_Detail?.company,
            "additional_info": accInfo ? accInfo :  props.accountCummunityApiInfo.user_Detail?.additional_info,
            "role": null,
            "is_active": true,
            "is_superuser": false
        }

        updateAccountInfo(login_user_id, params).then((res) => {
            setUpdateSnackbar(true);
            props.fetchAccountCommunityInfo();
            setTimeout(() => {
                setUpdateSnackbar(false)
                props.setOpenDialog(false);
            }, 1000);
            return res;
        }).catch((err) => {
            console.error(err);
        })
    }

    const handleCloseSnackbar = () => {
        setUpdateSnackbar(false);
    };

    return (
        <>
            <Dialog
                open={props.openDialog}
                onClose={props.handleDialog}
                maxWidth='lg'
                PaperProps={{
                    sx: {
                        width: "50%",
                        overflow: 'hidden !important',
                    }
                }}
            >
                <DialogTitle style={{ borderBottom: '1px solid #e5e5e5', padding: '15px', }}>
                    <h4 style={{ margin: 0, fontSize: '18px' }}>Information on your account and subscription</h4>
                </DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        <div>
                            <form style={{ width: '50%' }} className="account_info_form">
                                <div>
                                    <label htmlFor="login">Login</label><br />
                                    <input id='login' className="form-control" type="email" disabled defaultValue={props.accountCummunityApiInfo?.user_Detail?.email} />
                                </div>
                                <div>
                                    <label htmlFor="name">Name</label><br />
                                    <input id="name" className="form-control" type="text" onChange={handleChangeName} defaultValue={props.accountCummunityApiInfo?.user_Detail?.first_name} />
                                </div>
                                <div>
                                    <label htmlFor="surname">Surname</label><br />
                                    <input id="surname" className="form-control" type="text" onChange={handleChangeSurname} defaultValue={props.accountCummunityApiInfo?.user_Detail?.last_name}/>
                                </div>
                                <div>
                                    <label htmlFor="company">Company</label><br />
                                    <input id="company" className="form-control" type="text" onChange={handleChangeCompany} defaultValue={props.accountCummunityApiInfo?.user_Detail?.company} />
                                </div>
                                <div>
                                    <label htmlFor="position">Position</label><br />
                                    <input id="position" className="form-control" type="text" onChange={handleChangePosition} defaultValue={props.accountCummunityApiInfo?.user_Detail?.company} />
                                </div>
                                <div>
                                    <label htmlFor="contact">Contact/Phone</label><br />
                                    <input id="contact" className="form-control" type="number" onChange={handleChangeContact} defaultValue={props.accountCummunityApiInfo?.user_Detail?.phone_no} />
                                </div>
                                <div>
                                    <label htmlFor="additional_info">Additional Info</label><br />
                                    <input id="additional_info" className="form-control" type="text" placeholder="you can type here any aditional note..."  onChange={handleChangeAccInfo} defaultValue={props.accountCummunityApiInfo?.user_Detail?.additional_info}/>
                                </div>
                            </form>
                        </div>
                    </DialogContentText>
                    <Snackbar
                        open={updateSnackBar}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        User Updated Succesfully.
                        </Alert>
                    </Snackbar>
                </DialogContent>
                <DialogActions style={{ borderTop: '1px solid #e5e5e5' }}>
                    <div className="d-flex" style={{ float: 'right', gap: '5%', padding: '10px' }}>
                        <div>
                            <button className="button_class" onClick={handleSaveAccountInfo}>Save</button>
                        </div>
                        <div>
                            <button className="button_class" onClick={props.handleDialog}>Cancel</button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ModifyDialog;