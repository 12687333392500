import React from 'react'
// import { Provider } from 'react-redux';
// import Dashboard from './pages/dashboard/Dashboard';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

// import store from './redux/store'
import LogInPage from './components/loginPage/LogInPage';
import RegistrationPage from './components/registrationPage/registrationPage';
import ResetPassword from './components/ResetPassword/resetPassword';
import ForgotPassword from './components/ForgotPassword/forgotPassword';
import OTP from './components/Otp/otp';
import ChangePassword from './components/ChangePassword/changePassword';
import Permission from './components/PermissionDenied/permission';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { ToastContainer } from 'react-toastify';
// import Error from './Error';
function App() {

  return (
    <>
    <Router>
          <Routes>
            <Route path ='/' element={<LogInPage />} />
            <Route path='/permissionDenied' element={ <Permission/> } />
            {/* <Route path='/dashboard' element={ authenticate == true ? <Dashboard /> : <Permission/> } /> */}
            <Route path='/dashboard' element={<PrivateRoute />} />
            <Route path='/registrationPage' element={ <RegistrationPage />} />
            <Route path='/resetPassword' element={ <ResetPassword />} />
            <Route path='/forgotPassword' element={ <ForgotPassword />} />
            <Route path='/otp' element={ <OTP />} />
            <Route path='/changePassword' element={ <ChangePassword/>} />
            <Route path='*' element={<Permission />}/>
          </Routes>
    </Router>
    <ToastContainer/>
    </>
  );
}

export default App;