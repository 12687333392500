import React, { useEffect, useState } from 'react';
// import Chart from 'react-apexcharts';

import '../component-detail/ComponentDetail.css';
import { getCFPComponentDetails, getFFPComponentDetails, getTFPComponentDetails } from '../../services/Services';
import { useSelector } from 'react-redux';
import CDFundDistributionTable from './CDFundDistributionTable';
import SearchBar from 'material-ui-search-bar';
import { CircularProgress, makeStyles } from '@material-ui/core';
import PieChartCD from './PieChartCD';

const useStyles = makeStyles({
    searchBody: {
        marginBottom: '-22px',
        display: 'flex',
        justifyContent: 'end'
    },
    searchBar: {
        width: '18%',
        height: '45px',
        marginTop: '40px',
        // border: '1px solid black',
        boxShadow: '0px 4px 8px -1px rgba(0,0,0,0.2), 0px 3px 3px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important'
    }
})

const ComponentDetailTab = (props) => {
    //redux hook 
    const { activeScenarioId } = useSelector((state) => state.initialParameter);
    const classes = useStyles();

    const buttons = document.getElementsByClassName('button_tab');

    for (let i = 0; i < buttons.length; i++) {
        buttons[i].addEventListener('click', function () {
            for (let j = 0; j < buttons.length; j++) {
                buttons[j].classList.remove('active');
            }
            this.classList.add('active');
        });
    }

    const buttons2 = document.getElementsByClassName('button_tab2');

    for (let i = 0; i < buttons2.length; i++) {
        buttons2[i].addEventListener('click', function () {
            for (let j = 0; j < buttons2.length; j++) {
                buttons2[j].classList.remove('active');
            }
            this.classList.add('active');
        });
    }

    const [FundDistributionTable, setFundDistributionTable] = useState([]);
    const [fundingTab, setFundingTab] = useState("Current Funding Plan");
    const [selectedComponent, setSelectedComponent] = useState("individual component");
    const [tableKeys, setTableKeys] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    // const [categoryData, setCategoryData] = useState([]);

    const handleCFPComponentDetailsTable = () => {
        getCFPComponentDetails(activeScenarioId).then((res) => {
            props.setLoading(true);
            if (selectedComponent == "individual component") {
                setFundDistributionTable(res?.data?.current_funding_plan?.response_of_ByIndividualComponent);
            }
            else if (selectedComponent == "component category") {
                setFundDistributionTable(res?.data?.current_funding_plan?.response_of_BYComponentCategory);
            }
            else {
                setFundDistributionTable(res?.data?.current_funding_plan?.response_of_Byunits);
            }
            props.setLoading(false);

        }).catch((err) => {
            props.setLoading(true);
            console.error(err);
        })
    }

    const handleTFPComponentDetailsTable = () => {
        getTFPComponentDetails(activeScenarioId).then((res) => {
            props.setLoading(true);
            if (selectedComponent == "individual component") {
                setFundDistributionTable(res?.data?.threshold_funding_plan?.response_of_ByIndividualComponent);
            }
            else if (selectedComponent == "component category") {
                setFundDistributionTable(res?.data?.threshold_funding_plan?.response_of_BYComponentCategory);
            }
            else {
                setFundDistributionTable(res?.data?.threshold_funding_plan?.response_of_Byunits);
            }
            props.setLoading(false);


        }).catch((err) => {
            props.setLoading(true);
            console.error(err);
        })
    }

    const handleFFPComponentDetailsTable = () => {
        getFFPComponentDetails(activeScenarioId).then((res) => {
            props.setLoading(true);
            if (selectedComponent == "individual component") {
                setFundDistributionTable(res?.data?.full_funding_plan?.response_of_ByIndividualComponent);
            }
            else if (selectedComponent == "component category") {
                setFundDistributionTable(res?.data?.full_funding_plan?.response_of_BYComponentCategory);
            }
            else {
                setFundDistributionTable(res?.data?.full_funding_plan?.response_of_Byunits);
            }
            props.setLoading(false);

        }).catch((err) => {
            props.setLoading(true);
            console.error(err);
        })
    }
    const handleSearchChange = (e) => {
        setSearchValue(e);
    };

    useEffect(() => {
        if (selectedComponent == "individual component") {
            setTableKeys([
                { key: 'component_title', value: 'Component Title' },
                { key: 'useful_life', value: 'Useful Life' },
                { key: 'remaining_useful_life', value: 'Remaining Useful Life' },
                { key: 'replacement_cost', value: 'Replacement Cost' },
                { key: 'fully_funded_balance', value: 'Fully Funded Balance' },
                { key: 'yearly_cost', value: 'Yearly Cost' },
                { key: 'funded_balance', value: 'Funded Balance' },
                { key: 'reserve_contribution', value: 'Reserve Contribution' },
            ])
        }
        else if (selectedComponent == "component category") {
            setTableKeys([
                { key: 'category', value: 'Category' },
                { key: 'useful_life', value: 'Useful Life' },
                { key: 'remaining_useful_life', value: 'Remaining Useful Life' },
                { key: 'replacement_cost', value: 'Replacement Cost' },
                { key: 'fully_funded_balance', value: 'Fully Funded Balance' },
                { key: 'yearly_cost', value: 'Yearly Cost' },
                { key: 'funded_balance', value: 'Funded Balance' },
                { key: 'reserve_contribution', value: 'Reserve Contribution' },
            ])
        }
        else {
            setTableKeys([
                { key: 'unit', value: 'Unit' },
                { key: 'building', value: 'Building' },
                { key: 'address', value: 'Address' },
                { key: 'square_footage', value: 'Square Footage' },
                { key: 'percentage', value: 'Percentage' },
                { key: 'fixed_reserve_contribution', value: 'Fixed Reserve Contribution' },
                { key: 'variable_reserve_contribution', value: 'Variable Reserve Contribution' },
                { key: 'total_reserve_contribution', value: 'Total Reserve Contribution' },
            ])
        }
        if (fundingTab == "Current Funding Plan") {
            handleCFPComponentDetailsTable();
        }
        else if (fundingTab == "Threshold Funding Plan") {
            handleTFPComponentDetailsTable();
        }
        else {
            handleFFPComponentDetailsTable();
        }
    }, [fundingTab, selectedComponent])


    // useEffect(() => {
    //     getCFPComponentDetails(activeScenarioId).then((res) => {
    //         if(res?.data?.current_funding_plan) {
    //             setCategoryData(res?.data?.current_funding_plan?.response_of_BYComponentCategory)
    //         } else if(res?.data?.threshold_funding_plan) {
    //             setCategoryData(res?.data?.threshold_funding_plan?.response_of_BYComponentCategory)
    //         } else{
    //             setCategoryData(res?.data?.full_funding_plan?.response_of_BYComponentCategory)
    //         }
    //     }).catch((err) => {
    //         props.setLoading(true);
    //         console.error(err);
    //     })

    // }, [])

    return (
        <>
            {/* <div > */}
                {props.loading && <CircularProgress className='circular_loader' />}
            {/* </div> */}
            {!props.loading &&
                <>
                    <div className='component_box' style={{ marginTop: "50px" }}>
                        <div style={{ padding: '10px 0px 0px 20px' }}>
                            <label style={{ fontSize: '18px', fontWeight: '500', color: "var(--primary-color)" }}>Contribution and Fund Breakdown</label>
                        </div>

                        <div className='d-flex col-10 col-sm-6 col-md-6 col-lg-6 col-xl-10 col_pd' >
                            <div style={{ marginTop: '70px', marginLeft: "30px", marginRight: "30px" }}>
                                <div style={{ gap: "8px", marginBottom: '55px', position: 'relative' }}>
                                    <button style={{ marginRight: "30px", width: "170px", height: "70px" }} className='button_tab2 active' onClick={() => setFundingTab("Current Funding Plan")}>Current Funding Plan</button>
                                    <button style={{ marginRight: "30px", width: "170px", height: "70px" }} className='button_tab active' onClick={() => setSelectedComponent("individual component")}>By individual component</button>
                                    <div style={{ padding: '1px 17px', background: 'black', position: 'absolute', top: '50%', left: '42%', borderRadius: '0px' }}></div>
                                </div>
                                <div style={{ gap: "8px", marginBottom: '55px', position: 'relative' }}>
                                    <button style={{ marginRight: "30px", width: "170px", height: "70px" }} className='button_tab2' onClick={() => { setFundingTab("Threshold Funding Plan"); }}>Threshold funding plan</button>
                                    <button style={{ marginRight: "30px", width: "170px", height: "70px" }} className='button_tab' onClick={() => setSelectedComponent("component category")}>By component category</button>
                                    <div style={{ padding: '1px 17px', background: 'black', position: 'absolute', top: '50%', left: '42%', borderRadius: '0px' }}></div>
                                </div>
                                <div style={{ gap: "8px", marginBottom: '55px', position: 'relative' }}>
                                    <button style={{ marginRight: "30px", width: "170px", height: "70px" }} className='button_tab2' onClick={() => { setFundingTab("Full Funding Plan"); }}>Full funding plan</button>
                                    <button style={{ marginRight: "30px", width: "170px", height: "70px" }} className='button_tab' onClick={() => setSelectedComponent("units")}>By units</button>
                                    <div style={{ padding: '1px 17px', background: 'black', position: 'absolute', top: '50%', left: '42%', borderRadius: '0px' }}></div>
                                </div>
                            </div>

                            <div style={{ marginTop: "20px", marginRight: "-800px", width: "1000px" }}>
                                <div className={classes.searchBody}>
                                    <SearchBar
                                        style={{ marginBottom: "-25px", width: "30%" }}
                                        className={classes.searchBar}
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                <label style={{ marginBottom: '-20px' }} className='table_above_label'>Funds Distribution by individual components (according to Current Funding Plan)</label><br /><br />
                                <div>
                                    <CDFundDistributionTable FundDistributionTable={FundDistributionTable} tableKeys={tableKeys} searchValue={searchValue} />
                                </div>
                            </div>
                        </div>


                    </div><div className='component_chart_box'>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <label style={{ fontSize: '18px', fontWeight: '500', color: 'var(--primary-color)' }}>
                                Significance of each component
                            </label>
                        </div>
                        <div style={{ marginTop: '65px', display: 'flex', justifyContent: 'center' }}>
                            <PieChartCD FundDistributionTable={FundDistributionTable} tableKeys={tableKeys} selectedComponent={selectedComponent} />
                        </div>
                    </div>
                </>
            }
        </>

    )
}
export default ComponentDetailTab;