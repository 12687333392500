import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, makeStyles } from "@material-ui/core";
// import Slide from "@material-ui/core/Slide";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Handsontable from "handsontable";

import { getCFPListing } from "../../../services/Services";
import AdvancedFundingPlans from "./AdvancedFundingParameters";
import { dispatchCFPAdvanceParameterData, dispatchCFPCheckData, dispatchCFPCustomValueData, dispatchCFPPercentData, dispatchCFPSelectedRowData, dispatchCFPTableData, dispatchCFPUniformPercentData } from "../../../redux/actions/fundingPlansAction";

import './CurrentFundingPlans.css'
import { CSVLink } from "react-csv";
import showNotification from "../../common/Notification";

const useStyles = makeStyles({
    oddRows: {
        backgroundColor: '#f9f9f9',
    },
    evenRows: {
        backgroundColor: '#fff',
    },
    hoverRow: {
        '&:hover': {
            backgroundColor: '#f9f9f9'
        }
    },
    tableHead: {
        // backgroundColor: '#ecf0f5',
        backgroundColor: '#white',
        borderTop: '2px solid #111',
        borderBottom: '2px solid #111 !important',
    },
    tableHeadCells: {
        textAlign: 'center',
        padding: '10px 18px',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#333'
    },
    tableBodyCells: {
        textAlign: 'center'
    },
    hoverTableCell: {
        '&:hover': {
            fontWeight: 'bold'
        }
    },
    rowSelect: {
        backgroundColor: '#aab7d1'
    },
    bodyRow: {
        borderBottom: '1px solid #ddd !important'
    },
    green: {
        background: 'rgb(155, 245, 155)'
    },
    orange: {
        background: 'rgb(255, 235, 153)'
    },
    red: {
        background: 'rgb(242, 91, 61)'
    }
})

const CurrentFundingPlans = (props) => {
    //redux hook
    const { activeScenarioId } = useSelector((state) => state.initialParameter);
    const { cfpTableData, cfpCheckData, cfpPercentData, cfpUniformPercentData, cfpAdvanceParameterData, cfpSelectedRowData, cfpCustomValueData } = useSelector((state) => state.fundingPlans);
    const { inpEditedData } = useSelector((state) => state.initialParameter);
    const dispatch = useDispatch();

    //usestate
    const [openAFP, setOpenAFP] = useState(cfpAdvanceParameterData);
    const [checkboxValue, setCheckboxValue] = useState(cfpCheckData);
    const [percentageValue, setPercentageValue] = useState(cfpPercentData);
    const [uniformPercentageValue, setUniformPercentageValue] = useState(cfpUniformPercentData);
    const [customData, setCustomData] = useState([]);
    const [customDataValue, setCustomDataValue] = useState(cfpCustomValueData);
    const [CFPTableData, setCFPTableData] = useState(cfpTableData);
    const [selectedRows, setSelectedRows] = useState(cfpSelectedRowData);
    const [isCopied, setIsCopied] = useState(false);

    const classes = useStyles();

    const formatePercent = (value) => {
        return `${value?.toFixed(2)}%`
    }

    const formatDollar = (value) => {
        return `$${value?.toLocaleString()}`
    }

    const  formatNumberWithCommas = (number) => {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }

    const handleChange = () => {
        setOpenAFP(!openAFP);
        dispatch(dispatchCFPAdvanceParameterData(!openAFP));
    }

    const handleFirstYear = (e) => {
        setCheckboxValue(e.target.checked);
        dispatch(dispatchCFPCheckData(e.target.checked));
    }

    const handlePercentageChange = (e) => {
        const key = 'percent_change';
        setPercentageValue(e.target.value);
        dispatch(dispatchCFPPercentData(e.target.value));
        if (e.target.value == 'Custom') {
            setCustomDataValue(CFPTableData?.map(item => item[key]));
            dispatch(dispatchCFPCustomValueData(CFPTableData?.map(item => item[key])));
        }
        else {
            setCustomDataValue([]);
            dispatch(dispatchCFPCustomValueData([]));
        }
    }

    const handleUniformPercentChange = (e) => {
        setUniformPercentageValue(parseInt(e.target.value));
        dispatch(dispatchCFPUniformPercentData(e.target.value));
    }

    const afterChange = (changes, source) => {
        if (source === 'edit') {
            const newData = Handsontable?.helper?.arrayMap(customData, (row) => [...row]);
            changes.forEach(([row, col, oldValue, newValue]) => {
                // console.log("oldvalue", oldValue);
                // changes.forEach(([row, col, newValue]) => {
                newData[row][col] = oldValue;
                newData[row][col] = newValue;
            });
            setCustomData(newData);
            setCustomDataValue(newData?.map(item => item[1] != null ? item[1] : 0));
            dispatch(dispatchCFPCustomValueData(newData?.map(item => item[1])));
        }
    };

    const handleSelectedRows = (index) => {
        if (selectedRows?.includes(index)) {
            setSelectedRows(selectedRows?.filter(data => data !== index));
            dispatch(dispatchCFPSelectedRowData(selectedRows?.filter(data => data !== index)));
        } else {
            setSelectedRows([...selectedRows, index]);
            dispatch(dispatchCFPSelectedRowData([...selectedRows, index]));
        }
    };


    const handleReset = () => {
        setUniformPercentageValue(3);
        if (percentageValue == 'Custom')
            setCustomDataValue([0, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3]);
    }

    const alertMessage = useMemo(() => {
        if (CFPTableData.length > 0) {
            const item = CFPTableData[0];
            if (item?.reserve_contribution && inpEditedData?.length != 0) {
                const result = Math.abs(item?.reserve_contribution - inpEditedData[0][4]);
                return `Increased Reserve Contribution: $${formatNumberWithCommas(result)}`;
            }
        }
        return null;
    }, [CFPTableData, inpEditedData]);

    let params = {
        "reserve_contribution":
        {
            "first_year": checkboxValue,
            "percentage_change": percentageValue,
            "default_value": uniformPercentageValue ? uniformPercentageValue : 3,
            "custom_value": customDataValue
        },
        "special_assessments": {}
    }

    useEffect(() => {
        setCustomData(CFPTableData?.map(({ year, percent_change }) => [year, percent_change]));
    }, [CFPTableData])


    useEffect(() => {
        getCFPListing(activeScenarioId, params).then((res) => {
            props.setLoading(true);
            setCFPTableData(res?.data?.data);
            dispatch(dispatchCFPTableData(res?.data?.data));
            props.setLoading(false);

        })
            .catch((err) => {
                showNotification("warning",err?.response?.data?.error)
                props.setLoading(false);
            })
    }, [checkboxValue, percentageValue, uniformPercentageValue, customDataValue])

    const handleCopyClick = () => {
        const table = document.getElementById('tableToCopy');
        const range = document.createRange();
        range.selectNode(table);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        if (!isCopied) {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1000);
        }
    };

    const headers = [
        { label: 'Year', key: 'year' },
        { label: 'Starting Balance', key: 'starting_balance' },
        { label: 'Reserve Contribution', key: 'reserve_contribution' },
        { label: 'Percent Change', key: 'percent_change' },
        { label: 'Special Assessments', key: 'special_assessments' },
        { label: 'Interest Earned', key: 'interest_earned' },
        { label: 'Expenditures', key: 'expenditures' },
        { label: 'Ending Balance', key: 'ending_balance' },
        { label: 'Fully Funded Balance', key: 'fully_funded_balance' },
        { label: 'Percent Funded', key: 'percent_funded' },
    ];
    const customFilename = 'currentfundingplans.csv';

    const printTable = () => {
        const printWindow = window.open('', '', 'width=1000,height=600');

        printWindow.document.open();
        printWindow.document.write('<html><head><title>Current Funding Plan</title></head><body>');

        printWindow.document.write('<table border="0">');

        printWindow.document.write('<thead><tr>');
        Object.keys(cfpTableData.length > 0 && cfpTableData[0]).forEach((header) => {
            printWindow.document.write(`<th>${header}</th>`);
        });
        printWindow.document.write('</tr></thead>');

        printWindow.document.write('<tbody>');
        cfpTableData.length > 0 && cfpTableData.forEach((row) => {
            printWindow.document.write('<tr>');
            Object.values(row).forEach((value) => {
                printWindow.document.write(`<td>${value}</td>`);
            });
            printWindow.document.write('</tr>');
        });
        printWindow.document.write('</tbody>');

        printWindow.document.write('</table>');
        printWindow.document.write('</body></html>');

        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    };


    return (
        <>
            <div >
                {props.loading && <CircularProgress className='circular_loader' />}
            </div>
            <div className="CFP_main_div">
                <div>
                    <Card className="funding_container">
                        <div className="CFP_header">
                            <div className="CFP_header_inner" style={{ width: '50%' }}>
                                <h3>Current Funding Plan</h3>
                            </div>
                            <div className="AFP_button"
                                style={{
                                    //  width:'50%',
                                    marginLeft: '470px',
                                }}
                            >
                                <button onClick={handleChange}><SettingsSuggestIcon style={{ marginRight: '5px' }} />Advanced Funding Parameters</button>
                            </div>
                        </div>
                    </Card>
                </div>
                {!props.loading &&
                    <div className="CFP_inner_div">
                        <div className="CFP_table_div">
                            <div className="CFP_table_btn">
                                <div>
                                    <button className="button_class" style={{ width: '70px' }} onClick={handleCopyClick}>Copy</button>
                                </div>
                                <div>
                                    <CSVLink data={cfpTableData} headers={headers} filename={customFilename}>
                                        <button className="button_class" style={{ width: '70px' }}>Excel</button>
                                    </CSVLink>
                                </div>
                                <div>
                                    <button className="button_class" style={{ width: '70px' }} onClick={printTable}>Print</button>
                                </div>
                            </div>
                            {isCopied && (
                                <div className="popup">
                                    Copy to clipboard!
                                </div>
                            )}
                            <TableContainer component={Paper}>
                                <Table id="tableToCopy">
                                    <TableHead>
                                        <TableRow className={classes.tableHead}>
                                            <TableCell className={classes.tableHeadCells}>Year</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Starting Balance</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Reserve Contribution</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Percent Change</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Special Assessments</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Interest Earned</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Expenditures</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Ending Balance</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Fully Funded Balance</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Percent Funded</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {CFPTableData ? (CFPTableData.map((item, index) => (
                                            <TableRow onClick={() => handleSelectedRows(index)} className={`${index % 2 == 0 ? classes.evenRows : classes.oddRows} ${selectedRows.includes(index) ? '' : classes.hoverRow} ${selectedRows.includes(index) ? classes.rowSelect : ''} ${classes.bodyRow}`} key={index}>
                                                <TableCell className={classes.tableBodyCells} align="center">{item.year}</TableCell>
                                                {/* <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item?.starting_balance?.toFixed(2))}</TableCell> */}
                                                {
                                                    item?.starting_balance >= 0 ? (
                                                        <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item?.starting_balance?.toFixed(2)))}</TableCell>
                                                    ) : (
                                                        <TableCell className={classes.tableBodyCells} style={{ color: 'red' }} align="center">{formatNumberWithCommas(formatDollar(item?.starting_balance?.toFixed(2)))}</TableCell>
                                                    )
                                                }
                                                <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item?.reserve_contribution?.toFixed(2)))}</TableCell>
                                                <Tooltip title={index == 0 ? alertMessage : ''} placement="top">
                                                    <TableCell className={`${classes.tableBodyCells} ${index === 0 ? classes.hoverTableCell : ''}`} align="center" >{formatePercent(item.percent_change)}</TableCell></Tooltip>
                                                <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item.special_assessments))}</TableCell>
                                                <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item.interest_earned))}</TableCell>
                                                <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item?.expenditures?.toFixed(2)))}</TableCell>
                                                {/* <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item?.ending_balance?.toFixed(2))}</TableCell> */}
                                                {item?.ending_balance >= 0 ?
                                                    (
                                                        <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item?.ending_balance?.toFixed(2)))}</TableCell>
                                                    ) :
                                                    (
                                                        <TableCell className={classes.tableBodyCells} style={{ color: 'red' }} align="center">{formatNumberWithCommas(formatDollar(item?.ending_balance?.toFixed(2)))}</TableCell>
                                                    )
                                                }
                                                <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item?.fully_funded_balance?.toFixed(2)))}</TableCell>
                                                <TableCell className={`${classes.tableBodyCells} ${item.percent_funded > 70 ? classes.green : ''} ${item.percent_funded > 30 && item.percent_funded < 70 ? classes.orange : ''} ${item.percent_funded < 30 ? classes.red : ''}`} align="center">{formatePercent(item.percent_funded)}</TableCell>
                                            </TableRow>
                                        )))
                                            :
                                            <TableRow>Data is not available</TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        {openAFP ? (
                            <>
                                <div >
                                    <AdvancedFundingPlans checkboxValue={checkboxValue} percentageValue={percentageValue} uniformPercentageValue={uniformPercentageValue} customData={customData}
                                        handleFirstYear={handleFirstYear} handlePercentageChange={handlePercentageChange} handleUniformPercentChange={handleUniformPercentChange} afterChange={afterChange} handleReset={handleReset} />
                                </div>

                                {/* <div>
                                    <Slide direction="down" in={openAFP} mountOnEnter unmountOnExit>
                                        <AdvancedFundingPlans checkboxValue={checkboxValue} percentageValue={percentageValue} uniformPercentageValue={uniformPercentageValue} customData={customData}
                                            handleFirstYear={handleFirstYear} handlePercentageChange={handlePercentageChange} handleUniformPercentChange={handleUniformPercentChange} afterChange={afterChange} handleReset={handleReset} />
                                    </Slide>
                                </div> */}
                            </>
                        ) : " "}

                    </div>
                }
            </div>

        </>
    );
}
export default CurrentFundingPlans;