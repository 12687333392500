import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Accordion, AccordionSummary, AccordionDetails, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';

import 'react-datasheet-grid/dist/style.css';
import '../../../../assets/styles/style.css';
import './LoansOrOtherExpenditure.css';
import { dispatchLoansAndExpEditedData } from '../../../../redux/actions/scenarioManagementAction';
import "../scenarioManagementTable/ScenarioManagementTable.css"
import { deleteLoansExpenditure } from '../../../../services/Services';
import { Snackbar } from '@material-ui/core';


const LoanOrOtherExpenditureTable = () => {

    const hotTableComponent = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [loansAndExpenditureData, setLoansAndExpenditureData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    //useDispatch hook
    const dispatch = useDispatch();
    const { satYearData, loansAndExpData } = useSelector((state) => state.initialParameter);

    let yearList = [];
    satYearData?.data?.map((item) => {
        yearList.push(item?.year)
    })

    const data = [
        ['', yearList[0], 0, 'There are no other expenditure', 'Not Funded', ''],
    ]

    //useEffect

    useEffect(() => {
        setLoansAndExpenditureData(loansAndExpData?.data?.map(({ id, amount_due, description, fund_component, year_id
        }) => [id, yearList[0], amount_due, description, fund_component, year_id]))
    }, [loansAndExpData])

    const addRow = () => {
        if (loansAndExpenditureData?.length == 0 || loansAndExpenditureData == undefined) {
            const newRow = ['', yearList[0], 1, 'New Expenditure', 'Funded', ''];
            setLoansAndExpenditureData([newRow]);
        }
        else {
            hotTableComponent.current.hotInstance.alter('insert_row_above', 0);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 0, '');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 1, yearList[0]);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 2, 1);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 3, 'New Expenditure');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 4, 'Funded');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 5, '');
        }

    }

    const handleClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        setNewData(loansAndExpData);
    },[loansAndExpData])

    const handleAfterRemoveRow = (index) => {
       if(newData){
        const data = newData?.data[index];
        deleteLoansExpenditure(data?.id).then(() => {
           setSnackbarOpen(true);
        })
       }

       const updatedData = hotTableComponent.current.hotInstance.getData();
       setLoansAndExpenditureData(updatedData);
    }

    const afterChange = (changes, source) => {
        if (source === 'edit') {
            const newData = Handsontable?.helper?.arrayMap(loansAndExpenditureData, (row) => [...row]);
            // changes.forEach(([row, col, newValue]) => {
            changes.forEach(([row, col, oldValue, newValue]) => {
                // console.log("oldvalue", oldValue);
                newData[row][col] = oldValue;
                newData[row][col] = newValue;
            });
            setLoansAndExpenditureData(newData);
        }
    };


    useEffect(() => {
        dispatch(dispatchLoansAndExpEditedData(loansAndExpenditureData));
    }, [loansAndExpenditureData])

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false)

    };

    return (
        <>
            <div className='main_div_data_entry_tables buttonright'>

                <Accordion className="scenario_container" expanded={expanded}
                    sx={{
                        my: '1rem',
                        
                    }}>
                    <AccordionSummary style={{color:"var(--primary-color)"}} expandIcon={expanded ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={handleClick} className='loans_Expenditure_header '>
                        Loans or Other Expenditures
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ marginBottom: '10px' }}>
                            <button type="button" className="button_class" onClick={addRow}><AddIcon style={{fontSize :'20px',margin: '2px 2px'}} />Add Row</button>
                        </div>

                        {loansAndExpenditureData == undefined ?
                            <div>
                                <HotTable
                                    ref={hotTableComponent}
                                    data={data}
                                    columns={[
                                        {
                                            title: 'Id',
                                            type: 'text',

                                        },
                                        {
                                            title: 'Year',
                                            type: 'dropdown',
                                            source: yearList,
                                            dropdownMenu: true
                                        },
                                        {
                                            title: 'Amount Due',
                                            type: 'text',
                                            readOnly: true
                                        },
                                        {
                                            title: 'Discription',
                                            type: 'text',
                                            readOnly: true
                                        },
                                        {
                                            title: 'Fund Component',
                                            type: 'text',
                                            readOnly: true
                                        },
                                        {
                                            title: 'Year Id',
                                            type: 'text',

                                        },

                                    ]}
                                    columnSorting={true}
                                    stretchH="all"
                                    height='200px'
                                    hiddenColumns={{
                                        columns: [0, 5],
                                        indicators: true,
                                    }}
                                    // afterChange={afterChange}
                                    // colWidths={[70,100,100,60,100,90]}
                                    licenseKey="non-commercial-and-evaluation"
                                />

                            </div> :
                            <div>
                                <div>
                                    <HotTable
                                        ref={hotTableComponent}
                                        data={loansAndExpenditureData}
                                        columns={[
                                            {
                                                title: 'Id',
                                                type: 'text',

                                            },
                                            {
                                                title: 'Year',
                                                type: 'dropdown',
                                                source: yearList,
                                                dropdownMenu: true
                                            },
                                            {
                                                title: 'Amount Due',
                                                type: 'text',

                                            },
                                            {
                                                title: 'Discription',
                                                type: 'text',

                                            },
                                            {
                                                title: 'Fund Component',
                                                type: 'dropdown',
                                                source: ['Funded', 'Not Funded'],
                                                dropdownMenu: true

                                            },

                                        ]}
                                        columnSorting={true}
                                        rowHeaders={true}
                                        contextMenu={['remove_row','alignment']}
                                        stretchH="all"
                                        height="200px"
                                        afterChange={afterChange}
                                        afterRemoveRow={handleAfterRemoveRow}
                                        hiddenColumns={{
                                            columns: [0],
                                            indicators: true,
                                        }}
                                        // colWidths={[70,100,100,60,100,90]}
                                        licenseKey="non-commercial-and-evaluation"
                                    />
                                </div>
                            </div>
                        }
                    </AccordionDetails>
                </Accordion>
            </div>
            <Snackbar
               open={snackbarOpen}
               onClose={handleCloseSnackbar}
            >
                <Alert 
                onClose={handleCloseSnackbar }
                severity='success'
                sx={{width :'100%'}}
                >
                  Updated Successfully
                </Alert>
            </Snackbar>
        </>
    );
}
export default LoanOrOtherExpenditureTable;