import React, { useEffect } from "react";
import { useRef } from "react";

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DownloadIcon from '@mui/icons-material/Download';
import { Upload } from "@mui/icons-material";

import HotTable from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';

import 'handsontable/dist/handsontable.full.min.css';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Handsontable from "handsontable";
import { dispatchComponentEditedData } from "../../../../redux/actions/scenarioManagementAction";
import { deleteComponents, downloadComponentList, uploadComponentList } from "../../../../services/Services";
import "../scenarioManagementTable/ScenarioManagementTable.css"
import showNotification from "../../../common/Notification";

registerAllModules();

const ComponentsTable = () => {

    const hotTableComponent = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [componentEditedData, setComponentEditedData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [count, setCount] = useState(1)

    //redux hook
    const { componentListingData, activeScenarioId } = useSelector((state) => state.initialParameter);

    const dispatch = useDispatch();

    useEffect(() => {
        setComponentEditedData(componentListingData[0]?.map(({ id, category, component_title, description, useful_life_year, remaining_useful_life_years, assessement
            , current_replacement_cost, fund_component, notes }) => [id, category, component_title, description, useful_life_year, remaining_useful_life_years,
                current_replacement_cost, assessement, fund_component, notes]));
    }, [componentListingData]);

    const addRow = () => {
        setCount(count+1)
        if (componentEditedData?.length == 0 || componentEditedData == undefined) {
            const newRow = ['', 'Other', `New Component${count}`, 'New', 1, 0, 1, 'Fixed', 'Funded', 'None'];
            setComponentEditedData([newRow]);
        }
        else {
            hotTableComponent.current.hotInstance.alter('insert_row_above', 0);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 0, '');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 1, 'Other');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 2, `New Component${count}`);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 3, 'New');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 4, 1);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 5, 0);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 6, 1);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 7, 'Fixed');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 8, 'Funded');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 9, 'None');
        }
    }

    useEffect(() => {

        setNewData(componentListingData);
    }, [componentEditedData])

    const handleAfterRemoveRow = (index) => {
        // console.log("index",index);

        if (newData) {
            const data = newData[0][index];
            deleteComponents(data?.id).then((res) => {
                console.log(res);
            }).catch((err) => {
                console.log(err);
            })
        }

        const updatedData = hotTableComponent.current.hotInstance.getData();

        setComponentEditedData(updatedData);
    }

    const afterChange = (changes, source) => {
        if (source === 'edit' && changes != null) {
            const newData = Handsontable?.helper?.arrayMap(componentEditedData, (row) => [...row]);
            changes?.forEach(([row, col, oldValue, newValue]) => {
                // console.log("oldvalue", oldValue);

                newData[row][col] = oldValue;
                newData[row][col] = newValue;
            });
            setComponentEditedData(newData);
        }
    };

    const downloadComponentCSVList = () => {
        downloadComponentList(activeScenarioId).then((res) => {
            // setCsvData(res?.data);
            const csvBlob = new Blob([res.data], { type: 'text/csv' });
            const csvUrl = URL.createObjectURL(csvBlob);

            const link = document.createElement('a');
            link.href = csvUrl;
            link.download = 'Component-List.csv';
            link.click();
            URL.revokeObjectURL(csvUrl);
        }).catch((err) => {
            console.error(err);
        })

    }

    const uploadComponentCSVList = (event) => {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        // console.log(event.target.files[0])
        uploadComponentList(activeScenarioId, formData)
            .then(() => {
                // console.log(res);
                showNotification("success","Successfully Uploaded");
            })
        // .catch((err) => {
        //     // console.log(err);

        // });
    };

    const handleClick = () => {
        setExpanded(!expanded);
    };

    const handleRejuvenationClick = () => {
        const updatedData = componentEditedData?.map(row => {
            const incrementedValue = row[5] + 1;
            if (incrementedValue <= row[4]) {
                return [row[0], row[1], row[2], row[3], row[4], incrementedValue, row[6], row[7], row[8], row[9]];
            }
            else {
                return [row[0], row[1], row[2], row[3], row[4], row[5], row[6], row[7], row[8], row[9]];
            }
        });
        setComponentEditedData(updatedData);
    };

    const handleAgingClick = () => {
        const updatedData = componentEditedData?.map(row => {
            const decrementedValue = row[5] - 1;
            if (decrementedValue >= 0) {
                return [row[0], row[1], row[2], row[3], row[4], decrementedValue, row[6], row[7], row[8], row[9]];
            }
            else {
                return [row[0], row[1], row[2], row[3], row[4], row[5], row[6], row[7], row[8], row[9]];
            }
        });
        setComponentEditedData(updatedData);
    };


    useEffect(() => {
        dispatch(dispatchComponentEditedData(componentEditedData));
    }, [componentEditedData])

    return (
        <>
            <div className='main_div_data_entry_tables buttonright'>
                <Accordion className="scenario_container" expanded={expanded}
                    sx={{
                        my: '1rem',
                        // backgroundColor: '#F8F8F8 !important '
                    }}>
                    <AccordionSummary style={{ color: "var(--primary-color)" }} expandIcon={expanded ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={handleClick} className='loans_Expenditure_header '>
                        Components
                    </AccordionSummary>

                    <AccordionDetails style={{ marginBottom: '100px' }}>
                        <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <div>
                                    <button className="button_class" onClick={addRow}><AddIcon style={{ fontSize: "20px", margin: '2px 2px' }} /> Add Row</button>
                                </div>
                                <div>
                                    <button className="button_class" onClick={downloadComponentCSVList}><DownloadIcon style={{ fontSize: "20px", margin: '2px 2px' }} /> Download Component List</button>
                                </div>
                                <div>
                                    <label className="file-upload-label button_class">
                                        <input
                                            type="file"
                                            className="file-upload-input"
                                            onChange={uploadComponentCSVList}
                                        />
                                        <Upload style={{ fontSize: "20px", margin: '2px 2px' }} />
                                        Upload Component List
                                    </label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <div>
                                    <button className="button_class" onClick={handleAgingClick}><i className="fa fa-calendar-minus-o" style={{ margin: '2px 2px' }}></i> Ageing(-1 year)</button>
                                </div>
                                <div>
                                    <button className="button_class" onClick={handleRejuvenationClick}><i className="fa fa-calendar-plus-o" style={{ margin: '2px 2px' }}></i> Rejuvenation(+1 year)</button>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div>
                                <HotTable
                                    ref={hotTableComponent}
                                    data={componentEditedData}
                                    columns={[
                                        {
                                            title: 'Id',
                                            type: 'text',

                                        },
                                        {
                                            type: 'autocomplete',
                                            source: ['Common Exteriors', 'Common Interiors', 'Facilities', 'Other'],
                                            strict: false,
                                            title: 'Category',
                                        },
                                        {
                                            title: 'Component Title',
                                            type: 'text',
                                        },
                                        {
                                            title: 'Description',
                                            type: 'text',
                                        },
                                        {
                                            title: 'Useful Life (years)',
                                            type: 'numeric'
                                        },
                                        {
                                            title: 'Remaining Useful Life (years)',
                                            type: 'numeric'
                                        },
                                        {
                                            title: 'Current Replacement Cost',
                                            type: 'numeric',
                                            numericFormat: {
                                                pattern: '$ 0,0.00',
                                                culture: 'en-US',
                                            },
                                        },
                                        {
                                            title: 'Assessment',
                                            type: 'dropdown',
                                            source: ['Fixed', 'Variable'],
                                            dropdownMenu: true
                                        },
                                        {
                                            title: 'Fund Component',
                                            type: 'dropdown',
                                            source: ['Funded', 'Not Funded'],
                                            dropdownMenu: true
                                        },
                                        {
                                            title: 'Notes',
                                            type: 'text'
                                        },
                                    ]}
                                    // enable sorting for all columns
                                    columnSorting={true}
                                    contextMenu={['remove_row', 'alignment']}
                                    rowHeaders={true}
                                    stretchH="all"
                                    height={600}
                                    afterChange={afterChange}
                                    afterRemoveRow={handleAfterRemoveRow}
                                    hiddenColumns={{
                                        columns: [0],
                                        indicators: true,
                                    }}
                                    // colWidths={[70,100,100,60,100,90]}
                                    licenseKey="non-commercial-and-evaluation"
                                />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>
        </>
    );
};
export default ComponentsTable;