import React, { useEffect, useMemo, useRef } from 'react'
import { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import HotTable from '@handsontable/react';
import 'react-datasheet-grid/dist/style.css';
import '../../../../assets/styles/style.css';
import './UnitReserveContribution.css';
import '../scenarioManagementTable/ScenarioManagementTable.css'
import { Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUnitWiseContribution, getUnitWiseReserveTableListing, getUnitsVariableTableListing, updateUnitWiseContribution } from '../../../../services/Services';
import Handsontable from 'handsontable';
import { dispatchUnitReserve, dispatchUnitReserveRows, dispatchUnitReserveTableCount, dispatchUnitsVariableData } from '../../../../redux/actions/scenarioManagementAction';

const UnitReserveContribution = (props) => {
    const hotTableComponent = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const { unitreserve, unitreserverows, activeScenarioId } = useSelector((state) => state?.initialParameter);
    const [unitTableData, setUnitTableData] = useState([]);
    const [enable, setEnable] = useState(unitreserverows?.Omit_button_enable)
    const [newData, setNewData] = useState([])
    const [count, setCount] = useState(1)
    const [isTableEdit, setTableEdit] = useState(false)
    const [loadingdata, setLoadingdata] = useState(false)

    const dispatch = useDispatch()

    const handleClick = () => {
        setExpanded(!expanded);
    };

    const handleEnableChange = (e) => {
        if (e.target.checked) {
            setEnable(true)
        }
        else {
            setEnable(false)
        }
    }

    const addRow = () => {
        setCount(count + 1)
        if (unitTableData?.length == 0 || unitTableData == undefined) {
            const newRow = ['Unit', '0', 'occupied', '', '', ''];
            setUnitTableData([newRow]);
        }
        else {
            hotTableComponent.current.hotInstance.alter('insert_row_above', 0);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 0, `Unit${count}`);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 1, '0');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 2, 'occupied');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 3, '');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 4, '');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 5, '');
        }
    }

    useEffect(() => {
        setNewData(unitreserve);
    }, [unitTableData])

    const handleAfterRemoveRow = (index) => {
        setCount(count - 1)
        const data = newData?.results[index];
        if (newData) {
            deleteUnitWiseContribution(data?.id)
                .then((res) => {   //eslint-disable-line
                    getUnitWiseReserveTableListing(activeScenarioId)
                        .then((res) => {
                            dispatch(dispatchUnitReserve(res?.data));
                        })

                    getUnitsVariableTableListing(activeScenarioId)
                        .then((res) => {
                            dispatch(dispatchUnitsVariableData(res?.data));
                        })
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        const updatedData = hotTableComponent.current.hotInstance.getData();
        setUnitTableData(updatedData);
    };

    const afterChange = (changes, source) => {
        if (source === 'edit' && unitTableData) {
            const newData = Handsontable?.helper?.arrayMap(unitTableData, (row) => [...row]);
            if (newData) {
                changes?.forEach(([row, col, oldValue, newValue]) => {   // eslint-disable-line
                    if (newData[row]) {
                        newData[row][col] = newValue;
                        props.setDatachange(true)
                    }
                    if (newValue === 'occupied') {
                        setTableEdit(false)
                    } else if (newValue) {
                        setTableEdit(true)
                    }
                });
                setUnitTableData(newData);
            }
        }
    };

    useEffect(() => {
        setUnitTableData(unitreserve?.results?.map(({ unit, current_yearly_reserve_contribution, status, id, scenario_id, omit_vacant_units_reserve }) => {
            return [unit, current_yearly_reserve_contribution, status, id, scenario_id, omit_vacant_units_reserve]
        }));

    }, [unitreserve?.results]);

    useEffect(() => {
        dispatch(dispatchUnitReserveTableCount(unitTableData));
    }, [unitTableData])


    const Count = hotTableComponent?.current?.hotInstance?.countRows();
    useEffect(() => {
        dispatch(dispatchUnitReserveRows({ "Omit_button_enable": enable, "number_of_rows": Count }))
    }, [Count, enable])

    const updateUnitParam = useMemo(() => {
        if (!unitTableData?.length) return { data: [] };
        const dataArray = unitTableData.map((item) => {
            return {
                "id": item[3] ? item[3] : '',
                "current_yearly_reserve_contribution": item[1] ? item[1] : '0',
                "status": item[2] ? item[2] : "occupied",
                "unit": item[0] ? item[0] : 'Unit',
                "omit_vacant_units_reserve": unitreserverows?.Omit_button_enable ? unitreserverows?.Omit_button_enable : false,
                "scenario_id": item[4] ? item[4] : activeScenarioId,
            };
        });

        return { data: dataArray };
    }, [unitTableData]);


    const updateUnitParamApi = async (scenarioId) => {
        updateUnitWiseContribution(updateUnitParam, scenarioId).then((res) => {  //eslint-disable-line

            getUnitWiseReserveTableListing(scenarioId).then((res) => {
                dispatch(dispatchUnitReserve(res?.data));
            })
            getUnitsVariableTableListing(scenarioId).then((res) => {
                dispatch(dispatchUnitsVariableData(res?.data))
            })
            setLoadingdata(false)
        })
        .catch(()=>{
            setLoadingdata(false)
        })
        
        setTableEdit(false)
        
    }

    useEffect(() => {
        if (unitTableData?.length > 0 && activeScenarioId && isTableEdit) {
            updateUnitParamApi(activeScenarioId)
            setLoadingdata(true)
        }
    }, [isTableEdit])

    return (
        <>
            <div className='main_div_data_entry_tables buttonright'>
                <Accordion className="scenario_container" expanded={expanded}
                    sx={{
                        my: '1rem',
                    }}>
                    <AccordionSummary style={{ color: "var(--primary-color)" }} expandIcon={expanded ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={handleClick} className='units_header'>
                        Units Wise Reserve Contribution
                    </AccordionSummary>
                    <AccordionDetails>
                        {loadingdata ? <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status" style={{position:'absolute',top: '230px', zIndex: 1,right:'50%'}}>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div> : ""}
                        <div style={{ marginBottom: '10px' }}>
                            <button type="button" className="button_class" onClick={addRow} disabled={loadingdata} style={{ backgroundColor: loadingdata && 'grey', cursor: loadingdata && 'not-allowed' }}>
                                <AddIcon style={{ fontSize: '20px', margin: '2px 2px' }} />Add Unit</button>
                        </div>
                        <div>
                            <p style={{ fontWeight: '500', fontSize: '16px' }}>Omit Vacant Units Reserve:  <Switch checked={unitreserverows?.Omit_button_enable} onChange={handleEnableChange} /> </p>
                        </div>
                        <div>
                            <HotTable
                                ref={hotTableComponent}
                                data={unitTableData}
                                columns={[
                                    {
                                        title: 'Unit',
                                        type: 'text',
                                    },
                                    {
                                        title: 'Reserve Contribution',
                                        type: 'text',
                                    },
                                    {
                                        title: 'Status',
                                        type: 'dropdown',
                                        source: ['vacant', 'occupied'],
                                        dropdownMenu: true,
                                    },
                                ]}
                                columnSorting={true}
                                stretchH="all"
                                height="150"
                                contextMenu={['remove_row']}
                                afterChange={afterChange}
                                afterRemoveRow={handleAfterRemoveRow}
                                colWidths={[70, 100, 100]}
                                licenseKey="non-commercial-and-evaluation"
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}
export default UnitReserveContribution;