import React, { useEffect, useState } from "react";
import FundingPlanVSFFBalance from "./FundingPlanVSFFBalance";
import MainDashboard from "./mainDashboard/MainDashboard";
import ThirtyYrExpenditure from "./ThirtyYrExpenditure";
import { getFundingPlansVSFFBalance, getPercentFunded, getThirtyYrExpenditure } from "../../services/Services";
import PercentFunded from "./PercentFunded";
import { useSelector } from "react-redux";
import showNotification from "../common/Notification";

const DashboardTabs = (props) => {
    const { activeScenarioId } = useSelector((state) => state.initialParameter);
    const { cfpCheckData, cfpPercentData, cfpUniformPercentData, cfpCustomValueData,
        tfpMinimalEndingData, tfpPercentChangeData, tfpDefaultValueData, tfpMinBalanceYearData, tfpSpecialAssYearData, tfpFundingPriority,
        ffpMinimalEndingData, ffpPercentChangeData, ffpDefaultValueData, ffpMinBalanceYearData, ffpSpecialAssYearData, ffpFundingPriority, ffpMinimalPercentData } = useSelector((state) => state.fundingPlans);
    const [thirtyYrExpenditureData, setThirtyYrExpenditureData] = useState();
    const [dashboardData, setDashboardData] = useState();
    const [percentFundedData, setPercentFundedData] = useState();
    const [fpVSffBalacnceData, setFPVSFFBalacnceData] = useState();

    var errors = [];

    let params = {
        "current_Funding_plan": {
            "reserve_contribution": {
                "first_year": cfpCheckData,
                "percentage_change": cfpPercentData,
                "default_value": cfpUniformPercentData,
                "custom_value": cfpCustomValueData
            },
            "special_assessments": []
        },
        "threshold_funding_plan": {
            "min_end_balance_expend_ratio": parseFloat(tfpMinimalEndingData),
            "reserve_contribution":
            {
                "percentage_change": tfpPercentChangeData,
                "default_min_value": tfpDefaultValueData[0][0] != 0 ? tfpDefaultValueData[0][0] : -3,
                "default_max_value": tfpDefaultValueData[0][1] != 0 ? tfpDefaultValueData[0][1] : 3,
                // "custom_value": tfpCustomObjData
                "custom_value": {min:[18.08, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3,3,3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
                    max:[18.08, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3,3,3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3]}
            }
            ,
            "special_assessments": {
                "min_ending_balance_year": tfpMinBalanceYearData,
                "specialAssessments_added_for_years": tfpSpecialAssYearData,
                "funding_priority": tfpFundingPriority
            }
        },
        "full_funding_plan": {
            "min_end_balance_expend_ratio": parseFloat(ffpMinimalEndingData),
            "reserve_contribution":
            {
                "percentage_change": ffpPercentChangeData,
                "default_min_value": ffpDefaultValueData[0][0] != 0 ? ffpDefaultValueData[0][0] : -3,
                "default_max_value": ffpDefaultValueData[0][1] != 0 ? ffpDefaultValueData[0][1] : 3,
                // "custom_value": ffpCustomObjData
                "custom_value":{min:[37.17, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3,3,3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
                    max:[37.17, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3,3,3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],}

            }
            ,
            "special_assessments": {
                "min_ending_balance_year": ffpMinBalanceYearData,
                "specialAssessments_added_for_years": ffpSpecialAssYearData,
                "funding_priority": ffpFundingPriority
            },
            "minimal_percent_funded_last_year": ffpMinimalPercentData
        }
    }

    useEffect(() => {
        getThirtyYrExpenditure(activeScenarioId).then((res) => {
            props.setLoading(true);
            setThirtyYrExpenditureData(res.data);
            props.setLoading(false);
        }).catch(() => {
            props.setLoading(true);
        })

        getPercentFunded(activeScenarioId, params).then((res) => {
            props.setLoading(true);
            setDashboardData(res.data.dashboard_data);
            setPercentFundedData(res.data.response_of_percent_funded_api.data);
            props.setLoading(false);

        }).catch((err) => {
            // alert(err?.response?.data?.error)
            errors.push(err?.response?.data?.error)
            props.setLoading(true);
            if(errors.length == 4 ){
                if(err.response.status == 404){
                    showNotification("danger","Please select a scenario to move forward");
                }
                else{showNotification("danger",err?.response?.data?.error);}
                
                // console.log("Error b----------------",errors.length)         
            }
        })

        getFundingPlansVSFFBalance(activeScenarioId, params).then((res) => {
            props.setLoading(true);
            setFPVSFFBalacnceData(res.data.data);
            props.setLoading(false);
        }).catch((err) => {
            // alert(err?.response?.data?.error)
            // console.log("getPercentFunded", err?.response?.data?.error)
            errors.push(err?.response?.data?.detail)
            props.setLoading(true);
            // console.error(err);

            // if(errors.length == 4 ){
            //     alert(err?.response?.data?.error);
            //     console.log("Error b----------------",errors.length)
            //     // errors.length=0;
            //     console.log("Error a----------------",errors.length)

            // }
        })

    }, [activeScenarioId])

    return (
        <>
            {/* <div > */}
                {/* {props.loading && <CircularProgress className='circular_loader' />} */}
            {/* </div> */}
            {!props.loading ?(
                <div>
                    {dashboardData != undefined ? <MainDashboard dashboardData={dashboardData} /> : ''}
                    {thirtyYrExpenditureData != undefined ? <ThirtyYrExpenditure thirtyYrExpenditureData={thirtyYrExpenditureData} /> : ''}
                    {percentFundedData != undefined ? <PercentFunded percentFundedData={percentFundedData} /> : ''}
                    {fpVSffBalacnceData != undefined ? <FundingPlanVSFFBalance fpVSffBalacnceData={fpVSffBalacnceData} /> : ''}
                </div>) : (
                    <div style={{textAlign:"center", height:"100vh", top:'50%', position:'absolute',left:'35%'}} >
                        <h2 >Information is not available !!</h2>
                        <h4 >Components entries required for this view</h4>
                    </div>
                )
            }
        </>
    );
}

export default DashboardTabs;