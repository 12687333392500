import React from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { AddSelectedComponents, getComponentTableListing } from "../../services/Services";
import { useDispatch, useSelector } from "react-redux";
import { dispatchComponentData } from "../../redux/actions/scenarioManagementAction";

function AddSelected({ handleCloseSelected, rowsdata, rowsId, setIsSelected }) {
    const { activeScenarioId } = useSelector((state) => state.initialParameter);
    const dispatch = useDispatch()

    const handleAdd = () => {
        let params = { "reference_component_ids": rowsId }
        AddSelectedComponents(params,activeScenarioId).then(() => {
            setIsSelected(false)
            getComponentTableListing(activeScenarioId).then((res) => {
                dispatch(dispatchComponentData(res?.data?.data))
            })
        }).catch(() => {
            alert.log("Data is not Added")
        })
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="div">
                        <div style={{ display: 'flex' }}>
                            <div>
                                <h4>Add selected records to Component List</h4>
                            </div>
                        </div>
                        <p style={{ width: '100%', height: '1px', backgroundColor: 'gray', marginTop: "20px" }}></p>
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        {rowsdata.length == 0 ?
                            (<div>
                                Please Select Rows
                            </div>) :
                            (<div>
                                <p>You are about to add the following records to the <strong>Component List:</strong></p>
                                <ol>
                                    {rowsdata?.map((item, index) => (
                                        <li key={index} style={{ fontSize: '10px', fontStyle: 'italic' }}><strong>{item}</strong></li>
                                    ))}
                                </ol>
                            </div>
                            )}
                        <p style={{ width: '100%', height: '1px', backgroundColor: 'gray', marginTop: "20px", marginBottom: "25px" }}></p>
                    </Typography>

                    <div style={{ position: 'absolute', right: '0', bottom: '0', margin: '2%' }}>
                        {rowsdata.length !== 0 &&
                            <button className="button_class" style={{ marginRight: '8px' }} onClick={handleAdd}>Add</button>}
                        <button className="button_class" onClick={handleCloseSelected}>Cancel</button>
                    </div>
                </Box>
            </Modal >
        </>
    )
}
export default AddSelected;