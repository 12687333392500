import React, { useEffect } from 'react'
import { useState } from "react";

import {
    DataSheetGrid,
    textColumn,
    keyColumn,
} from 'react-datasheet-grid'

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import 'react-datasheet-grid/dist/style.css';
import '../../../../assets/styles/style.css';
import "../scenarioManagementTable/ScenarioManagementTable.css"

import { useDispatch, useSelector } from 'react-redux';
import { getSpecialAssessmentTableListing, getSpecialAssessmentDefaultTableListing } from '../../../../services/Services';
import { dispatchSATOneData, dispatchSATThreeData, dispatchSATTwoData, dispatchSATYearData } from '../../../../redux/actions/scenarioManagementAction';
const SpecialAssessment = () => {
    const dispatch = useDispatch();
    const {satOneData ,satTwoData ,satThreeData}= useSelector((state) => state.initialParameter);
    const [expanded, setExpanded] = useState(false);
    const [table1Data, setTable1Data] = useState(satOneData);
    const [table2Data, setTable2Data] = useState(satTwoData);
    const [table3Data, setTable3Data] = useState(satThreeData);

    const { activeScenarioId } = useSelector((state) => state.initialParameter);


    useEffect(() => {
        if (activeScenarioId == '') {
            getSpecialAssessmentDefaultTableListing().then((res) => {
                setTable1Data((res.data).slice(0, 10));
                setTable2Data((res.data).slice(10, 20));
                setTable3Data((res.data).slice(20, 30));
            }).catch((err) => {
                console.error(err);
            })
        }
        else {
            getSpecialAssessmentTableListing(activeScenarioId).then((res) => {
                setTable1Data((res.data.data).slice(0, 10));
                setTable2Data((res.data.data).slice(10, 20));
                setTable3Data((res.data.data).slice(20));
                dispatch(dispatchSATOneData((res.data.data).slice(0, 10)));
                dispatch(dispatchSATTwoData((res.data.data).slice(10, 20)));
                dispatch(dispatchSATThreeData((res.data.data).slice(20)));
                dispatch(dispatchSATYearData(res.data));
            }).catch((err) => {
                console.error(err);
            })
        }
    }, [activeScenarioId])

    const columns = [
        {
            ...keyColumn('year', textColumn),
            title: 'Year',
            disabled: true
        },
        {
            ...keyColumn('amount', textColumn),
            title: 'Amount',
        },
        {
            ...keyColumn('purpose', textColumn),
            title: 'Purpose',
        },
    ]

    const handleTableData1 = (value) => {
        setTable1Data(value);
        dispatch(dispatchSATOneData(value));
    }

    const handleTableData2 = (value) => {
        setTable2Data(value);
        dispatch(dispatchSATTwoData(value));
    }

    const handleTableData3 = (value) => {
        setTable3Data(value);
        dispatch(dispatchSATThreeData(value));
    }

    const handleClick = () => {
        setExpanded(!expanded);
    };
    return (
        <>
            <div className='main_div_data_entry_tables buttonright'>
                <Accordion className="scenario_container" expanded={expanded}
                    sx={{
                        my: '1rem',
                    }}>
                    <AccordionSummary expandIcon={expanded ? <RemoveIcon style={{ color: 'var(--primary-color)' }}/> : <AddIcon style={{ color: 'var(--primary-color)' }}/>} className='units_header' style={{color:"var(--primary-color"}} onClick={handleClick}>
                        Special Assessments
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">
                            <div className="dataSheet_without_number col-sm-4">
                                <DataSheetGrid
                                    value={satOneData ? satOneData : table1Data}
                                    onChange={handleTableData1}
                                    columns={columns}
                                    rowHeight={25}
                                />
                            </div>
                            <div className="dataSheet_without_number col-sm-4">
                                <DataSheetGrid
                                    value={satTwoData ? satTwoData : table2Data}
                                    onChange={handleTableData2}
                                    columns={columns}
                                    rowHeight={25}
                                />
                            </div>
                            <div className="dataSheet_without_number col-sm-4">
                                <DataSheetGrid
                                    value={satThreeData ? satThreeData : table3Data}
                                    onChange={handleTableData3}
                                    columns={columns}
                                    rowHeight={25}
                                />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}
export default SpecialAssessment;