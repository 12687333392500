import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./forgotPassword.css"
import { forgotPassword } from "../../services/Services";

function ForgotPassword() {
    const navigate = useNavigate()
    const [email,setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const handleEmailChange = (e) => {
        const Email = e.target.value;
        setEmail(Email);
        validateEmail(Email);
    }
    const handleSubmitClick = (e) => {
        e.preventDefault()
        let payload = {
            "email" : email
        }
        navigate("/otp");
        forgotPassword(payload).then((res) => {
            alert(res.data.message)
            navigate("/otp");
        }).catch((err) => {
            alert(err.response.data.error)
        })
    }

    const validateEmail = (_email) => {
        if (
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(_email) &&
            _email !== ""
        ){
            setEmailError("");
        }else{
            setEmailError("Enter the Valid Email");
        }
    }

    return (
        <>
            <div className="main_login">
                <div className="inner_div">
                    <div style={{ padding: '20px' }}>
                        <div className="login_header">
                            <h3>Community Planning Header</h3>
                        </div>
                        <div>
                            <form action="" className="login_form" >
                                <div className="input_div">
                                    <i className="fa fa-envelope"></i>
                                    <input type='email' name="email" id="email" required onChange={handleEmailChange} placeholder="Enter your registered mail address"/>
                                </div>
                                <div className="error">{emailError}</div>
                                <div className="login_btn">
                                    <button className="form-control btn" onClick={handleSubmitClick}>Submit</button>
                                </div>
                                <div style={{display:'flex', justifyContent: 'center', margin:'10px'}}>
                                    <Link to={"/"}>Back to login</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;