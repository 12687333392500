import React, { useEffect, useMemo, useRef } from 'react'
import { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import HotTable from '@handsontable/react';
import 'react-datasheet-grid/dist/style.css';
import '../../../../assets/styles/style.css';
import './UnitsVariableTable.css';
import '../scenarioManagementTable/ScenarioManagementTable.css'
import { useDispatch, useSelector } from 'react-redux';
import Handsontable from 'handsontable';
import { dispatchUnitReserve, dispatchUnitsVariableData, dispatchUnitsVariableEditedData } from '../../../../redux/actions/scenarioManagementAction';
import { createAndUpdateUnitsVariableTable, deleteUnitsIfVariable, getUnitWiseReserveTableListing, getUnitsVariableTableListing } from '../../../../services/Services';

const UnitVariableTable = (props) => {
    const hotTableComponent = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [unitsEditedData, setUnitsEditedData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [count, setCount] = useState(1)
    const [isTableEdit, setTableEdit] = useState(false)
    const [loadingdata, setLoadingdata] = useState(false)


    //redux hook
    const dispatch = useDispatch();
    const { comEditedData, unitsVariableData, activeScenarioId } = useSelector((state) => state.initialParameter);
    const data = [
        ['', '', '', '30 units are submitted for a fixed assessment', '', ''],
    ]

    let variableValue = '';
    comEditedData?.map((item) => {
        item?.filter((item) => {
            if (item == "Variable") {
                variableValue = item;
            }
        })
    })


    useEffect(() => {
        setUnitsEditedData(unitsVariableData?.data?.map(({ id, unit, building, address, square_footage, percentage
        }) => [id, unit, building, address, square_footage, percentage,]))
    }, [unitsVariableData])


    let diasbleInput = variableValue == 'Variable' ? false : true;

    const addRow = () => {
        setCount(count + 1)
        setLoadingdata(true)
        if (unitsEditedData?.length == 0 || unitsEditedData == undefined) {
            const newRow = ['', 'New Unit 1', 1, 'New Address', 100, 100];
            setUnitsEditedData([newRow]);
        }
        else {
            hotTableComponent.current.hotInstance.alter('insert_row_above', 0);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 0, '');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 1, `Unit${count}`);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 2, 1);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 3, 'New Address');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 4, 100);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 5, 100);
        }
    }

    const handleClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        setNewData(unitsVariableData);
    }, [unitsEditedData])

    const handleAfterRemoveRow = (index) => {
        setCount(count - 1)
        if (newData) {
            const data = newData.data[index];
            deleteUnitsIfVariable(data?.id).then((res) => {   //eslint-disable-line
                getUnitWiseReserveTableListing(activeScenarioId)
                    .then((res) => {
                        dispatch(dispatchUnitReserve(res?.data));
                    })
                    .catch((err) => {  //eslint-disable-line
                    });

                getUnitsVariableTableListing(activeScenarioId)
                    .then((res) => {
                        dispatch(dispatchUnitsVariableData(res?.data));
                    })
                    .catch((err) => {  //eslint-disable-line
                    });
            }).catch((err) => {
                console.log(err)
            })
        }

        const updatedData = hotTableComponent.current.hotInstance.getData();
        setUnitsEditedData(updatedData);
    }

    const afterChange = (changes, source) => {
        if (source === 'edit' && unitsEditedData?.length != 0 && unitsEditedData?.length != undefined) {
            props.setDatachange(false)
            let sumofSF = 0;
            unitsEditedData?.forEach((item) => {
                sumofSF = sumofSF + item[4];
            });
            const newData = Handsontable?.helper?.arrayMap(unitsEditedData, (row) => [...row]);

            const col4RowCountMap = new Map();

            changes.forEach(([row, col, oldValue, newValue]) => {  //eslint-disable-line
                if (col === 4) {
                    if (!col4RowCountMap.has(row)) {
                        col4RowCountMap.set(row, 1);
                    } else {
                        col4RowCountMap.set(row, col4RowCountMap.get(row) + 1);
                    }
                }
            });

            changes.forEach(([row, col, oldValue, newValue]) => {    //eslint-disable-line
                if (col === 4) {
                    const rowCount = col4RowCountMap.get(row);
                    if (rowCount) {
                        const percentageToDistribute = ((newValue / sumofSF) * 100 / rowCount).toFixed(2);

                        for (let i = 0; i < unitsEditedData.length; i++) {
                            if (unitsEditedData[i][4] === newValue) {
                                newData[i][col + 1] = percentageToDistribute;
                            }
                        }
                    }
                }
                newData[row][col] = newValue;
                if (newValue === 100) {
                    setTableEdit(false)
                } else if (newValue) {
                    setTableEdit(true)
                }

            });

            setUnitsEditedData(newData);
        }
    };

    useEffect(() => {
        dispatch(dispatchUnitsVariableEditedData(unitsEditedData));
    }, [unitsEditedData])

    const updateUnitVariableParam = useMemo(() => {
        if (!unitsEditedData?.length) return { data: [] };
        const dataArray = unitsEditedData.map((item) => {
            return {
                'id': item[0],
                "unit": item[1],
                "building": item[2],
                "address": item[3],
                "square_footage": item[4] ? item[4] : 100,
                "percentage": item[5],
                "scenario_id": activeScenarioId
            };
        });

        return { data: dataArray };
    }, [unitsEditedData]);

    const updateUnitVariableParamApi = async (scenarioId) => {
        createAndUpdateUnitsVariableTable(updateUnitVariableParam, activeScenarioId).then((res) => {
            console.log(res)
            getUnitsVariableTableListing(scenarioId).then((res) => {
                dispatch(dispatchUnitsVariableData(res?.data))
            })
            getUnitWiseReserveTableListing(scenarioId).then((res) => {
                dispatch(dispatchUnitReserve(res?.data));
            })
            setLoadingdata(false)
        })
            .catch(() => {
                setLoadingdata(false)
            })
        setTableEdit(false)
    }

    useEffect(() => {
        if (unitsEditedData?.length > 0 && activeScenarioId && isTableEdit) {
            updateUnitVariableParamApi(activeScenarioId)
            // setLoadingdata(true)
        }
    }, [isTableEdit])


    return (
        <>
            <div className='main_div_data_entry_tables buttonright'>
                <Accordion className="scenario_container" expanded={expanded}
                    sx={{
                        my: '1rem',
                    }}>
                    <AccordionSummary style={{ color: "var(--primary-color)" }} expandIcon={expanded ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={handleClick} className='units_header'>
                        Units if Variable
                    </AccordionSummary>
                    {loadingdata ? <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status" style={{ position: 'absolute', top: '230px', zIndex: 1, right: '50%' }}>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> : ""}
                    <AccordionDetails>
                        {comEditedData == undefined || diasbleInput ? (
                            <div style={{ marginBottom: '10px' }}>
                                <button type="button" className="btn btn-light btn_1" disabled ><AddIcon style={{ fontSize: '20px', margin: '2px 2px' }} />Add Unit</button>
                            </div>
                        ) : (
                            <div style={{ marginBottom: '10px' }}>
                                <button type="button" className="button_class" onClick={addRow} disabled={loadingdata} style={{ backgroundColor: loadingdata && 'grey', cursor: loadingdata && 'not-allowed' }}><AddIcon style={{ fontSize: '20px', margin: '2px 2px' }} />Add Unit</button>
                            </div>
                        )}

                        {unitsEditedData?.length == 0 || diasbleInput ? (
                            <div>
                                <HotTable
                                    ref={hotTableComponent}
                                    data={data}
                                    columns={[
                                        {
                                            title: 'Id',
                                            type: 'text',
                                            readOnly: true

                                        },
                                        {
                                            title: 'Unit',
                                            type: 'text',
                                            readOnly: true

                                        },
                                        {
                                            title: 'Building',
                                            type: 'numeric',
                                            readOnly: true

                                        },
                                        {
                                            title: 'Address',
                                            type: 'text',
                                            readOnly: true

                                        },
                                        {
                                            title: 'Square Footage',
                                            type: 'numeric',
                                            readOnly: true

                                        },
                                        {
                                            title: 'Percentage',
                                            type: 'numeric',
                                            readOnly: true

                                        }
                                    ]}
                                    columnSorting={true}

                                    stretchH="all"
                                    height="auto"
                                    hiddenColumns={{
                                        columns: [0],
                                        indicators: true,
                                    }}
                                    // colWidths={[70,100,100,60,100,90]}
                                    licenseKey="non-commercial-and-evaluation"
                                />
                            </div>
                        ) : (
                            <div>
                                <HotTable
                                    ref={hotTableComponent}
                                    data={unitsEditedData}
                                    columns={[
                                        {
                                            title: 'Id',
                                            type: 'text',

                                        },
                                        {
                                            title: 'Unit',
                                            type: 'text',

                                        },
                                        {
                                            title: 'Building',
                                            type: 'numeric',

                                        },
                                        {
                                            title: 'Address',
                                            type: 'text',

                                        },
                                        {
                                            title: 'Square Footage',
                                            type: 'numeric',
                                            // numericFormat: {
                                            //     pattern: '0,0.00'
                                            // }

                                        },
                                        {
                                            title: 'Percentage',
                                            renderer: (instance, td, row, col, prop, value) => {
                                                td.innerHTML = value ? value + '%' : '';
                                                td.style.textAlign = 'right';
                                            }
                                        }
                                    ]}
                                    columnSorting={true}
                                    contextMenu={['remove_row', 'alignment']}
                                    // contextMenu={true}
                                    // contextMenuCopyPaste={contextMenuItems}
                                    stretchH="all"
                                    height="auto"
                                    hiddenColumns={{
                                        columns: [0],
                                        indicators: true,
                                    }}
                                    afterChange={afterChange}
                                    // colWidths={[70,100,100,60,100,90]}
                                    licenseKey="non-commercial-and-evaluation"
                                    afterRemoveRow={handleAfterRemoveRow}
                                />
                            </div>
                        )}


                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}
export default UnitVariableTable;