import React, { useEffect, useMemo, useRef, useState } from "react";

import { Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableContainer, TableRow, TableCell, IconButton, Autocomplete, TextField, Tooltip } from '@mui/material';
import ModifyDialog from "./ModifyDialog";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EditCommunity from "./EditCommDialog";
import CreateDialog from "./CreateCommDialog";
import SearchBar from "material-ui-search-bar";
import { useDispatch, useSelector } from "react-redux";

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { HotTable } from "@handsontable/react";
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import Handsontable from "handsontable";

import { DownloadComponents, UpdateCSV, UpdateRefrenceComponent, componentListapi, deleteMultipleRowsComponents, getAccountCommunityInfo, getCommunityInfoID, getCommunityInfoList } from "../../services/Services";
import { getUserList } from "../../services/Services";
import { getScenariosList } from "../../services/Services";
import { grantAccess } from "../../services/Services";
import { getAccessUserList } from "../../services/Services";
import { revokeScenarioAccess } from "../../services/Services";

import './AccountInfo.css';
import { Check } from "@material-ui/icons";
import CloseIcon from '@mui/icons-material/Close'
import { Snackbar } from "@material-ui/core";
import Alert from '@mui/material/Alert';
import EditColorDialog from "./EditColorPalleteDialog";
import { dispatchCommunityData } from "../../redux/actions/accountInfoAction";
import { useCallback } from "react";
import { Upload } from "@mui/icons-material";
import ImportComponent from "./ImportComponents";
import UploadCustom from "./UploadCustomList";
import AddSelected from "./AddSelected";
import showNotification from "../common/Notification";

const AccountInfo = (props) => {
    const hotTableRef = useRef('');
    const hotTableComponent = useRef('');
    const { activeScenarioId, community_id } = useSelector((state) => state.initialParameter);
    const dispatch = useDispatch()
    const [expandedAccInfo, setExpandedAccInfo] = useState(true);
    const [expandedCommInfo, setExpandedCommInfo] = useState(true);
    const [expandedCommRefDatabase, setExpandedCommRefDatabase] = useState(true);
    const [expandedAccessControl, setExpandedAccessControl] = useState(true);
    const [isAdding, setIsAdding] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openEditColorDialog, setOpenEditColorDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [accountCummunityApiInfo, setAccountCommunityApiInfo] = useState();
    const [getCommunityType, setCommunityType] = useState('');
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [componentTabledata, setComponentTabledata] = useState([]);
    const [newData, setNewData] = useState([])
    const [createBy, setCreateBy] = useState(false)
    const [createOn, setCreateOn] = useState(false)
    const [modifiedBy, setModifiedBy] = useState(false)
    const [modifiedOn, setModifiedOn] = useState(false)
    const [results, setResults] = useState([])
    const [pageChange, setPageChange] = useState([])
    const [search, setSearch] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [isUplaod, setIsUpload] = useState(false)
    const [rowsId, setRowsId] = useState([])
    const [rowsdata, setRowsdata] = useState([])
    const [isSelected, setIsSelected] = useState(false)
    const [readChecked, setReadChecked] = useState(false)
    const [writeChecked, setWriteChecked] = useState(false)
    const CommunityToken = '3f11a7eb-bf78-4ddc-8668-7252a5d4a5eb';

    useEffect(() => {
        componentListapi(currentPage).then((res) => {
            setResults(res?.data?.data)
            setPageChange(res?.data?.meta)
        })
    }, [currentPage])

    const handleChangePage = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleExpandAccInfo = () => {
        setExpandedAccInfo(!expandedAccInfo);
    }

    const handleExpandCommInfo = () => {
        setExpandedCommInfo(!expandedCommInfo);
    }

    const handleExpandCommRefDataBase = () => {
        setExpandedCommRefDatabase(!expandedCommRefDatabase)
    }
    const handleExpandAccessControl = () => {
        setExpandedAccessControl(!expandedAccessControl)
    }
    const handleMoreClick = () => {
        setIsAdding(true);
    }
    const handleDialog = () => {
        setOpenDialog(!openDialog);
    }
    const handleCreateDialog = () => {
        setOpenCreateDialog(!openCreateDialog);
    }

    const handleEdit = (type) => {
        setCommunityType(type);
        setOpenEditDialog(!openEditDialog);
    }

    const handleColorEdit = () => {
        setOpenEditColorDialog(!openEditColorDialog);
    }
    const handleSnackbar = () => {
        setOpenSnackbar(!openSnackBar);
    }

    useEffect(() => {
        setNewData(results);
    }, [results])

    const handleAfterRemoveRow = () => {
        if (newData) {
            let params = {
                "component_id": rowsId
            }
            deleteMultipleRowsComponents(params).then(() => {
                componentListapi(currentPage).then((res) => {
                    setResults(res?.data?.data)
                    setPageChange(res?.data?.meta)
                })
            }).catch((err) => {
                console.log(err?.response?.detail);
            })
        }
        if (hotTableComponent.current && hotTableComponent.current.hotInstance) {
            const updatedData = hotTableComponent.current.hotInstance.getData();
            setComponentTabledata(updatedData);
        }
    }
    const [count, setCount] = useState(1)
    const addRow = () => {
        setCount(count + 1)
        const newRowData = getNewRowData();
        setComponentTabledata([...componentTabledata, newRowData]);
    }

    const getNewRowData = () => {
        return ['', 'Fixed', 'Not Funded', 'None', 0, `New Category${count}`, `New Component${count}`, 'New', 1, 1, '', '', '', ''];
    };

    const afterChange = (changes, source) => {
        if (source === 'edit' && changes != null) {
            const newData = Handsontable?.helper?.arrayMap(componentTabledata, (row) => [...row]);
            changes.forEach(([row, col, oldValue, newValue]) => {
                newData[row][col] = oldValue;
                newData[row][col] = newValue;
            });
            setComponentTabledata(newData);
        }
    };

    const afterSelection = (startRow, startCol, endRow, endCol, selectionLayerLevel) => {  //eslint-disable-line
        const selectedRowIds = [];
        const selectedRowsData = []
        const step = startRow <= endRow ? 1 : -1;

        for (let i = startRow; (step === 1 && i <= endRow) || (step === -1 && i >= endRow); i += step) {
            const selectedRowData = componentTabledata[i];
            if (selectedRowData && selectedRowData.length > 5) {
                const selectedId = selectedRowData[0];
                const selectedData = selectedRowData[6]
                selectedRowIds.push(selectedId);
                selectedRowsData.push(selectedData)
                setRowsId(selectedRowIds)
                setRowsdata(selectedRowsData)
            }
        }
    };

    const handleDeleteRows = () => {
        let params = {
            "component_id": rowsId
        }
        deleteMultipleRowsComponents(params).then(() => {
            componentListapi(currentPage).then((res) => {
                setResults(res?.data?.data)
                setPageChange(res?.data?.meta)
                setRowsId([])
                showNotification("success", "Data Delete Successfully")
            }).catch(() => {
                showNotification("danger", "Data is not deleted")
            })
        })
    }

    useEffect(() => {
        setComponentTabledata(results?.map(({ id, assessement, fund_component, notes, remaining_useful_life_years, category, component_title, description, useful_life_year, current_replacement_cost, created_by_email, created_on, last_modified_on, last_modified_by_email
        }) => {
            const arr = [id, assessement, fund_component, notes, remaining_useful_life_years, category, component_title, description, useful_life_year, current_replacement_cost]
            if (createBy) {
                arr.push(created_by_email)
            }
            if (createOn) {
                arr.push(created_on)
            }
            if (modifiedBy) {
                arr.push(last_modified_by_email)
            }
            if (modifiedOn) {
                arr.push(last_modified_on)
            }
            return arr
        }));
    }, [results, createBy, createOn, modifiedBy, modifiedOn]);

    const [users, setUsers] = useState([]);
    let errors = [];

    const fetchUserList = () => {
        getUserList(community_id)
            .then((res) => {
                // setUsers(res?.data?.results);
                setUsers(res?.data);
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    errors.push(err);
                } else {
                    showNotification("danger", err.response.message)
                }
            });
    };

    const activeUserEmail = localStorage.getItem('userEmail');

    const activeUser = users?.find((user) => {
        if (user.email === activeUserEmail) {
            return user;
        }
    })

    const userId = activeUser?.email;

    useEffect(() => {
        fetchCommunityInfoID();
    }, [])

    const [communityID, setCommunityID] = useState(null);

    const fetchCommunityInfoID = () => {
        let params = { "community_uuid": CommunityToken }
        getCommunityInfoID(params, userId).then((res) => {
            setCommunityID(res.data.community_id);
        })
    }

    const [primaryColor, setPrimaryColor] = useState("#0f100f");
    const [secondaryColor, setSecondaryColor] = useState("#ffffff");
    const [tertiaryColor, setTertiaryColor] = useState("#e6e6fa");

    const fetchAccountCommunityInfo = () => {
        getAccountCommunityInfo(community_id).then((res) => {
            setPrimaryColor(res.data.community_info.primary_color);
            setSecondaryColor(res.data.community_info.secondary_color);
            setTertiaryColor(res.data.community_info.tertiary_color);
            props.setLoading(true);
            setAccountCommunityApiInfo(res?.data);
            dispatch(dispatchCommunityData(res?.data));
            props.setLoading(false);
        }).catch((err) => {
            props.setLoading(true);
            if (err.response.status == 401) {
                errors.push(err);
                if (errors.length == 6) {
                    showNotification("danger", "Session expires, to continue please login again")
                }
            } else {
                showNotification("danger", err);
            }
        })
    }

    useEffect(() => {
        fetchUserList();
        fetchCommunityInfoList();
    }, []);

    const [scenarios, setScenarios] = useState([]);

    const fetchCommunityInfoList = () => {
        getCommunityInfoList()
            .catch((err) => {
                if (err.response.status == 401) {
                    errors.push(err);
                } else {
                    showNotification("danger", err);
                }
            })
    }

    useEffect(() => {
        fetchScenariosList();
    }, [communityID]);

    const fetchScenariosList = () => {
        if (communityID) {
            getScenariosList(communityID)
                .then((res) => {
                    setScenarios(res.data);
                })
                .catch((err) => {
                    showNotification("danger", err);
                });
        }
    };

    const scenarioOptions = scenarios.map((scenario) => ({
        value: `${scenario.id}`,
        label: `${scenario.scenario_name}`
    }))

    const getScenarioNameById = (id_) => {
        const scenarioName = scenarioOptions.find((scenario) => {
            if (parseInt(scenario.value) == id_) {
                return scenario?.label;
            }
        })
        return scenarioName.label;
    }

    const userOptions = users?.map((user) => {
        if (`${user?.first_name} ${user?.last_name}` != `${accountCummunityApiInfo?.user_Detail?.first_name} ${accountCummunityApiInfo?.user_Detail?.last_name}`) {
            console.log("email---------", user.email)
            console.log("name---------", `${user?.first_name} ${user?.last_name}`)
            return (
                {
                    value: `${user?.email}`,
                    label: `${user?.first_name} ${user?.last_name}`,
                }
            )
        }
    });

    const getUserNameById = (_email) => {
        const userName = userOptions?.find((user) => {
            if (user?.value == _email) {
                return user?.label;
            }
        })
        return userName?.label
    }
    const [selectedData, setSelectedData] = useState({
        user: "",
        access: "",
        scenario: ""
    });

    useEffect(() => {
        fetchAccountCommunityInfo();
        fetchAccessUserList();
    }, [])

    useEffect(() => {
        selectedData;
    }, [selectedData])

    const handleChange = (event, fieldName) => {
        const editedData = { ...selectedData, [fieldName]: event.target.value };
        setSelectedData(editedData);
    };

    const handleAccessClick = () => {
        const users = selectedData.user;
        const array_email = [];
        for (let i = 0; i < users.length; i++) {
            array_email.push(users[i]);
        }
        if (array_email.length != 0) {
            const payload = {
                "access_control": [
                    { "emails": array_email, "read": readChecked, "write": writeChecked }
                ]
            }
            grantAccess(selectedData.scenario, payload).then(() => {
                setIsAdding(false)
                handleMoreClick();
                fetchAccessUserList();
                setOpenSnackbar(true);
                setTimeout(() => {
                    setOpenSnackbar(false)
                }, 1000);
            }).catch((err) => {
                showNotification("danger", err.response.data.message)
            })
        }
    };

    const handleDeniedClick = () => {
        setSelectedData({})
        setWriteChecked(false)
        setReadChecked(false)
        setIsAdding(false)
    }

    const handleCheckboxChange = (event) => {
        const itemName = event.target.name;
        const isChecked = event.target.checked;
        if (itemName === 'Read') {
            setReadChecked(isChecked)
        }
        else if (itemName === 'Write') {
            setWriteChecked(isChecked)
            setReadChecked(isChecked)
        }
    };

    const [accessList, setAccessList] = useState([]);

    const fetchAccessUserList = () => {
        getAccessUserList(community_id)
            .then((res) => {
                setAccessList(res.data.write_access_user_scenarios);
            })
    };

    useEffect(() => {
        accessList;
    }, [accessList])

    const handleDeleteClick = (id) => {
        revokeScenarioAccess(id).then(() => {
            fetchAccessUserList();
        })
            .catch(() => {
                showNotification("danger", "Record not Delete")
            })

    }

    const allowDisplay = scenarioOptions.find((scenario) => {
        if (parseInt(scenario.value) == activeScenarioId) {
            return scenario;
        }
    })

    const handleCreateBy = useCallback(() => {
        setCreateBy(!createBy)
    }, [createBy])

    const handleCreateOn = useCallback(() => {
        setCreateOn(!createOn)
    }, [createOn])

    const handleModifiedBy = useCallback(() => {
        setModifiedBy(!modifiedBy)
    }, [modifiedBy])

    const handleModifiedOn = useCallback(() => {
        setModifiedOn(!modifiedOn)
    }, [modifiedOn])

    const col = useMemo(() => {
        let arr = [
            {
                title: 'Id',
                type: 'text',
            },
            {
                title: 'Assessement',
                type: 'text',
            },
            {
                title: 'Fund component',
                type: 'text',
            },
            {
                title: 'Notes',
                type: 'text',
            },
            {
                title: 'remaining_useful_life_years',
                type: 'text',
            },
            {
                title: 'Category',
                type: 'text',
            },
            {
                title: 'Component Title',
                type: 'text',
            },
            {
                title: 'Description',
                type: 'numeric'
            },
            {
                title: 'Useful Life (years)',
                type: 'numeric'
            },
            {
                title: 'Replacement Cost',
                type: 'numeric',
                numericFormat: {
                    pattern: '$ 0,0.00',
                    culture: 'en-US',
                },
            },

        ]
        if (createBy) {
            arr.push({
                title: 'Created By',
                type: 'numeric'
            },)
        } else {
            const itemToRemove = {
                title: 'Created By',
                type: 'numeric'
            }
            arr.filter(element => !(element.title === itemToRemove.title && element.type === itemToRemove.type));
        }

        if (createOn) {
            arr.push({
                title: 'Create On',
                type: 'numeric'
            },)
        } else {
            const itemToRemove = {
                title: 'Create On',
                type: 'numeric'
            }
            arr.filter(element => !(element.title === itemToRemove.title && element.type === itemToRemove.type));
        }

        if (modifiedBy) {
            arr.push({
                title: 'Modified By',
                type: 'numeric'
            },)
        } else {
            const itemToRemove = {
                title: 'Modified By',
                type: 'numeric'
            }
            arr.filter(element => !(element.title === itemToRemove.title && element.type === itemToRemove.type));
        }
        if (modifiedOn) {
            arr.push({
                title: 'Modified On',
                type: 'numeric'
            },)
        } else {
            const itemToRemove = {
                title: 'Modified On',
                type: 'numeric'
            }
            arr.filter(element => !(element.title === itemToRemove.title && element.type === itemToRemove.type));
        }
        return arr
    }, [createBy, createOn, modifiedBy, modifiedOn])

    const selectAllRows = () => {
        const hotInstance = hotTableRef.current.hotInstance;
        hotInstance.selectCell(0, 0, hotInstance.countRows() - 1, col.length - 1);
    };

    const deselectAllRows = () => {
        const hotInstance = hotTableRef.current.hotInstance;
        hotInstance.deselectCell();
    };

    const handleSearch = () => {
        setSearchValue(search)
        console.log(searchValue)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const [isImport, setIsImport] = useState(false)
    const handleImport = () => {
        setIsImport(true)
    }

    const handleUpload = () => {
        setIsUpload(true)
    }

    const handleAddSelected = () => {
        setIsSelected(true)
    }

    const handleCloseImport = () => {
        setIsImport(false)
    }

    const handleCloseUpload = () => {
        setIsUpload(false)
    }

    const handleCloseSelected = () => {
        setIsSelected(false)
        setRowsId([])
        setRowsdata([])
    }

    const handleSave = () => {

        let dataObj = componentTabledata?.map((item) => {
            return (
                {

                    "id": item[0] ? item[0] : '',
                    "category": item[5],
                    "component_title": item[6],
                    "description": item[7],
                    "useful_life_year": item[8],
                    "remaining_useful_life_years": item[4],
                    "current_replacement_cost": item[9],
                    "assessement": item[1],
                    "fund_component": item[2],
                    "notes": item[3]
                }
            )
        })
        let params = {
            data: dataObj
        }
        UpdateRefrenceComponent(params).then(() => {
            componentListapi(currentPage).then((res) => {
                setResults(res?.data?.data)
                setPageChange(res?.data?.meta)
                showNotification("success", "Data Update Successfully");
            }).catch(() => {
                showNotification("danger", "Data is not updated")
            })

        })
    }

    const handleDownload = () => {
        DownloadComponents().then((res) => {
            const csvBlob = new Blob([res?.data], { type: 'text/csv' });
            const csvUrl = URL.createObjectURL(csvBlob);

            const link = document.createElement('a');
            link.href = csvUrl;
            link.download = 'Component-List.csv';
            link.click();
            URL.revokeObjectURL(csvUrl);
        })
    }

    const handleUploadCSV = (event) => {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        UpdateCSV(formData).then(() => {
            componentListapi(currentPage).then((res) => {
                setResults(res?.data?.data)
                setPageChange(res?.data?.meta)
                showNotification("success", "Data Update Successfully")
            }).catch(() => {
                showNotification("danger", "Data is not updated")

            })

        })
    }

    return (
        <>
            <div className="row outerContainer">
                <div className="col-sm-5 buttonright" style={{ borderRadius: '2%' }}>
                    <Accordion style={{ marginTop: '55px' }} expanded={expandedAccInfo} sx={{ overflow: 'hidden', border: '1px solid var(--primary-color)' }}>
                        <AccordionSummary expandIcon={expandedAccInfo ? <RemoveIcon style={{ color: "var(--primary-color)" }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={handleExpandAccInfo}
                            sx={{
                                background: 'white !important',
                                minHeight: 'auto !important',
                                '& .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content': {
                                    margin: '10px !important'
                                }
                            }}
                        >
                            <div className="AI_header" style={{ padding: '15px 1px' }}>
                                <h3 className="AccountInfo_header_text">Account Info</h3>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <div>
                                    <table className="account_info_table table table-striped">
                                        <tbody>
                                            <tr>
                                                <td>Login / email</td>
                                                <td>{accountCummunityApiInfo?.user_Detail?.email}</td>
                                            </tr>
                                            <tr>
                                                <td>Name</td>
                                                <td>{accountCummunityApiInfo?.user_Detail?.first_name}  {accountCummunityApiInfo?.user_Detail?.last_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Company / position</td>
                                                <td>{accountCummunityApiInfo?.user_Detail?.company}</td>
                                            </tr>
                                            <tr>
                                                <td>Contact / Phone</td>
                                                <td>{accountCummunityApiInfo?.user_Detail?.phone_no}</td>
                                            </tr>
                                            <tr>
                                                <td>Addtional Info</td>
                                                <td>{accountCummunityApiInfo?.user_Detail?.additional_info}</td>
                                            </tr>
                                            <tr>
                                                <td>Registration Date</td>
                                                <td>{accountCummunityApiInfo?.user_Detail?.registration_date}</td>
                                            </tr>
                                            <tr>
                                                <td>Account Status / Role</td>
                                                <td>{accountCummunityApiInfo?.user_Detail?.account_status ? 'ACTIVE / Privileged User' : ''}</td>
                                            </tr>
                                            <tr style={{ borderBottom: '1px solid #111' }}>
                                                <td>Connected Communities</td>
                                                <td>{accountCummunityApiInfo?.user_Detail?.connected_community}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ float: 'right', padding: '10px 0px' }}>
                                    <button className="button_class" onClick={handleDialog}><ManageAccountsIcon /> Modify</button>
                                </div>
                                <ModifyDialog openDialog={openDialog} setOpenDialog={setOpenDialog} handleDialog={handleDialog} fetchAccountCommunityInfo={fetchAccountCommunityInfo} userId={userId} accountCummunityApiInfo={accountCummunityApiInfo} />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="col-sm-7 buttonright" style={{ borderRadius: '2%' }}>
                    <Accordion style={{ marginTop: '55px' }} expanded={expandedCommInfo} sx={{ overflow: 'hidden', border: '1px solid var(--primary-color)' }}>
                        <AccordionSummary expandIcon={expandedCommInfo ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={handleExpandCommInfo}
                            sx={{
                                background: 'white !important',
                                minHeight: 'auto !important',
                                '& .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content': {
                                    margin: '10px !important'
                                }
                            }} >
                            <h3 style={{ padding: '15px 1px' }} className="AccountInfo_header_text">Community Info</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <div>
                                    <table className="account_info_table table table-striped">
                                        <tbody>
                                            <tr>
                                                <td>Community Name</td>
                                                <td>{accountCummunityApiInfo?.community_info?.community_name}</td>
                                                <td>
                                                    <button className="button_class" onClick={() => handleEdit('community_name')}><ModeEditIcon style={{ fontSize: '14px', margin: '2px 4px' }} />Edit</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Community Address</td>
                                                <td>{accountCummunityApiInfo?.community_info?.community_address}</td>
                                                <td>
                                                    <button className="button_class" onClick={() => handleEdit('community_address')}><ModeEditIcon style={{ fontSize: '14px', margin: '2px 4px' }} />Edit</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Subscription type / period</td>
                                                <td>{accountCummunityApiInfo?.community_info?.subscription_type}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Max number of scenarios</td>
                                                <td>{accountCummunityApiInfo?.community_info?.max_number_of_scenarios}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Subscription Status</td>
                                                <td>{accountCummunityApiInfo?.community_info?.subscription_status == true ? 'ACTIVE' : 'INACTIVE'}</td>
                                                <td></td>
                                            </tr>
                                            <tr style={{ borderBottom: '1px solid #111' }}>
                                                <td>Color Palette</td>
                                                <td><input type="color" value={primaryColor} disabled /><input type="color" value={secondaryColor} disabled /><input type="color" value={tertiaryColor} disabled /></td>
                                                <td>
                                                    <button className="button_class" onClick={handleColorEdit}><ModeEditIcon style={{ fontSize: '14px', margin: '2px 4px' }} />
                                                        Edit
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <EditCommunity openEditDialog={openEditDialog} setOpenEditDialog={setOpenEditDialog} handleEdit={handleEdit} getCommunityType={getCommunityType} fetchAccountCommunityInfo={fetchAccountCommunityInfo} userId={userId} accountCummunityApiInfo={accountCummunityApiInfo} fetchCommunityInfoList={fetchCommunityInfoList} communityID={communityID} />
                                    <EditColorDialog openEditColorDialog={openEditColorDialog} setOpenEditColorDialog={setOpenEditColorDialog} primaryColor={primaryColor} setPrimaryColor={setPrimaryColor} secondaryColor={secondaryColor} setSecondaryColor={setSecondaryColor} tertiaryColor={tertiaryColor} setTertiaryColor={setTertiaryColor} handleColorEdit={handleColorEdit} fetchAccountCommunityInfo={fetchAccountCommunityInfo} community_id={community_id} />
                                </div>
                                {/* <div style={{ display: 'flex', justifyContent: 'end' }}>
                                    <button className="button_class" style={{ display: 'flex', justifyContent: 'end' }} onClick={handleCreateDialog}>Create</button>
                                </div> */}
                                <CreateDialog openCreateDialog={openCreateDialog} setOpenCreateDialog={setOpenCreateDialog} handleCreateDialog={handleCreateDialog} fetchAccountCommunityInfo={fetchAccountCommunityInfo} userId={userId} accountCummunityApiInfo={accountCummunityApiInfo} fetchCommunityInfoList={fetchCommunityInfoList} communityID={communityID} />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div></div>
                <Snackbar
                    open={openSnackBar}
                    onClose={handleSnackbar}
                >
                    <Alert onClose={handleSnackbar} severity="success" sx={{ width: '100%' }}>
                        Access Granted Succesfully.
                    </Alert>
                </Snackbar>
            </div>
            <div className="comm_ref_database buttonright">
                <Accordion expanded={expandedCommRefDatabase} >
                    <AccordionSummary expandIcon={expandedCommRefDatabase ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={handleExpandCommRefDataBase}
                        sx={{
                            background: 'white !important',
                            minHeight: 'auto !important',
                            '& .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content': {
                                margin: '10px !important'
                            }
                        }}
                    >
                        <h3 style={{ padding: '15px 1px' }}>Components Reference Database</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                <div className="Comp_ref_database_btn">
                                    <div className="dropdown">
                                        <button type="button" className="button_class dropdown-toggle" data-bs-toggle="dropdown">
                                            Column visibility
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li style={{ padding: '2px' }}><button className="btn btn_1" style={{ width: '100%', backgroundColor: createBy && 'lightgreen' }} onClick={handleCreateBy} >Created by</button></li>
                                            <li style={{ padding: '2px' }}><button className="btn btn_1" style={{ width: '100%', backgroundColor: createOn && 'lightgreen' }} onClick={handleCreateOn}>Created on</button></li>
                                            <li style={{ padding: '2px' }}><button className="btn btn_1" style={{ width: '100%', backgroundColor: modifiedBy && 'lightgreen' }} onClick={handleModifiedBy}>Last modified by</button></li>
                                            <li style={{ padding: '2px' }}><button className="btn btn_1" style={{ width: '100%', backgroundColor: modifiedOn && 'lightgreen' }} onClick={handleModifiedOn}>Last modified on</button></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <button className="button_class" style={{ width: '150px' }} onClick={selectAllRows}>Select all</button>
                                    </div>
                                    <div>
                                        <button className="button_class" style={{ width: '150px' }} onClick={deselectAllRows}>Deselect all</button>
                                    </div>
                                    <div>
                                        <button className="button_class" style={{ width: '150px', padding: '3.5 px' }} onClick={handleSave}><SaveIcon style={{ fontSize: '17px', margin: '2px 4px' }} />Save</button>
                                    </div>
                                </div>
                                <div >
                                    <SearchBar
                                        value={search}
                                        onChange={(newValue) => setSearch(newValue)}
                                        onKeyPress={handleKeyPress}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <HotTable
                                    ref={hotTableRef}
                                    data={componentTabledata}
                                    columns={col}
                                    columnSorting={true}
                                    rowHeaders={true}
                                    contextMenu={['remove_row', 'alignment']}
                                    // afterInit={handleAfterInit}
                                    stretchH="all"
                                    afterSelection={afterSelection}
                                    height="auto"
                                    afterChange={afterChange}
                                    hiddenColumns={{
                                        columns: [0, 1, 2, 3, 4],
                                        indicators: true,
                                    }}
                                    afterRemoveRow={handleAfterRemoveRow}
                                    licenseKey="non-commercial-and-evaluation"
                                />
                                <div style={{ float: 'right' }}>
                                    <button className="btn_1"
                                        style={{ marginRight: '10px' }}
                                        disabled={currentPage === 1}
                                        onClick={() => handleChangePage(currentPage - 1)}
                                    >
                                        Prev
                                    </button>
                                    <span style={{ fontSize: '15px' }}>Page {currentPage}</span>
                                    <button className="btn_1"
                                        style={{ marginLeft: '10px' }}
                                        disabled={currentPage == pageChange?.page_count}
                                        onClick={() => handleChangePage(currentPage + 1)}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                            <div style={{ marginTop: '45px' }}></div>
                            <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between', maxWidth: '331vh' }}>
                                <div style={{ display: 'flex', gap: '10px' }} className="com_ref_div">
                                    <div>
                                        <button className="button_class com_ref_button" onClick={addRow}><AddIcon className="com_ref_icon" /> Add Row</button>
                                    </div>
                                    <div>
                                        <button className="button_class com_ref_button" onClick={handleDeleteRows}><RemoveIcon className="com_ref_icon" /> Delete Row</button>
                                    </div>
                                    <div>
                                        <button className="button_class com_ref_button" onClick={handleDownload}><DownloadIcon className="com_ref_icon" /> Download List</button>
                                    </div>
                                    <div>
                                        <button className="button_class com_ref_button" onClick={handleUpload}><Upload className="com_ref_icon" />Upload Custom List</button>
                                    </div>
                                    <div>
                                        <button className="button_class com_ref_button" onClick={handleImport}><InsertDriveFileIcon className="com_ref_icon" />Import From Components</button>
                                    </div>
                                    <div>
                                        <button className="button_class com_ref_button" onClick={handleAddSelected}><InsertDriveFileIcon className="com_ref_icon"/>Add selected to Components</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isImport && (
                            <ImportComponent handleCloseImport={handleCloseImport} componentTabledata={componentTabledata} setIsImport={setIsImport} setResults={setResults} setPageChange={setPageChange} currentPages={currentPage} />
                        )}
                        {isUplaod && (
                            <UploadCustom handleCloseUpload={handleCloseUpload} handleUploadCSV={handleUploadCSV} />
                        )}
                        {isSelected && (
                            <AddSelected handleCloseSelected={handleCloseSelected} rowsdata={rowsdata} rowsId={rowsId} setIsSelected={setIsSelected} />
                        )}

                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="comm_ref_database buttonright">
                <Accordion expanded={expandedAccessControl} >
                    <AccordionSummary expandIcon={expandedAccessControl ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={handleExpandAccessControl}
                        sx={{
                            background: 'white !important',
                            minHeight: 'auto !important',
                            '& .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content': {
                                margin: '10px !important'
                            }
                        }}
                    >
                        <h3 style={{ padding: '15px 1px' }}>Access Control For Scenarios</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            {allowDisplay ? (
                                <>
                                    <button className="button_class" onClick={handleMoreClick}>Add more</button>
                                    <div style={{ marginTop: '10px' }}>
                                        <TableContainer >
                                            <Table>
                                                <TableBody>
                                                    <TableRow className="compact-heading">
                                                        <TableCell>S.NO</TableCell>
                                                        <TableCell>Users</TableCell>
                                                        <TableCell>Scenarios</TableCell>
                                                        <TableCell>Access</TableCell>
                                                        <TableCell>Action</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                                <TableBody>
                                                    {accessList.map((row, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{getUserNameById(row.user_id)}</TableCell>
                                                            {/* <TableCell>{row.scenario_id}</TableCell> */}
                                                            <TableCell>{getScenarioNameById(row.scenario_id)}</TableCell>
                                                            <TableCell>{row.write ? 'Read and Write' : 'Read'}</TableCell>
                                                            <TableCell>
                                                                <Tooltip title='Revoke Access'>
                                                                    <IconButton
                                                                        aria-label="Revoke Access"
                                                                        onClick={() => handleDeleteClick(row.id)}>
                                                                        <DeleteIcon style={{ color: 'red' }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                    }
                                                    {isAdding &&
                                                        <TableRow className="compact-row" >
                                                            <TableCell></TableCell>
                                                            <TableCell sx={{ width: '350px' }}>

                                                                <Autocomplete
                                                                    multiple
                                                                    sx={{ width: '250px' }}
                                                                    value={selectedData?.user
                                                                        ? userOptions?.filter((option) =>
                                                                            selectedData?.user?.includes(option?.value)
                                                                        )
                                                                        : []}
                                                                    options={userOptions}
                                                                    getOptionLabel={(option) => option?.label}
                                                                    onChange={(event, newValue) => {
                                                                        if (newValue) {
                                                                            const selectedValues = Array?.isArray(newValue)
                                                                                ? newValue.map((v) => v.value)
                                                                                : [newValue.value];
                                                                            handleChange(
                                                                                { target: { value: selectedValues } },
                                                                                "user"
                                                                            );
                                                                        }
                                                                        else {
                                                                            handleChange(
                                                                                { target: { value: [] } },
                                                                                "user"
                                                                            );
                                                                        }
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} variant="standard" label="Select Users" />
                                                                    )}
                                                                />
                                                            </TableCell>
                                                            <TableCell sx={{ width: '350px' }}>
                                                                <Autocomplete
                                                                    sx={{ width: '250px' }}
                                                                    value={selectedData.scenario
                                                                        ? scenarioOptions.find(
                                                                            (option) =>
                                                                                option.value === selectedData?.scenario
                                                                        )
                                                                        : null}
                                                                    options={scenarioOptions}
                                                                    getOptionLabel={(option) => option.label}
                                                                    onChange={(event, newValue) => {
                                                                        if (newValue) {
                                                                            const selectedValues = Array.isArray(newValue)
                                                                                ? newValue.map((v) => v.value)
                                                                                : newValue.value;
                                                                            handleChange({ target: { value: selectedValues } }, "scenario");
                                                                        }
                                                                        else {
                                                                            handleChange(
                                                                                { target: { value: '' } },
                                                                                "scenario"
                                                                            );
                                                                        }
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} variant="standard" label="Select Scenario" />
                                                                    )}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <div>
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            name="Read"
                                                                            checked={readChecked}
                                                                            onChange={(event) => {
                                                                                handleCheckboxChange(event);
                                                                            }}
                                                                            style={{ marginRight: '20px' }}
                                                                        />
                                                                        Read
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            name="Write"
                                                                            checked={writeChecked}
                                                                            onChange={(event) => {
                                                                                handleCheckboxChange(event);
                                                                            }}
                                                                            style={{ marginRight: '20px' }}
                                                                        />
                                                                        Write
                                                                    </label>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Tooltip title='Grant Access' style={{ display: "flex" }}>
                                                                    <IconButton
                                                                        aria-label="Grant Access"
                                                                        onClick={handleAccessClick}>
                                                                        <Check style={{ color: 'green' }} />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        aria-label="Discard Access"
                                                                        onClick={handleDeniedClick}>
                                                                        <CloseIcon style={{ color: 'red' }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div> </>
                            ) :
                                <div className="invalid">
                                    You don&apos;t have access for this Scenario
                                </div>
                            }
                        </div>

                    </AccordionDetails>
                </Accordion><br /><br />
            </div>
        </>
    );
}

export default AccountInfo;