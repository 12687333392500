import User from '../../configurations/Constant';

const initialState = {
    email: '',
    communitydata: {}
}

const accountInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case User.ACCOUNTDATA:
            return {
                ...state,
                email: action.email,
            };
        case User.COMMUNITYINFO:
            return {
                ...state,
                communitydata: action.communitydata,
            };

        default:
            return state;
    }
}

export default accountInfoReducer;