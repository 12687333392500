import User from "../../configurations/Constant";

export const dispatchLogInToken = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.LOGINTOKENDATA,
        logInTokenData: json,
      })
    } catch (e) {
      dispatch({
        type: User.LOGINTOKENDATA,
        logInTokenData: {},
      });
    }
  }

export const dispatchAuthentication = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.ISAUTH,
        isAuth: json,
      })
    } catch (e) {
      dispatch({
        type: User.ISAUTH,
        isAuth: {},
      });
    }
  }