import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Tooltip, Typography } from "@material-ui/core";
import { updateColorChoice } from "../../services/Services";
import showNotification from "../common/Notification";

function EditColorDialog(props) {

    const [selectedPrimaryColor, setSelectedPrimaryColor] = useState();
    const [selectedSecondaryColor, setSelectedSecondaryColor] = useState();
    const [selectedTertiaryColor, setSelectedTertiaryColor] = useState();

    useEffect(() => {
        setSelectedPrimaryColor(props?.primaryColor);
        setSelectedSecondaryColor(props?.secondaryColor);
        setSelectedTertiaryColor(props?.tertiaryColor);
    }, [])
    useEffect(() => {
        setSelectedPrimaryColor(props?.primaryColor);
        setSelectedSecondaryColor(props?.secondaryColor);
        setSelectedTertiaryColor(props?.tertiaryColor);
    }, [props])

    const handlePrimaryColorChange = (event) => {
        setSelectedPrimaryColor(event.target.value);
    }
    const handleSecondaryColorChange = (event) => {
        setSelectedSecondaryColor(event.target.value);
    }
    const handleTertiaryColorChange = (event) => {
        setSelectedTertiaryColor(event.target.value);
    }

    useEffect(() => {
        document.documentElement.style.setProperty('--primary-color', selectedPrimaryColor);
        document.documentElement.style.setProperty('--secondary-color', selectedSecondaryColor);
        document.documentElement.style.setProperty('--tertiary-color', selectedTertiaryColor);
    }, [selectedPrimaryColor, selectedSecondaryColor, selectedTertiaryColor])

    const handleSaveClick = () => {
        const payload = {
            "primary_color": selectedPrimaryColor,
            "secondary_color": selectedSecondaryColor,
            "tertiary_color": selectedTertiaryColor
        }
        updateColorChoice(props.community_id,payload).then((res) => {
            showNotification("success",res.data.message)
            props.fetchAccountCommunityInfo();
            props.setOpenEditColorDialog(false)
        })
    }
    return (
        <>
            <Dialog
                open={props.openEditColorDialog}
                onClose={props.handleColorEdit}
                maxWidth='lg'
                PaperProps={{
                    sx: {
                        width: "30%",
                        overflow: 'hidden !important',
                    }
                }}
            >
                <DialogTitle>
                    <Typography variant="h5" style={{ fontWeight: 'bold', color: 'var(--primary-color)', marginBottom: '20px' }}>Choose Color Combination</Typography>
                </DialogTitle>
                <DialogContent>
                    <Tooltip title='Primary Color' arrow>
                        <div aria-label="Primary Color">
                            <span >
                                <h6 style={{ marginBottom: '10px' }}>Primary Color</h6>
                                <input type="color" onChange={handlePrimaryColorChange} value={selectedPrimaryColor} />
                            </span>
                        </div>
                    </Tooltip>
                    
                    <br />
                    <Tooltip title='Text Color' arrow>
                        <div aria-label="Text Color">
                            <span>
                                <h6 style={{ marginBottom: '10px' }}>Text Color</h6>
                                <input type="color" onChange={handleSecondaryColorChange} value={selectedSecondaryColor} />
                            </span>
                        </div>
                    </Tooltip>
                    <br />
                    <div>
                        <Tooltip title='Secondary Color'  arrow>
                            <div aria-label="Secondary Color">
                                <span>
                                    <h6 style={{ marginBottom: '10px' }}>Secondary Color</h6>
                                    <input type="color" onChange={handleTertiaryColorChange} value={selectedTertiaryColor} />
                                </span>
                            </div>
                        </Tooltip>
                    </div>

                    <br />
                </DialogContent>
                <DialogActions>
                    <button className='button_class' onClick={props.handleColorEdit}>Cancel</button>
                    <button className='button_class' onClick={handleSaveClick}>Save</button>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default EditColorDialog;
