import User from '../../configurations/Constant';

const initialState = {
    cfpTableData: [],
    cfpCheckData: true,
    cfpPercentData: 'Uniform',
    cfpUniformPercentData: 3,
    cfpAdvanceParameterData: false,
    cfpSelectedRowData: [],
    cfpCustomValueData: [],
    tfpTableData: [],
    tfpAdvanceParameterData: false,
    tfpMinimalEndingData: 0,
    tfpPercentChangeData: 'Uniform',
    tfpSelectedRowData: [],
    // tfpDefaultValueData: [[-3, 3]],
    tfpDefaultValueData: [[0, 0]],
    tfpCustomObjData: {
        "min": [],
        "max": []
    },
    ffpTableData: [],
    ffpAdvanceParameterData: false,
    ffpMinimalEndingData: 0,
    ffpPercentChangeData: 'Uniform',
    ffpSelectedRowData: [],
    ffpMinimalPercentData: 100,
    tfpMinBalanceYearData: '',
    tfpSpecialAssYearData: '',
    tfpFundingPriority: '',
    ffpMinBalanceYearData: '',
    ffpSpecialAssYearData: '',
    ffpFundingPriority: '',
    ffpDefaultValueData: [[0, 0]],
    // ffpDefaultValueData: [[-3, 3]],
    ffpCustomObjData: {
        "min": [],
        "max": []
    },
    min: [],
    max: [],
    tmin: [],
    tmax: [],

    full_funding_plan: {},
    threshold_funding_plan: {}


}

const fundingPlansReducer = (state = initialState, action) => {
    switch (action.type) {
        case User.CFPTABLEDATA:
            return {
                ...state,
                cfpTableData: action.cfpTableData,
            };
        case User.CFPCHECKDATA:
            return {
                ...state,
                cfpCheckData: action.cfpCheckData,
            }
        case User.CFPPERCENTDATA:
            return {
                ...state,
                cfpPercentData: action.cfpPercentData,
            }
        case User.CFPUNIFORMPERCENTDATA:
            return {
                ...state,
                cfpUniformPercentData: action.cfpUniformPercentData,
            }
        case User.CFPADVANCEDPARAMETERDATA:
            return {
                ...state,
                cfpAdvanceParameterData: action.cfpAdvanceParameterData,
            }
        case User.CFPSELECTEDROWDATA:
            return {
                ...state,
                cfpSelectedRowData: action.cfpSelectedRowData,
            }
        case User.CFPCUSTOMDATAVALUE:
            return {
                ...state,
                cfpCustomValueData: action.cfpCustomValueData,
            }
        case User.TFPTABLEDATA:
            return {
                ...state,
                tfpTableData: action.tfpTableData,
            }
        case User.TFPADVANCEPARAMETERDATA:
            return {
                ...state,
                tfpAdvanceParameterData: action.tfpAdvanceParameterData,
            }
        case User.TFPMINIMALENDINGDATA:
            return {
                ...state,
                tfpMinimalEndingData: action.tfpMinimalEndingData,
            }
        case User.TFPPERCENTCHANGEDATA:
            return {
                ...state,
                tfpPercentChangeData: action.tfpPercentChangeData
            }
        case User.TFPSELECTEDROWDATA:
            return {
                ...state,
                tfpSelectedRowData: action.tfpSelectedRowData,
            }
        case User.TFPDEFAULTVALUEDATA:
            return {
                ...state,
                tfpDefaultValueData: action.tfpDefaultValueData,
            }
        case User.TFPCUSTOMOBJDATA:
            return {
                ...state,
                tfpCustomObjData: action.tfpCustomObjData,
            }
        case User.TFPCUSTOMOBJDATAMIN:
            return {
                ...state,
                tmin: action.tmin,
            }
        case User.TFPCUSTOMOBJDATAMAX:
            return {
                ...state,
                tmax: action.tmax,
            }



        case User.FFPTABLEDATA:
            return {
                ...state,
                ffpTableData: action.ffpTableData,
            }
        case User.FFPADVANCEPARAMETERDATA:
            return {
                ...state,
                ffpAdvanceParameterData: action.ffpAdvanceParameterData,
            }
        case User.FFPMINIMALENDINGDATA:
            return {
                ...state,
                ffpMinimalEndingData: action.ffpMinimalEndingData,
            }
        case User.FFPPERCENTCHANGEDATA:
            return {
                ...state,
                ffpPercentChangeData: action.ffpPercentChangeData
            }
        case User.FFPSELECTEDROWDATA:
            return {
                ...state,
                ffpSelectedRowData: action.ffpSelectedRowData,
            }
        case User.FFPMINIMALPERCENTDATA:
            return {
                ...state,
                ffpMinimalPercentData: action.ffpMinimalPercentData,
            }
        case User.TFPMINENDINGBALANCEYEARDATA:
            return {
                ...state,
                tfpMinBalanceYearData: action.tfpMinBalanceYearData,
            }
        case User.TFPSPECIALASSYEARDATA:
            return {
                ...state,
                tfpSpecialAssYearData: action.tfpSpecialAssYearData,
            }
        case User.TFPFUNDINGPRIORITYDATA:
            return {
                ...state,
                tfpFundingPriority: action.tfpFundingPriority,
            }
        case User.FFPMINENDINGBALANCEYEARDATA:
            return {
                ...state,
                ffpMinBalanceYearData: action.ffpMinBalanceYearData,
            }
        case User.FFPSPECIALASSYEARDATA:
            return {
                ...state,
                ffpSpecialAssYearData: action.ffpSpecialAssYearData,
            }
        case User.FFPFUNDINGPRIORITYDATA:
            return {
                ...state,
                ffpFundingPriority: action.ffpFundingPriority,
            }
        case User.FFPDEFAULTVALUEDATA:
            return {
                ...state,
                ffpDefaultValueData: action.ffpDefaultValueData,
            }
        case User.FFPCUSTOMOBJDATA:
            return {
                ...state,
                ffpCustomObjData: action.ffpCustomObjData,
            }
        case User.FFPCUSTOMOBJDATAMIN:
            return {
                ...state,
                min: action.min,
            }
        case User.FFPCUSTOMOBJDATAMAX:
            return {
                ...state,
                max: action.max,
            }
        case User.FULLFUNDING:
            return {
                ...state,
                full_funding_plan: action.full_funding_plan,
            }
        case User.THRESHOLDFUNDING:
            return {
                ...state,
                threshold_funding_plan: action.threshold_funding_plan,
            }
        default:
            return state;
    }
}

export default fundingPlansReducer;