import React from "react";
import { useNavigate } from "react-router-dom";

function Permission() {
    const navigate = useNavigate()
    const handleLoginClick = () => {
        navigate("/");
    }
    return (
        <div style={{display :'flex', justifyContent : 'center', marginTop :'160px'}}>
            <div>
                <h2 style={{fontSize :'30px', color:'black'}}>Access Denied</h2>
                <h7>To access this page please Login</h7>
            </div>
            <div>
                <button onClick={handleLoginClick} style={{ marginLeft:'-150px',display:'flex',justifyContent:'center',marginTop :'80px' }}>
                    Login
                </button>
            </div>
        </div>
    )
}
export default Permission;