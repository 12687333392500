import React, {useState} from "react";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import ReactApexChart from "react-apexcharts";

const FundingPlanVSFFBalance = (props) => {
    //usestate
    const [expanded, setExpanded] = useState(false);

    const options = {
        chart: {
            type: 'line',
        },
        xaxis: {
            categories: props.fpVSffBalacnceData[0].values?.map(item => item.category),
        },
        stroke: {
          width: [3, 3, 3],                           // Customize the line width for each series
          dashArray: [0, 5, 0],                       // Customize the line dash pattern for each series
        },
        colors: ['#d42020', '#0f913c', '#1338a8','#111'],
        markers: {
          size: 4,
          shape: 'circle',
          radius: 2,
          strokeWidth: 2,
        },
    };

    const series = props.fpVSffBalacnceData?.map(seriesData => ({
        name: seriesData.name,
        data: seriesData?.values?.map(item => ((item.value)/6).toFixed(0)),
        type: seriesData.type || 'line',
    }));


    const handleClick = () => {
        setExpanded(!expanded);
    }


    return (
        <>
            <div className="main_div_data_entry_tables buttonright">
                <Accordion expanded={expanded} style={{  borderTop: '3px solid var(--primary-color)'}} >
                    <AccordionSummary style={{color:'var(--primary-color)'}} expandIcon={expanded ? <RemoveIcon style={{ color: 'var(--primary-color)' }}/> : <AddIcon style={{ color: 'var(--primary-color)' }}/>} onClick={handleClick}>
                    Funding Plans VS Fully Funded Balance ($)
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="line"
                            height={350}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}

export default FundingPlanVSFFBalance;