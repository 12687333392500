import React, { useState } from 'react'
import InitialParameter from './dataEntryTable/initialParameterTable/InitialParameterTable';
import UnitVariableTable from './dataEntryTable/unitsVariableTable/UnitsVariableTable';
import LoanOrOtherExpenditureTable from './dataEntryTable/loansOrOtherExpenditureTable/LoansOrOtherExpenditureTable';
import SpecialAssessment from './dataEntryTable/specialAssessmentTable/SpecialAssessmentTable';
import ScenarioManagementTable from './dataEntryTable/scenarioManagementTable/ScenarioManagementTable';
import ComponentsTable from './dataEntryTable/componentsTable/ComponentsTable';
import MonthlyExpensesTable from './dataEntryTable/monthlyExpensesTable/MonthlyExpensesTable';


import '../data-entry/DataEntryTab.css';
import '../../assets/styles/style.css';
import UnitReserveContribution from './dataEntryTable/unitViseReserveContribution/UnitViseReserveContribution';


function DataEntryTab(props) {
    const [datachange,setDatachange] = useState(false)
    return (
        <>
            <>
                <ScenarioManagementTable setLoading={props.setLoading} datachange={datachange} setDatachange={setDatachange} />
                <InitialParameter />
                <UnitReserveContribution datachange={datachange} setDatachange={setDatachange}/>
                <UnitVariableTable  datachange={datachange} setDatachange={setDatachange}/>
                <SpecialAssessment />
                <LoanOrOtherExpenditureTable />
                <ComponentsTable />
                <MonthlyExpensesTable />
            </>
        </>
    );
}

export default DataEntryTab;