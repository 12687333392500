import React, { useEffect } from "react";
import { useRef } from "react";

import { Accordion, AccordionSummary, AccordionDetails, Switch } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


import HotTable from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';

import 'handsontable/dist/handsontable.full.min.css';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Handsontable from "handsontable";
import { dispatchMCEEditedData } from "../../../../redux/actions/scenarioManagementAction";
import { MCEStatus, deleteMonthlyExpense } from "../../../../services/Services";
import "../scenarioManagementTable/ScenarioManagementTable.css"

registerAllModules();

const MonthlyExpensesTable = () => {

    const hotTableComponent = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [statusChecked, setStatusChecked] = useState(false);
    const [mceEditedData, setMCEEditedData] = useState([]);
    const [newData, setNewData] = useState([]);

    //redux hook
    const { componentListingData, mceData, activeScenarioId } = useSelector((state) => state.initialParameter);
    const dispatch = useDispatch();

    useEffect(() => {
        setMCEEditedData(mceData?.data?.map(({ id, category, component_title, description
            , monthly_replacement_cost, notes }) => [id, category, component_title, description,
                monthly_replacement_cost, notes]));
    }, [componentListingData]);
    useEffect(() => {
        setMCEEditedData(mceData?.data?.map(({ id, category, component_title, description
            , monthly_replacement_cost, notes }) => [id, category, component_title, description,
                monthly_replacement_cost, notes]));
    }, [mceData]);

    const addRow = () => {
        if (mceEditedData?.length == 0 || mceEditedData == undefined) {
            const newRow = ['', 'Other', 'New Component', 'New', 0, ''];
            setMCEEditedData([newRow]);
        }
        else {
            hotTableComponent.current.hotInstance.alter('insert_row_above', 0);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 0, '');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 1, 'Other');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 2, 'New Component');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 3, 'New');
            hotTableComponent.current.hotInstance.setDataAtCell(0, 4, 0);
            hotTableComponent.current.hotInstance.setDataAtCell(0, 5, 'None');
        }
    }

    useEffect(() => {
        setNewData(mceData);
    }, [mceEditedData])

    // const handleAfterRemoveRow = (index) => {
    //     const data = newData?.data[index];
    //         deleteMonthlyExpense(data?.id).then((res) => {
    //             console.log(res);
    //         }).catch((err) => {
    //             console.log(err);
    //         })

    //     const updatedData = hotTableComponent.current.hotInstance.getData();
    //     setMCEEditedData(updatedData);
    // }

    const handleAfterRemoveRow = (index) => {
        if (newData?.data && newData.data[index]) {
            const data = newData.data[index];
            deleteMonthlyExpense(data?.id).then((res) => {
                            console.log(res);
                        }).catch((err) => {
                            console.log(err);
                        })
            
        } else {
            console.log(`Data at index ${index} is undefined or empty.`);
        }
        const updatedData = hotTableComponent.current.hotInstance.getData();
        setMCEEditedData(updatedData);
    }

    const afterChange = (changes, source) => {
        if (source === 'edit') {
            const newData = Handsontable?.helper?.arrayMap(mceEditedData, (row) => [...row]);
            changes?.forEach(([row, col, oldValue, newValue]) => {
                console.log("oldvalue", oldValue);
                // newData[row][col] = oldValue;
                newData[row][col] = newValue;
            });
            setMCEEditedData(newData);

        }
    };


    useEffect(() => {
        dispatch(dispatchMCEEditedData(mceEditedData));
    }, [mceEditedData])

    const handleClick = () => {
        setExpanded(!expanded);
    };
    const handleStatusEnable = (e) => {
        setStatusChecked(e.target.checked)
        const params = { "status": e.target.checked }
        if (e.target.checked == true) {
            MCEStatus(activeScenarioId, params).then((res) => {
                return res;
            }).catch((err) => {
                console.error(err);
            })
        }
    }

    return (
        <>
            <div className='main_div_data_entry_tables buttonright'>
                <Accordion className="scenario_container" expanded={expanded}
                    sx={{
                        my: '1rem',
                        // backgroundColor: '#F8F8F8 '
                    }}>
                    <AccordionSummary expandIcon={expanded ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} style={{ color: 'var(--primary-color)' }} onClick={handleClick} className='loans_Expenditure_header '>
                        Monthly Common Expenses
                    </AccordionSummary>

                    <AccordionDetails style={{ marginBottom: '100px' }}>
                        <div style={{ marginBottom: '16px', display: 'flex', gap: '20px' }}>
                            <div>
                                <button className="btn btn-light button_class" onClick={addRow} disabled={statusChecked == false ? true : false}><AddIcon sx={{ size: 'small' }} /> Add Row</button>
                            </div>
                            <div>
                                <p style={{ fontWeight: '500', fontSize: '16px', top:'10px',left:"7px" }}> Enable:  <Switch onChange={handleStatusEnable} /> </p>
                            </div>
                        </div>
                        <div>
                            <div>
                                <HotTable
                                    ref={hotTableComponent}
                                    data={mceEditedData}
                                    columns={[
                                        {
                                            title: 'Id',
                                            type: 'text',

                                        },
                                        {
                                            title: 'Category',
                                            type: 'text',
                                            readOnly: statusChecked == false ? true : false
                                        },
                                        {
                                            title: 'Component Title',
                                            type: 'text',
                                            readOnly: statusChecked == false ? true : false
                                        },

                                        {
                                            title: 'Description',
                                            type: 'text',
                                            readOnly: statusChecked == false ? true : false
                                        },

                                        {
                                            title: 'Monthly Replacement Cost',
                                            type: 'numeric',
                                            readOnly: statusChecked == false ? true : false,
                                            numericFormat: {
                                                pattern: '$ 0,0.00',
                                                culture: 'en-US',
                                            },
                                        },
                                        {
                                            title: 'Notes',
                                            type: 'text',
                                            readOnly: statusChecked == false ? true : false
                                        },
                                    ]}
                                    // enable sorting for all columns
                                    columnSorting={true}
                                    contextMenu={['remove_row', 'alignment']}
                                    rowHeaders={true}
                                    stretchH="all"
                                    height="auto"
                                    afterChange={afterChange}
                                    afterRemoveRow={handleAfterRemoveRow}
                                    hiddenColumns={{
                                        columns: [0],
                                        indicators: true,
                                    }}
                                    // colWidths={[70,100,100,60,100,90]}
                                    licenseKey="non-commercial-and-evaluation"
                                />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>
        </>
    );
};
export default MonthlyExpensesTable;