import { combineReducers } from 'redux';
import initialParameterReducer from './scenarioManagementReducer';
import fundingPlansReducer from './fundingPlanReducer';
import logInTokenReducer from './logInReducer';
import accountInfoReducer from './accountInfoReducer';

const rootReducer = combineReducers({
  initialParameter: initialParameterReducer,
  fundingPlans: fundingPlansReducer,
  logInToken: logInTokenReducer,
  accountInfo: accountInfoReducer
});

export default rootReducer;