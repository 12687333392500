
import React, {  useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import ReactApexChart from 'react-apexcharts';

const ThirtyYrExpenditure = (props) => {
    const [expanded, setExpanded] = useState(false);

    const handleClick = () => {
        setExpanded(!expanded);
    }

    const options = {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: props?.thirtyYrExpenditureData?.map(item => item.year),
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return  value?.toFixed(0) + ' K'; // Format the y-axis value with a dollar symbol
            },
          },
        },
      };
    
      const series = [
        {
          name: 'Series',
          data: props?.thirtyYrExpenditureData?.map(item => item.expenditures),
        },
      ];
    


    return (
        <>
            <div className="main_div_data_entry_tables buttonright">
                <Accordion expanded={expanded} style={{  borderTop: '3px solid var(--primary-color)'}} >
                    <AccordionSummary style={{color:'var(--primary-color)'}}expandIcon={expanded ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={handleClick}>
                        Thirty Year Expenditure ($)
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="bar"
                            height={350}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}

export default ThirtyYrExpenditure;