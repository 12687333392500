import React from "react";

import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import '../../../assets/styles/style.css';


function AddScenarios(props) {
    //functions
    const handleClose = () => {
        props.setAddScenario(false);
    }

    return (
        <>
            <Dialog
                open={props.openAddScenario}
                onClose={handleClose}
                maxWidth='lg'
                PaperProps={{
                    sx: {
                        width: "50%",
                        overflow: 'hidden !important',
                    }
                }}
            >
                <DialogTitle style={{ cursor: 'move', fontWeight: '600',borderBottom:'1px solid lightgray' }}>
                    <div className="d-flex">
                        <div style={{ width: '100%' }}>
                        The maximum number of scenarios has been achieved
                        </div>
                        <div>
                            <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
                        </div>
                    </div>

                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className="edit_modal_inputs" >
                            <p>
                            Maximum 5 scenarios are allowed in your current subscription plan. Currently there are 5 saved scenarios in the database. In order to add a new scenario you can delete or edit one or more of the existing scenarios.
                            </p>
                        </div>

                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}
export default AddScenarios;