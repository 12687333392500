import React, { useEffect, useRef, useState } from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { HotTable } from "@handsontable/react";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { useSelector } from "react-redux";
import { ImportComponents, componentListapi } from "../../services/Services";
import showNotification from "../common/Notification";

function ImportComponent({ handleCloseImport, setIsImport, setResults, setPageChange, currentPages }) {  //eslint-disable-line
    const [componentEditedData, setComponentEditedData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const hotTableRef = useRef('');
    const { componentListingData } = useSelector((state) => state.initialParameter);
    const totalItems = componentEditedData?.length;
    const itemsPerPage = 10;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = componentEditedData?.slice(startIndex, endIndex);
    const [filteredData, setFilteredData] = useState('');
    const [rowsId, setRowsId] = useState([])

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        setComponentEditedData(componentListingData[0]?.map(({ category, component_title, description, useful_life_year
            , current_replacement_cost, id }) => [category, component_title, description, useful_life_year
                , current_replacement_cost, id]));
    }, [componentListingData]);

    const handleSearch = (e) => {
        const filtered = currentItems?.filter(item => {
            return item[0] && item[0]?.toLowerCase().includes(e?.target?.value?.toLowerCase());
        });
        if (e.target.value == '') {
            setFilteredData('')
        } else {
            setFilteredData(filtered);
        }
    }

    const afterSelection = (startRow, startCol, endRow, endCol, selectionLayerLevel) => {  //eslint-disable-line
        const selectedRowIds = [];
        const selectedRowsData = []
        const step = startRow <= endRow ? 1 : -1;
        console.log("startRow", startRow)

        for (let i = startRow; (step === 1 && i <= endRow) || (step === -1 && i >= endRow); i += step) {
            const selectedRowData = filteredData === '' ? currentItems[i] : filteredData[i];
            if (selectedRowData && selectedRowData.length > 5) {
                const selectedId = selectedRowData[5];
                const selectedData = selectedRowData[1]
                selectedRowIds.push(selectedId);
                selectedRowsData.push(selectedData)
                setRowsId(selectedRowIds)
                // setRowsdata(selectedRowsData)
            }
        }
    };

    const handleImport = () => {
        let params = { "component_ids": rowsId }
        ImportComponents(params).then(() => {
            setIsImport(false)
            componentListapi(currentPages).then((res) => {
                setResults(res?.data?.data)
                setPageChange(res?.data?.meta)
            })
        }).catch(() => {
            showNotification("danger","Record is not Imported")
        })
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '70%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="div">
                        Import
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        <div style={{ display: "inline-flex", justifyContent: "end", width: '100%', paddingBottom: '2%' }}>
                            <input type='search' placeholder="Search" onChange={handleSearch} style={{ border: '2px solid lightgray', borderRadius: '5px' }} />
                        </div>
                        <div>
                            <HotTable
                                ref={hotTableRef}
                                data={filteredData == '' ? currentItems : filteredData}
                                columns={[
                                    {
                                        title: 'Category',
                                        type: 'text',
                                    },
                                    {
                                        title: 'Component Title',
                                        type: 'text',
                                    },
                                    {
                                        title: 'Description',
                                        type: 'numeric'
                                    },
                                    {
                                        title: 'Useful Life (years)',
                                        type: 'numeric'
                                    },
                                    {
                                        title: 'Replacement Cost',
                                        type: 'numeric',
                                        numericFormat: {
                                            pattern: '$ 0,0.00',
                                            culture: 'en-US',
                                        },
                                    },

                                ]}
                                columnSorting={true}
                                rowHeaders={true}
                                contextMenu={['remove_row', 'alignment']}
                                stretchH="all"
                                height="auto"
                                // afterChange={afterChange}
                                afterSelection={afterSelection}
                                selectionMode="multiple"
                                licenseKey="non-commercial-and-evaluation"
                            />
                        </div>
                    </Typography>
                    <div style={{ padding: '10px 0px', float: 'right' }}>
                        <button className="btn_1"
                            style={{ marginRight: '10px' }}
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Prev
                        </button>
                        <span style={{ fontSize: '15px' }}>Page{currentPage} </span>
                        <button className="btn_1"
                            style={{ marginLeft: '10px' }}
                            disabled={currentPage == totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                        </button>
                    </div>

                    <div style={{ marginTop: '30px' }}>
                        <button className="button_class " style={{ marginTop: "2%" }} onClick={handleImport}><InsertDriveFileIcon style={{ fontSize: '17px', margin: '2px 2px' }} />Import selected to Reference List</button>
                        <button className="button_class " style={{ marginTop: "2%", marginRight: "3%", position: 'absolute', right: '0' }} onClick={handleCloseImport}>Cancel</button>
                    </div>
                </Box>
            </Modal>
        </>
    )

}
export default ImportComponent