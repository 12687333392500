import React from 'react';

import PersonIcon from '@mui/icons-material/Person';
import '../layouts/Header.css'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { logoutUser } from '../services/Services';
const Header = () => {
    const navigate = useNavigate();
    const details = useSelector((state) => state.accountInfo);
    // const refreshToken = localStorage.getItem("refreshToken");
    const handleSignOutClick = () => {

        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        navigate("/");
        // const payload = {
        //     "refresh" : refreshToken
        // }
        // logoutUser(payload).then(() => {
        //     localStorage.removeItem("token");
        //     localStorage.removeItem("refreshToken");
        //     navigate("/");
        // }).catch((err) => {
        //     alert(err.response.data.message);
        // })
    }

    return (
        <div className="header">
            <span className='header_title_span'> <PersonIcon /> {details?.email?.user_Detail?.email}</span>
            <div className="buttons">

                <button className='header_button'>Report Issue</button>
                <button className='header_button' onClick={handleSignOutClick}>Sign Out</button>
            </div>
        </div>
    )
}

export default Header;