import React from "react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { changePassword } from "../../services/Services";
import showNotification from "../common/Notification";

function ChangePassword () {
    const [email,setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("")

    const navigate = useNavigate();

    const handleSubmitClick = (e) => {
        e.preventDefault();
        let payload = {
            "email" : email,
            "new_password" : password
        }
        changePassword(payload).then((res) => {
            showNotification("success",res.data.message);
            navigate("/");
        }).catch((err) => {
            showNotification("danger",err.response.data);
        })
    }

    const validatePassword = (_password) => {
        if(_password == "", password.length>=5){
            setPasswordError("");
        }else{
            setPasswordError("Enter a valid password");
        }
    }

    const validateEmail = (_email) => {
        if (
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(_email) &&
            _email !== ""
        ){
            setEmailError("")
        }else{
            setEmailError("Enter the valid email");
        }
    }

    const handleEmailChange = (e) => {
       const EMAIL = e.target.value;
       setEmail(EMAIL);
       validateEmail(EMAIL);
    }
    const handlePasswordChange = (e) => {
       const Password = e.target.value;
       setPassword(Password);
       validatePassword(Password);
    }
    return(
        <>
            <div className="main_login">
                <div className="inner_div">
                    <div style={{ padding: '20px' }}>
                        <div className="login_header">
                            <h3>Community Planning Header</h3>
                        </div>
                        <div>
                            <form action="" className="login_form" >
                                <div className="input_div">
                                    <input type="email" name="email" id="email" onChange={handleEmailChange} required placeholder="Enter email" />
                                </div>
                                <div className="error">{emailError }</div>
                                <div className="input_div"> 
                                    <input type="text" name="password" id="password" onChange={handlePasswordChange}required placeholder="Enter New Password"/>
                                </div>
                                <div className="error">{passwordError}</div>
                                <div className="login_btn">
                                    <button className='form-control btn' onClick={handleSubmitClick}>Submit</button>
                                </div>
                            </form>
                            <div style={{ display: 'flex', justifyContent: 'right', margin: '10px' }}>
                                <Link to={"/"}>Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePassword;