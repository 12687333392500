import React from 'react';
import Chart from 'react-apexcharts';

const PieChartCD = (props) => {

  let sumOfRC = 0;
  let avgOfRC;
  const getReserveContribution = props.FundDistributionTable?.map((item) => {
    sumOfRC = parseInt(sumOfRC + item[props.tableKeys[7]?.key]);
    return sumOfRC;
  });
  console.log('getReserveContribution', getReserveContribution);

  const getAvgReserveContribution = props.FundDistributionTable?.map((item) => {
    avgOfRC = ((item[props.tableKeys[7]?.key] / sumOfRC) * 100)?.toFixed(2);
    return parseFloat(avgOfRC);
  });

  const newData = props.FundDistributionTable?.map((item, index) => ({
    ...item,
    data: getAvgReserveContribution[index]
  }));

  const generateChartData = () => {
    const sortedData = newData?.sort((a, b) => b.data - a.data);
    const chartData = [];
    let sumOfOthers = 0;

    // Add the three highest values to the chart data
    for (let i = 0; i < Math?.min(3, sortedData.length); i++) {
      chartData?.push({
        label: sortedData[i][props.tableKeys[0]?.key],
        data: sortedData[i].data,
        category: 'others',
        replacementCost: sortedData[i][props.tableKeys[3]?.key],
        usefulLife: sortedData[i][props.tableKeys[1]?.key],
        remainingUsefulLife: sortedData[i][props.tableKeys[2]?.key],
      });
    }
    // Calculate the sum of the remaining values
    for (let i = 3; i < sortedData.length; i++) {
      sumOfOthers += sortedData[i].data;
    }

    // Add "Others" to the chart data
    if (sumOfOthers > 0) {
      chartData?.push({
        label: "Others",
        data: sumOfOthers,
        category: 'others',
        replacementCost: '500',
        usefulLife: '4',
        remainingUsefulLife: '3',
      });
    }

    return chartData;
  };

  const getData = generateChartData();

  const chartOptions = {
    labels: getData?.map((data) => data.label),
    tooltip: {
      y: {
        formatter: function (value) {
          return value;
        }
      },
      custom: function ({ series, seriesIndex, w }) {
        console.log(w);

        console.log('data=====', series, seriesIndex);
        let filter = getData?.filter((item, index) => item.label == w.config.labels[index]);
        let values = Object?.entries(filter[seriesIndex]);
        if (props.selectedComponent == 'individual component') {
          return `<div>${values[0][1]}<br>
          <em>Category:</em> ${values[2][1]} <br>
                <em>Replacement Cost:</em> ${values[3][1]}<br>
                <em>Useful Life:</em> ${values[4][1]}<br>
                <em>Remaining Useful Life:</em> ${values[5][1]}<br>
                <strong>Importance:</strong> ${values[1][1]}<br></div>`;
        } else if (props.selectedComponent == "component category") {
          return `<div>${values[0][1]}<br>
          <em>Replacement Cost:</em> ${values[3][1]}<br>
          <em>Useful Life:</em> ${values[4][1]}<br>
          <em>Remaining Useful Life:</em> ${values[5][1]}<br>
          <strong>Importance:</strong> ${values[1][1]}<br></div>`;
        } else {
          return `<div>${values[0][1]}<br>
          <em>Replacement Cost:</em> ${values[3][1]}<br>
          <strong>Importance:</strong> ${values[1][1]}<br></div>`;
        }

      }
    },
    series: getData?.map((data) => data.data),
    colors: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'],
    legend: {
      show: true,
      position: 'right'
    }

  };

  return (
    <div>
      <Chart options={chartOptions} series={chartOptions.series} type="pie" width="500" />
    </div>
  );
};

export default PieChartCD;