import React, { useEffect, useMemo, useState } from "react";
import { useRef } from "react";

import './InitialParameterTable.css';
import 'react-datasheet-grid/dist/style.css';
import { dispatchINPEditedData, dispatchSATOneData, dispatchSATThreeData, dispatchSATTwoData, dispatchSATYearData } from "../../../../redux/actions/scenarioManagementAction";

import Handsontable from 'handsontable';
import HotTable from '@handsontable/react';
import { useDispatch, useSelector } from "react-redux";
import { getSpecialAssessmentTableListing, updateInitialParameterTable } from "../../../../services/Services";

const InitialParameter = () => {
    //redux hook
    const { initialParameterListingData,activeScenarioId } = useSelector((state) => state.initialParameter);
    const dispatch = useDispatch();
    const hotTableComponent = useRef(null);

    const [data, setData] = useState([]);

    const afterChange = (changes, source) => {
        if (source === 'edit' && changes != null) {
            const newData = Handsontable?.helper?.arrayMap(data, (row) => [...row]);
            changes.forEach(([row, col, oldValue, newValue]) => { //eslint-disable-line
                if (col == 1) {
                    const date = new Date(newValue);
                    date.setFullYear(date.getFullYear() + 1);

                    const hotTable = hotTableComponent.current.hotInstance;
                    hotTable.setDataAtCell(row, col + 1, date.toISOString().substring(0, 10));
                }
                else {
                    // newData[row][col] = oldValue;
                    newData[row][col] = newValue;
                }
            });
            setData(newData);
        }
    };

    const updateInitialParam = useMemo(() => {
        if (!data?.length) return []
        let params = {
            // "id": data[0][0],
            "fiscal_year_start": data[0][1],
            "fiscal_year_end": data[0][2],
            "starting_balance": data[0][3],
            "current_yearly_reserve_contribution": data[0][4],
            "proposed_yearly_reserve_contribution": data[0][5],
            "inflation": data[0][6],
            "number_of_units": data[0][7],
            "default_interest_rate": data[0][8],
            "total_assessment_amount_per_month": data[0][10],
            // "scenario_id": initialParameterListingData[0]?.scenario_id,
            'delinquent_discount': data[0][9],
            'minus_delinquent_payments': initialParameterListingData[0]?.minus_delinquent_payments,
        }
        return params
    }, [data])

    const [contribution,setContribution]=useState(null) //eslint-disable-line
    

    const updateInitialParamApi = async(scenarioId) => {
        updateInitialParameterTable(updateInitialParam,scenarioId).then((res)=>{
            setContribution(res?.data?.unit_wise_contributions)          
        })
    }

    const specialAssessmentTableView = async(scenarioId) => {
        getSpecialAssessmentTableListing(scenarioId).then((res) => {
            dispatch(dispatchSATOneData((res.data.data).slice(0, 10)));
            dispatch(dispatchSATTwoData((res.data.data).slice(10, 20)));
            dispatch(dispatchSATThreeData((res.data.data).slice(20)));
            dispatch(dispatchSATYearData(res.data));
        }).catch((err) => {
            console.log(err)
        })
    }
    

    useEffect(() => {
        if (data.length>0 && activeScenarioId) {
            updateInitialParamApi(activeScenarioId)
            specialAssessmentTableView(activeScenarioId)
        }            
    }, [data])

    useEffect(() => {

        setData(initialParameterListingData?.map(({ id, fiscal_year_start, fiscal_year_end, starting_balance,
            current_yearly_reserve_contribution, proposed_yearly_reserve_contribution, inflation, number_of_units, default_interest_rate, delinquent_discount,
            total_assessment_amount_per_month}) => {

            let startDate = new Date(fiscal_year_start);
            let startDateDay = startDate.getDate();
            let startDateMonth = startDate.getMonth() + 1;
            let startDateYear = startDate.getFullYear();
            let startFormatedDate = startDateYear + "-" + startDateMonth + "-" + startDateDay;

            let endDate = new Date(fiscal_year_end);
            let endDateDay = endDate.getDate();
            let endDateMonth = endDate.getMonth() + 1;
            let endDateYear = endDate.getFullYear();
            let endFormatedDate = endDateYear + "-" + endDateMonth + "-" + endDateDay;


            return [id, startFormatedDate, endFormatedDate, starting_balance,
                current_yearly_reserve_contribution, proposed_yearly_reserve_contribution, inflation, number_of_units, String(default_interest_rate), String(delinquent_discount),
                total_assessment_amount_per_month]
        }));

    }, [initialParameterListingData]);

    useEffect(() => {
        dispatch(dispatchINPEditedData(data));
    }, [data])

    return (
        <>
            <div className="main_div_initial_parameter">
                <div className="inner_div">
                    <div className="initial_header">
                        <h3>Enter Initial Parameters</h3>
                    </div>
                    <div>
                        <HotTable
                            ref={hotTableComponent}
                            data={data}
                            columns={[
                                {
                                    title: 'Id',
                                    type: 'text',

                                },
                                {
                                    title: 'Fiscal Year Start',
                                    type: 'date',
                                    dateFormat: 'YYYY-MM-DD',

                                },
                                {
                                    title: 'Fiscal Year End',
                                    type: 'date',
                                    dateFormat: 'YYYY-MM-DD',
                                    readOnly: true

                                },
                                {
                                    title: 'Starting Balance',
                                    type: 'numeric',
                                    numericFormat: {
                                        pattern: '$ 0,0.00',
                                        culture: 'en-US',
                                    },

                                },
                                {
                                    title: 'Current Yearly Reserve Contribution',
                                    type: 'numeric',
                                    numericFormat: {
                                        pattern: '$ 0,0.00',
                                        culture: 'en-US',
                                    },
                                },
                                {
                                    title: 'Proposed Yearly Reserve Contribution',
                                    type: 'numeric',
                                    numericFormat: {
                                        pattern: '$ 0,0.00',
                                        culture: 'en-US',
                                    },
                                },
                                {
                                    title: 'Inflation',
                                    renderer: (instance, td, row, col, prop, value) => {
                                        td.innerHTML = value ? value + '%' : '';
                                        td.style.textAlign = 'right';
                                    }
                                },
                                {
                                    title: 'Number of Units',
                                    type: 'numeric'
                                },
                                {
                                    title: 'Default Interest Rate',
                                    renderer: (instance, td, row, col, prop, value) => {
                                        td.innerHTML = value ? value + '%' : '';
                                        td.style.textAlign = 'right';
                                    }
                                },
                                {
                                    title: 'Delinquent Discount',
                                    renderer: (instance, td, row, col, prop, value) => {
                                        td.innerHTML = value ? value + '%' : '';
                                        td.style.textAlign = 'right';
                                    }
                                },
                                {
                                    title: 'Total Assessment Amount per Month',
                                    type: 'numeric',
                                    numericFormat: {
                                        pattern: '$ 0,0.00',
                                        culture: 'en-US',
                                    },
                                },
                            ]}
                            columnSorting={false}
                            stretchH="all"
                            height='auto'
                            afterChange={afterChange}
                            hiddenColumns={{
                                columns: [0],
                                indicators: true,
                            }}
                            licenseKey="non-commercial-and-evaluation"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default InitialParameter;