import User from "../../configurations/Constant";

export const dispatchAccountData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.ACCOUNTDATA,
        email: json,
      })
    } catch (e) {
      dispatch({
        type: User.ACCOUNTDATA,
        email: '',
      });
    }
  }

  export const dispatchCommunityData = (json) => async (dispatch) => {
    try {
      dispatch({
        type: User.COMMUNITYINFO,
        communitydata: json,
      })
    } catch (e) {
      dispatch({
        type: User.COMMUNITYINFO,
        communitydata: {},
      });
    }
  }