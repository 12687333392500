import React, { useEffect, useMemo, useState } from "react";
import { Card, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, makeStyles } from "@material-ui/core";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Handsontable from "handsontable";
import { useDispatch, useSelector } from "react-redux";
import {
    dispatchFFPAdvanceParameterData, dispatchFFPCustomObjData, dispatchFFPCustomObjDatamax, dispatchFFPCustomObjDatamin, dispatchFFPDefaultValueData, dispatchFFPFundingPriorityData, dispatchFFPMinEndBalYearData, dispatchFFPMinimalEndingData, dispatchFFPMinimalPercentData,
    dispatchFFPPercentChangeData, dispatchFFPSelectedRowData, dispatchFFPSpecialAssYearData, dispatchFFPTableData, dispatchFULLFunding
} from "../../../redux/actions/fundingPlansAction";
import { getFFPListing, getYearFundingPlanListing } from "../../../services/Services";
import FFPAdvancedFundingParameters from "./FFPAdvancedParameter";
import { CSVLink } from "react-csv";
import showNotification from "../../common/Notification";


const useStyles = makeStyles({
    oddRows: {
        backgroundColor: '#f9f9f9',
    },
    evenRows: {
        backgroundColor: '#fff',
    },
    hoverRow: {
        '&:hover': {
            backgroundColor: '#f9f9f9'
        }
    },
    tableHead: {
        backgroundColor: 'white',
        borderTop: '2px solid #111',
        borderBottom: '2px solid #111'
    },
    tableHeadCells: {
        textAlign: 'center',
        padding: '10px 18px',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#333'
    },
    tableBodyCells: {
        textAlign: 'center'
    },
    hoverTableCell: {
        '&:hover': {
            fontWeight: 'bold'
        }
    },
    rowSelect: {
        backgroundColor: '#aab7d1'
    },
    green: {
        background: 'rgb(155, 245, 155)'
    },
    orange: {
        background: 'rgb(255, 235, 153)'
    },
    red: {
        background: 'rgb(242, 91, 61)'
    }
})

const FullFundingPlan = (props) => {
    //redux hook
    const { activeScenarioId } = useSelector((state) => state.initialParameter);
    const { ffpAdvanceParameterData, ffpMinimalEndingData, ffpPercentChangeData, ffpTableData, ffpSelectedRowData,
        ffpMinimalPercentData, ffpMinBalanceYearData, ffpSpecialAssYearData, ffpFundingPriority, ffpDefaultValueData, ffpCustomObjData } = useSelector((state) => state.fundingPlans);
    const { inpEditedData } = useSelector((state) => state.initialParameter);
    const dispatch = useDispatch();


    //usestate
    const [selectedRows, setSelectedRows] = useState(ffpSelectedRowData);
    const [openAFP, setOpenAFP] = useState(ffpAdvanceParameterData);
    const [FFPData, setFFPData] = useState(ffpTableData);
    const [endingBalance, setEndingBalance] = useState(ffpMinimalEndingData);
    const [minimalFundBalance, setMinimalFundBalance] = useState(ffpMinimalPercentData);
    const [percentChange, setPercentChange] = useState(ffpPercentChangeData);
    const [defaultVale, setDefaultValue] = useState(ffpDefaultValueData);
    const [customData, setCustomData] = useState([]);
    const [yearFundingPlan, setYearFundingPlan] = useState([]);
    const [progress, setProgress] = React.useState(ffpFundingPriority);
    const [minEndingBalanceYear, setMinEndingBalanceYear] = useState(ffpMinBalanceYearData);
    const [specialAssessmentsAddedForYears, setSpecialAssessmentsAddedForYears] = useState(ffpSpecialAssYearData);
    const [customDataObj, setCustomDataObj] = useState(ffpCustomObjData)
    const [isCopied, setIsCopied] = useState(false);
    const [mindata,setMindata]=useState(0)

    const filteredSpecialAssessmentData = yearFundingPlan?.filter((item) => item <= minEndingBalanceYear);
    const classes = useStyles();

    const handleSelectedRows = (index) => {
        if (selectedRows?.includes(index)) {
            setSelectedRows(selectedRows.filter(data => data !== index));
            dispatch(dispatchFFPSelectedRowData(selectedRows?.filter(data => data !== index)));
        } else {
            setSelectedRows([...selectedRows, index]);
            dispatch(dispatchFFPSelectedRowData([...selectedRows, index]));
        }
    };

    const formatePercent = (value) => {
        return `${value?.toFixed(2)}%`
    }

    const formatDollar = (value) => {
        return `$${value.toLocaleString()}`
    }

    const formatNumberWithCommas = (number) => {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }

    const handleOpneAFP = () => {
        setOpenAFP(!openAFP);
        dispatch(dispatchFFPAdvanceParameterData(!openAFP));
    }

    const handleEndingBalance = (e) => {
        setEndingBalance(e.target.value);
        dispatch(dispatchFFPMinimalEndingData(e.target.value));
    }

    const handlePercentChange = (e) => {
        setPercentChange(e.target.value);
        dispatch(dispatchFFPPercentChangeData(e.target.value));
        if (e.target.value == 'Custom') {
            // let minData = FFPData?.map(item => item.percent_change * (-1));
            // let maxData = FFPData?.map(item => item.percent_change);
            let minData = [35.17, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3];
            let maxData = [35.17, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3];
            setCustomDataObj(prevObj => ({ ...prevObj, min: minData }));
            setCustomDataObj(prevObj => ({ ...prevObj, max: maxData }));
            // dispatch(dispatchFFPCustomObjData(prevObj => ({ ...prevObj, min: minData })));
            // dispatch(dispatchFFPCustomObjData(prevObj => ({ ...prevObj, max: maxData })));
            dispatch(dispatchFFPCustomObjData({ min: minData }));
            dispatch(dispatchFFPCustomObjData({ max: maxData }));
        }
        else {
            setCustomDataObj({
                min: [],
                max: []
            })
            dispatch(dispatchFFPCustomObjData({
                min: [],
                max: []
            }))
        }
    }

    const handleMinimalFundBalance = (e) => {
        setMinimalFundBalance(e.target.value);
        dispatch(dispatchFFPMinimalPercentData(e.target.value));
    }

    const handleMinEndingBalanceYear = (e) => {
        setMinEndingBalanceYear(e.target.value);
        dispatch(dispatchFFPMinEndBalYearData(e.target.value));
    }

    const handleSpecialAssessmentAddedForYears = (e) => {
        setSpecialAssessmentsAddedForYears(e.target.value);
        dispatch(dispatchFFPSpecialAssYearData(e.target.value));
    }


    const handleProgressChange = (event, newProgress) => {
        setProgress(newProgress);
        dispatch(dispatchFFPFundingPriorityData(newProgress));
    };

    const afterChangeDefault = (changes, source) => {
        if (source === 'edit') {
            const newData = Handsontable?.helper?.arrayMap(defaultVale, (row) => [...row]);
            // changes.forEach(([row, col, newValue]) => {
            changes.forEach(([row, col, oldValue, newValue]) => {
                // console.log("oldvalue", oldValue);
                newData[row][col] = oldValue;
                newData[row][col] = newValue;
            });
            setDefaultValue(newData);
            dispatch(dispatchFFPDefaultValueData(newData));
        }
    };

    const afterChangeCustom = (changes, source) => {
        if (source === 'edit') {
            const newData = Handsontable?.helper?.arrayMap(customData, (row) => [...row]);
            // changes.forEach(([row, col, newValue]) => {
            changes.forEach(([row, col, oldValue, newValue]) => {
                // console.log("oldvalue", oldValue);
                newData[row][col] = oldValue;
                newData[row][col] = newValue;
            });
            setCustomData(newData);
            let minData = [];
            let maxData = [];
            newData.forEach(item => {
                minData.push(item[1]);
                maxData.push(item[2]);
            });
            setCustomDataObj(prevObj => ({ ...prevObj, min: minData }));
            setCustomDataObj(prevObj => ({ ...prevObj, max: maxData }));
            dispatch(dispatchFFPCustomObjData(prevObj => ({ ...prevObj, min: minData })));
            dispatch(dispatchFFPCustomObjData(prevObj => ({ ...prevObj, max: maxData })));
            dispatch(dispatchFFPCustomObjDatamin(minData));
            dispatch(dispatchFFPCustomObjDatamax(maxData));
        }
    };

    function customRenderer(instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        if ((col === 1 || col === 2) && row === 0) {
            td.style.background = '#f1f1f1';
            cellProperties.readOnly = true;
        }
    }

    // const fulldata= useSelector((state) => state.fundingPlans);
    useEffect(() => {
        setDefaultValue([[-3, 3]]);
    }, [])
    const handleReset = () => {
        setDefaultValue([[-3, 3]]);
        if (percentChange == 'Custom')
            setCustomDataObj({
                min: [35.17, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
                max: [35.17, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
            });
        else {
            setDefaultValue([[-3, 3]])

        }

    }

    const alertMessage = useMemo(() => {
        if (FFPData.length > 0) {
            const item = FFPData[0];
            if (item?.reserve_contribution && inpEditedData?.length != 0) {
                const result = Math.abs(item?.reserve_contribution - inpEditedData[0][4]);
                return `Increased Reserve Contribution: $${formatNumberWithCommas(result)}`;
            }
        }
        return null;
    }, [FFPData, inpEditedData]);

    let params = {
        "min_end_balance_expend_ratio": parseFloat(endingBalance),
        "reserve_contribution":
        {
            "first_year": true,
            "percentage_change": percentChange,
            "default_min_value": defaultVale[0][0] != 0 ? defaultVale[0][0] : -3.0,
            'default_max_value': defaultVale[0][1] != 0 ? defaultVale[0][1] : 2.9,
            'custom_value': customDataObj
            // "custom_value": {"min": [35.17,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90],"max": [35.17,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90,2.90]}
        }
        ,
        "special_assessments": {
            "min_ending_balance_year": minEndingBalanceYear,
            "specialAssessments_added_for_years": specialAssessmentsAddedForYears,
            "funding_priority": progress
        },
        "minimal_percent_funded_last_year": minimalFundBalance
    }

    useEffect(() => {
        dispatch(dispatchFULLFunding({ params }))
    }, [customDataObj, endingBalance, percentChange, defaultVale, minEndingBalanceYear, specialAssessmentsAddedForYears, progress, minimalFundBalance])

    useEffect(() => {
        setCustomData(FFPData?.map(({ year, percent_change }) => [year, (-1) * percent_change, percent_change]));
    }, [FFPData])

    useEffect(() => {
        getFFPListing(activeScenarioId, params).then((res) => {
            props.setLoading(true)
            setFFPData(res?.data?.data);
            setMindata(res?.data?.min_ratio?.toFixed(2))
            dispatch(dispatchFFPTableData(res?.data?.data));
            props.setLoading(false)
        }).catch((err) => {
            showNotification("warning",err?.response?.data?.error)
            props.setLoading(false)
        })

        getYearFundingPlanListing(activeScenarioId).then((res) => {
            setYearFundingPlan(res?.data);

        })
    }, [endingBalance, minimalFundBalance, percentChange, customDataObj, defaultVale, minEndingBalanceYear, progress, specialAssessmentsAddedForYears])

    const handleCopyClick = () => {
        const table = document.getElementById('tableToCopy');
        const range = document.createRange();
        range.selectNode(table);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        if (!isCopied) {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1000);
        }
    };

    const headers = [
        { label: 'Year', key: 'year' },
        { label: 'Starting Balance', key: 'starting_balance' },
        { label: 'Reserve Contribution', key: 'reserve_contribution' },
        { label: 'Percent Change', key: 'percent_change' },
        { label: 'Special Assessments', key: 'special_assessments' },
        { label: 'Interest Earned', key: 'interest_earned' },
        { label: 'Expenditures', key: 'expenditures' },
        { label: 'Ending Balance', key: 'ending_balance' },
        { label: 'Fully Funded Balance', key: 'fully_funded_balance' },
        { label: 'Percent Funded', key: 'percent_funded' },
    ];
    const customFilename = 'Fullfundingplans.csv';

    const printTable = () => {
        const printWindow = window.open('', '', 'width=1000,height=600');

        printWindow.document.open();
        printWindow.document.write('<html><head><title>Full Funding Plan</title></head><body>');

        printWindow.document.write('<table border="0">');

        printWindow.document.write('<thead><tr>');
        Object.keys(ffpTableData.length > 0 && ffpTableData[0]).forEach((header) => {
            printWindow.document.write(`<th>${header}</th>`);
        });
        printWindow.document.write('</tr></thead>');

        printWindow.document.write('<tbody>');
        ffpTableData.length > 0 && ffpTableData.forEach((row) => {
            printWindow.document.write('<tr>');
            Object.values(row).forEach((value) => {
                printWindow.document.write(`<td>${value}</td>`);
            });
            printWindow.document.write('</tr>');
        });
        printWindow.document.write('</tbody>');

        printWindow.document.write('</table>');
        printWindow.document.write('</body></html>');

        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    };

    return (
        <>
            <div >
                {props.loading && <CircularProgress className='circular_loader' />}
            </div>
            <div className="CFP_main_div">
                <Card className="funding_container">
                    <div className="CFP_header">
                        <div className="CFP_header_inner">
                            <h3>Full Funding Plan</h3>
                        </div>
                        <div className="AFP_button" style={{ marginLeft: '1000px' }}>
                            <button onClick={handleOpneAFP}><SettingsSuggestIcon />Advanced Funding Parameters</button>
                        </div>
                    </div>
                </Card>

                {!props.loading &&
                    <div className="CFP_inner_div">
                        <div className="CFP_table_div">
                            <div className="CFP_table_btn">
                                <div>
                                    <button className="button_class" style={{ width: '70px' }} onClick={handleCopyClick}>Copy</button>
                                </div>
                                <div>
                                    <CSVLink data={ffpTableData} headers={headers} filename={customFilename}>
                                        <button className="button_class" style={{ width: '70px' }}>Excel</button>
                                    </CSVLink>
                                </div>
                                <div>
                                    <button className="button_class" style={{ width: '70px' }} onClick={printTable}>Print</button>
                                </div>
                            </div>
                            {isCopied && (
                                <div className="popup">
                                    Copy to clipboard!
                                </div>
                            )}
                            <TableContainer component={Paper}>
                                <Table id="tableToCopy">
                                    <TableHead>
                                        <TableRow className={classes.tableHead}>
                                            <TableCell className={classes.tableHeadCells}>Year</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Starting Balance</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Reserve Contribution</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Percent Change</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Special Assessments</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Interest Earned</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Expenditures</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Ending Balance</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Fully Funded Balance</TableCell>
                                            <TableCell className={classes.tableHeadCells}>Percent Funded</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {FFPData ? (FFPData.map((item, index) => (
                                            <TableRow onClick={() => handleSelectedRows(index)} className={`${index % 2 == 0 ? classes.evenRows : classes.oddRows} ${selectedRows.includes(index) ? '' : classes.hoverRow} ${selectedRows.includes(index) ? classes.rowSelect : ''}`} key={index}>
                                                <TableCell className={classes.tableBodyCells} align="center">{item.year}</TableCell>
                                                {/* <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item?.starting_balance?.toFixed(2))}</TableCell> */}
                                                {
                                                    item?.starting_balance >= 0 ? (
                                                        <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item?.starting_balance?.toFixed(2)))}</TableCell>
                                                    ) : (
                                                        <TableCell className={classes.tableBodyCells} align="center" style={{ color: "red" }}>{formatNumberWithCommas(formatDollar(item?.starting_balance?.toFixed(2)))}</TableCell>
                                                    )
                                                }
                                                <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item?.reserve_contribution?.toFixed(2)))}</TableCell>
                                                <Tooltip title={index == 0 ? alertMessage : ''} placement="top">
                                                    <TableCell className={`${classes.tableBodyCells} ${index === 0 ? classes.hoverTableCell : ''}`} align="center" >{formatePercent(item.percent_change)}</TableCell></Tooltip>
                                                <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item.special_assessments))}</TableCell>
                                                <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item.interest_earned))}</TableCell>
                                                <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item?.expenditures?.toFixed(2)))}</TableCell>
                                                {
                                                    item?.ending_balance >= 0 ? (
                                                        <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item?.ending_balance?.toFixed(2)))}</TableCell>
                                                    ) : (
                                                        <TableCell className={classes.tableBodyCells} align="center" style={{ color: 'red' }}>{formatNumberWithCommas(formatDollar(item?.ending_balance?.toFixed(2)))}</TableCell>
                                                    )
                                                }
                                                {/* <TableCell className={classes.tableBodyCells} align="center">{formatDollar(item?.ending_balance?.toFixed(2))}</TableCell> */}
                                                <TableCell className={classes.tableBodyCells} align="center">{formatNumberWithCommas(formatDollar(item?.fully_funded_balance?.toFixed(2)))}</TableCell>
                                                <TableCell className={`${classes.tableBodyCells} ${item.percent_funded > 70 ? classes.green : ''} ${item.percent_funded > 30 && item.percent_funded < 70 ? classes.orange : ''} ${item.percent_funded < 30 ? classes.red : ''}`} align="center">{formatePercent(item.percent_funded)}</TableCell>
                                            </TableRow>
                                        )))
                                            :
                                            <TableRow>Data is not available</TableRow>
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        {openAFP ? (
                            <div>
                                <FFPAdvancedFundingParameters endingBalance={endingBalance} percentChange={percentChange} defaultVale={defaultVale} customData={customData}
                                    handleEndingBalance={handleEndingBalance} handlePercentChange={handlePercentChange} afterChangeDefault={afterChangeDefault}
                                    afterChangeCustom={afterChangeCustom} handleMinimalFundBalance={handleMinimalFundBalance} minimalFundBalance={minimalFundBalance}
                                    setProgress={setProgress} handleProgressChange={handleProgressChange} progress={progress} yearFundingPlan={yearFundingPlan}
                                    handleMinEndingBalanceYear={handleMinEndingBalanceYear} minEndingBalanceYear={minEndingBalanceYear} handleSpecialAssessmentAddedForYears={handleSpecialAssessmentAddedForYears}
                                    specialAssessmentsAddedForYears={specialAssessmentsAddedForYears} filteredSpecialAssessmentData={filteredSpecialAssessmentData} handleReset={handleReset} customRenderer={customRenderer} mindata={mindata}
                                />
                            </div>) : ''}
                    </div>
                }
            </div>

        </>
    )
};

export default FullFundingPlan;