import React, { useState } from "react";
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@mui/material";
import { updateCommunityInfo } from "../../services/Services";
import showNotification from "../common/Notification";


const EditCommunity = (props) => {


    const [communityName, setCommunityName] = useState('');
    const [communityAddress, setCommunityAddress] = useState('');
    const [updateSnackBar, setUpdateSnackbar] = useState(false);

    const handleCommunityName = (e) => {
        setCommunityName(e.target.value);
    }

    const handleCommunityAddress = (e) => {
        setCommunityAddress(e.target.value);
    }

    // const para = document.getElementById('riya')

    // console.log("para------------",para)
    // const color = 'lime'


    // if(para) para.style=`color: ${color}`;

    const handleSaveAccountInfo = () => {
        let params;
        if (props.getCommunityType == 'community_name') {
            params = {
                "community_name": communityName ? communityName : props.accountCummunityApiInfo?.community_info?.community_name,
                // "user_id" : [props.userId],
            }
        } else {
            params = {
                "community_address": communityAddress ? communityAddress : props.accountCummunityApiInfo?.community_info?.community_address,
                // "user_id" : [props.userId],
            }
        }

        // updateCommunityInfo(props.userId, params).then((res) => {

        updateCommunityInfo(props.communityID, params).then((res) => {
            setUpdateSnackbar(true);
            props.fetchAccountCommunityInfo();
            props.fetchCommunityInfoList();
            setTimeout(() => {
                setUpdateSnackbar(false)
                props.setOpenEditDialog(false);
            }, 1000);
            return res;
        }).catch((err) => {
           showNotification('danger',err.response.data);
        })
    }

    const handleCloseSnackbar = () => {
        setUpdateSnackbar(false);
    };

    // const handleDeleteAccountInfo = () => {
    //     deleteCommunityInfo(props.communityID).then(() => {
    //         showNotification("success","Succesfully Deleted")
    //         props.fetchAccountCommunityInfo();
    //         props.fetchCommunityInfoList();
    //     }).catch(() => {
    //         showNotification("danger","Delete Account Info");
    //     })
    // }
    return (
        <>
            <Dialog
                open={props.openEditDialog}
                onClose={props.handleEdit}
                maxWidth='lg'
                PaperProps={{
                    sx: {
                        width: "50%",
                        overflow: 'hidden !important',
                    }
                }}
            >
                <DialogTitle style={{ borderBottom: '1px solid #e5e5e5', padding: '15px' }}>
                    {props.getCommunityType == 'community_name' ? (
                        <h4 style={{ margin: 0, fontSize: '18px' }}>Modify Community (Homeowner Association) Name</h4>
                    ) : (
                        <h4 style={{ margin: 0, fontSize: '18px' }}>Modify Community (Homeowner Association) Address</h4>
                    )}

                </DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        {props.getCommunityType == 'community_name' ? (
                            <div>
                                <p id='riya'>This name is to be used in the report as the name of the Community (Homeowner Association)</p>
                                <form style={{ width: '50%' }} className="account_info_form">
                                    <div>
                                        <input className="form-control" type="text" placeholder="Ballard Four Seasons" onChange={handleCommunityName} defaultValue={props.accountCummunityApiInfo?.community_info?.community_name} />
                                    </div>
                                </form>
                            </div>
                        ) : (
                            <div>
                                <p>This address is to be used in the reports</p>
                                <form style={{ width: '50%' }} className="account_info_form">
                                    <div>
                                        <textarea className="form-control" type="text" placeholder="Ballard Four Seasons" onChange={handleCommunityAddress} defaultValue={props.accountCummunityApiInfo?.community_info?.community_address} />
                                    </div>
                                </form>
                            </div>
                        )}
                    </DialogContentText>
                    <Snackbar
                        open={updateSnackBar}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                            Community Updated Succesfully.
                        </Alert>
                    </Snackbar>
                </DialogContent>
                <DialogActions style={{ borderTop: '1px solid #e5e5e5' }}>
                {/* style={{ float: 'right', gap: '5%', padding: '10px'}} */}
                    <div className="d-flex" style={{gap : '4%', display :'flex', justifyContent : 'space-around'}}>
                        {/* <div style={{ display : 'flex', justifyContent :'start'}}>
                            <button className="button_class" onClick={handleDeleteAccountInfo}>Delete</button>
                        </div> */}
                        <div>
                            <button className="button_class" onClick={handleSaveAccountInfo}>Save</button>
                        </div>
                        <div>
                            <button className="button_class" onClick={props.handleEdit}>Cancel</button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default EditCommunity;