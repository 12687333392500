import React, { useState, useEffect } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import { Typography, Tab, Tabs, Box } from '@mui/material';
// import Box from '@mui/material/Box';

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import DataEntryTab from '../components/data-entry/DataEntryTab';
import CurrentFundingPlans from '../components/funding-plans/currentFundingPlans/CurrentFundingPlans';
import ThresholdFundingPlan from '../components/funding-plans/ThresholdFundingPlan/ThresholdFundingPlan';
import FullFundingPlan from '../components/funding-plans/fullFundingPlan/FullFundingPlan';
// import Reports from '../components/reports/Reports';
import ComponentDetailTab from '../components/component-detail/ComponentDetail';
import ExpenditureTab from '../components/expenditures/Expenditure';
import DashboardTabs from '../components/dashboard/DashboardTabs';
import AccountInfo from '../components/accountInfo/AccountInfo';
import IconButton from '@mui/material/IconButton';
import { getGeneratePdf } from '../services/Services';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
// import jsPDF from 'jspdf';
import { useRef } from 'react';

import './Header.css';
import Header from './Header';
import showNotification from '../components/common/Notification';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function HeaderTab() {

    const { activeScenarioId } = useSelector((state) => state.initialParameter);

    const [value, setValue] = React.useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorE2, setAnchorE2] = useState(null);
    const [loading, setLoading] = React.useState(false);
    

    const handleDropdownClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDropdownClose = () => {
        // console.log("In close");
        setAnchorEl(null);
    };

    const { cfpCheckData, cfpPercentData, cfpUniformPercentData, cfpCustomValueData, ffpMinimalEndingData, ffpPercentChangeData,
        ffpMinimalPercentData, ffpFundingPriority, ffpSpecialAssYearData, ffpMinBalanceYearData, ffpDefaultValueData,
        tfpMinimalEndingData, tfpPercentChangeData, tfpDefaultValueData, tfpMinBalanceYearData, tfpSpecialAssYearData,
        tfpFundingPriority} = useSelector((state) => state.fundingPlans);
        

    // const fulldatamax = useSelector((state) => state.fundingPlans.tmax);
    // const fulldatamin = useSelector((state) => state.fundingPlans.tmin);
    

    let params = {
        "current_funding": {
            "reserve_contribution":
            {
                "first_year": cfpCheckData,
                "percentage_change": cfpPercentData,
                "default_value": cfpUniformPercentData ? cfpUniformPercentData : 3,
                "custom_value": cfpCustomValueData
            },
            "special_assessments": {},
        },
        "full_funding": {
            "min_end_balance_expend_ratio": parseFloat(ffpMinimalEndingData),
            "reserve_contribution":
            {
                "first_year": true,
                "percentage_change": ffpPercentChangeData,
                "default_min_value": ffpDefaultValueData[0][0] != 0 ? ffpDefaultValueData[0][0] : -3,
                'default_max_value': ffpDefaultValueData[0][1] != 0 ? ffpDefaultValueData[0][1] : 3,
                // "custom_value":{min,max}
                "custom_value":{min:[37.17, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3,3,3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
                    max:[37.17, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3,3,3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3]}

            }
            ,
            "special_assessments": {
                "min_ending_balance_year": ffpMinBalanceYearData,
                "specialAssessments_added_for_years": ffpSpecialAssYearData,
                "funding_priority": ffpFundingPriority
            },
            "minimal_percent_funded_last_year": ffpMinimalPercentData,
        },
        "threshold_funding": {
            "minimal_ending_balance": parseFloat(tfpMinimalEndingData),
            "reserve_contribution":
            {
                "first_year": true,
                "percentage_change": tfpPercentChangeData,
                "default_min_value": tfpDefaultValueData[0][0] != 0 ? tfpDefaultValueData[0][0] : -3,
                "default_max_value": tfpDefaultValueData[0][1] != 0 ? tfpDefaultValueData[0][1] : 3,
                // "custom_value": {...fulldatamin, min: fulldatamin, ...fulldatamax, max: fulldatamax}
                "custom_value":{min:[18.08, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3,3,3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
                    max:[18.08, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3,3,3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3]}
            }
            ,
            "special_assessments": {
                "min_ending_balance_year": tfpMinBalanceYearData,
                "specialAssessments_added_for_years": tfpSpecialAssYearData,
                "funding_priority": tfpFundingPriority
            }
        }
    }

    const handleReportDropdownClick = (event) => {
        setAnchorE2(event.currentTarget);
    };

    const handleReportDropdownClose = () => {
        setAnchorE2(null);
    };

    const handleChange = (event, newValue) => {

        if (newValue != 2 && newValue != 5) {
            setValue(newValue);
        }
        // else if(newValue == 2){
        //     setAnchorEl(event.currentTarget); 
        // }
        // }else if(newValue == 5) {
        //     handleReportsClick(event);
        // }
    };


    const anchorRef = useRef(null);
    const [reportloading, setReportLoading] = useState(false);

    const handleReportsClick = () => {
        setReportLoading(true);
        getGeneratePdf(activeScenarioId, params)
            .then((res) => {
                setAnchorE2(null);

                const fileURL = res.data.download_file_url;

                anchorRef.current.href = fileURL;

                anchorRef.current.click();

                setTimeout(() => {
                    showNotification("success","Download complete!"); // Show the alert after the delay
                }, 5000);
                setReportLoading(false);
            })
            .catch((err) => {
                showNotification("warning",err.response.data.message)
                setReportLoading(false);
            });
    };

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (anchorEl && !anchorEl.contains(event.target)) {
                handleDropdownClose();
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [anchorEl]);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (anchorE2 && !anchorE2.contains(event.target)) {
                handleReportDropdownClose();
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [anchorE2]);

    return (
        <>
            <Header />
            <div className='header_navbar'>
                <div className="header_title"><a>Reserve Study Application</a></div>
                <div>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={false}
                        sx={{
                            width: '100%',
                            '@media (max-width: 890px)': {
                                '& .MuiTabs-flexContainer': {
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                },
                                '& .MuiTab-root': {
                                    marginBottom: '8px',
                                },
                                //   '&.MuiButtonBase-root': {
                                //     color: 'inherit',
                                //     border: '0',
                                //     cursor: 'pointer',
                                //     margin: '0',
                                //     display: "inline-flex",
                                //     outline: '0',
                                //     /* padding: 0; */
                                //     position: 'relative',
                                //     alignItems: 'center',
                                //     userSelect: 'none',
                                //     borderRadius: '0',
                                //     verticalAlign: 'middle',
                                //     // -moz-appearance: 'none',
                                //     justifyContent: 'center',
                                //     textDecoration: 'none',
                                //     backgroundColor: 'transparent',
                                //     // -webkit-appearance: 'none',
                                //     // -webkit-tap-highlight-color: 'transparent',
                                // }
                            },
                        }}
                    >
                        <Tab label="Dashboard" {...a11yProps(0)}
                            sx={{
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                color: 'var(--primary-color)',
                                fontWeight: '600',

                                '&.Mui-selected': {
                                    // color: '#6688CC !important'
                                    color: 'var(--tertiary-color) ',
                                    // padding : '12px 18px'
                                },
                                '&.MuiButtonBase-root': {
                                    // color: 'inherit',
                                    // border: '0',
                                    // cursor: 'pointer',
                                    // margin: '0',
                                    // display: "inline-flex",
                                    // outline: '0',
                                    padding: '12px 18px',
                                    // position: 'relative',
                                    // alignItems: 'center',
                                    // userSelect: 'none',
                                    // borderRadius: '0',
                                    // verticalAlign: 'middle',
                                    // -moz-appearance: 'none',
                                    // justifyContent: 'center',
                                    // textDecoration: 'none',
                                    // backgroundColor: 'transparent',
                                    // -webkit-appearance: 'none',
                                    // -webkit-tap-highlight-color: 'transparent',
                                }
                            }}
                        />
                        <Tab label="Data Entry" {...a11yProps(1)}
                            sx={{
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                color: 'var(--primary-color)',
                                fontWeight: '600',

                                '&.Mui-selected': {
                                    // color: '#6688CC !important'
                                    color: 'var(--tertiary-color) '
                                },
                                '&.MuiButtonBase-root': {
                                    // color: 'inherit',
                                    // border: '0',
                                    // cursor: 'pointer',
                                    // margin: '0',
                                    // display: "inline-flex",
                                    // outline: '0',
                                    padding: '12px 18px',
                                    // position: 'relative',
                                    // alignItems: 'center',
                                    // userSelect: 'none',
                                    // borderRadius: '0',
                                    // verticalAlign: 'middle',
                                    // -moz-appearance: 'none',
                                    // justifyContent: 'center',
                                    // textDecoration: 'none',
                                    // backgroundColor: 'transparent',
                                    // -webkit-appearance: 'none',
                                    // -webkit-tap-highlight-color: 'transparent',
                                }


                            }}
                        />
                        <Tab
                            sx={{
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                color: 'var(--primary-color)',
                                fontWeight: '600',

                                '&.Mui-selected': {
                                    // color: '#6688CC !important'
                                    color: 'var(--tertiary-color) '
                                },
                                '&.MuiButtonBase-root': {
                                    // color: 'inherit',
                                    // border: '0',
                                    // cursor: 'pointer',
                                    // margin: '0',
                                    // display: "inline-flex",
                                    // outline: '0',
                                    padding: '12px 12px',
                                    // position: 'relative',
                                    // alignItems: 'center',
                                    // userSelect: 'none',
                                    // borderRadius: '0',
                                    // verticalAlign: 'middle',
                                    // -moz-appearance: 'none',
                                    // justifyContent: 'center',
                                    // textDecoration: 'none',
                                    // backgroundColor: 'transparent',
                                    // -webkit-appearance: 'none',
                                    // -webkit-tap-highlight-color: 'transparent',
                                }
                            }}
                            onClick={handleDropdownClick}
                            // onClose={handleDropdownClose}
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                    <React.Fragment>
                                        <span>Funding Plans</span>
                                        <IconButton sx={{ padding: '0px', color: 'var(--primary-color)' }}
                                            size="large"
                                            aria-label="more"
                                            id="dropdown-menu"
                                            aria-controls="dropdown-menu"
                                            aria-haspopup="true"
                                            onClick={handleDropdownClick}
                                        >
                                            <ArrowDropDownIcon />
                                        </IconButton>
                                        <Menu
                                            id="dropdown-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleDropdownClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'dropdown-menu',
                                            }}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            getContentAnchorEl={null}
                                            PaperProps={{
                                                style: {
                                                    marginTop: '81px',
                                                    marginLeft: '-31px'
                                                },
                                            }}
                                        >

                                            <MenuItem onClick={() => { setValue(7); handleDropdownClose(); }} style={{ fontSize: '14px', color: 'var(--primary-color)' }}>Current Funding Plan</MenuItem>
                                            <MenuItem onClick={() => { setValue(8); handleDropdownClose(); }} style={{ fontSize: '14px', color: 'var(--primary-color)' }}>Threshold Funding Plan</MenuItem>
                                            <MenuItem onClick={() => { setValue(9); handleDropdownClose(); }} style={{ fontSize: '14px', color: 'var(--primary-color)' }}> Full Funding Plan</MenuItem>
                                        </Menu>
                                    </React.Fragment>
                                </Box>
                            }
                        />

                        <Tab label="Component Details" {...a11yProps(3)}
                            sx={{
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                color: 'var(--primary-color)',
                                fontWeight: '600',
                                // padding : '12px 18px',

                                '&.Mui-selected': {
                                    // color: '#6688CC !important'
                                    color: 'var(--tertiary-color) ',
                                    // padding : '12px 18px'
                                },
                                '&.MuiButtonBase-root': {
                                    // color: 'inherit',
                                    // border: '0',
                                    // cursor: 'pointer',
                                    // margin: '0',
                                    // display: "inline-flex",
                                    // outline: '0',
                                    padding: '12px 12px',
                                    // position: 'relative',
                                    // alignItems: 'center',
                                    // userSelect: 'none',
                                    // borderRadius: '0',
                                    // verticalAlign: 'middle',
                                    // -moz-appearance: 'none',
                                    // justifyContent: 'center',/
                                    // textDecoration: 'none',
                                    // backgroundColor: 'transparent',
                                    // -webkit-appearance: 'none',
                                    // -webkit-tap-highlight-color: 'transparent',
                                }
                            }}
                        />
                        <Tab label="Expenditures" {...a11yProps(4)}
                            sx={{
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                color: 'var(--primary-color)',
                                fontWeight: '600',
                                // padding : '12px 18px',

                                '&.Mui-selected': {
                                    // color: '#6688CC !important'
                                    color: 'var(--tertiary-color) ',
                                    // padding : '12px 18px'

                                },
                                '&.MuiButtonBase-root': {
                                    // color: 'inherit',
                                    // border: '0',
                                    // cursor: 'pointer',
                                    // margin: '0',
                                    // display: "inline-flex",
                                    // outline: '0',
                                    padding: '12px 18px',
                                    // position: 'relative',
                                    // alignItems: 'center',
                                    // userSelect: 'none',
                                    // borderRadius: '0',
                                    // verticalAlign: 'middle',
                                    // -moz-appearance: 'none',
                                    // justifyContent: 'center',
                                    // textDecoration: 'none',
                                    // backgroundColor: 'transparent',
                                    // -webkit-appearance: 'none',
                                    // -webkit-tap-highlight-color: 'transparent',
                                }
                            }}
                        />
                        {/* <Tab label="Reports" {...a11yProps(5)}
                            sx={{
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                color: '#182978',
                                fontWeight: '600',

                                '&.Mui-selected': {
                                    color: '#6688CC !important'
                                }
                            }} /> */}
                        <Tab
                            sx={{
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                color: 'var(--primary-color)',
                                fontWeight: '600',
                                // padding : '12px 18px',


                                '&.Mui-selected': {
                                    // color: '#6688CC !important'
                                    color: 'var(--tertiary-color)',
                                    // padding : '12px 18px'

                                },
                                '&.MuiButtonBase-root': {
                                    // color: 'inherit',
                                    // border: '0',
                                    // cursor: 'pointer',
                                    // margin: '0',
                                    // display: "inline-flex",
                                    // outline: '0',
                                    padding: '12px 8px',
                                    // position: 'relative',
                                    // alignItems: 'center',
                                    // userSelect: 'none',
                                    // borderRadius: '0',
                                    // verticalAlign: 'middle',
                                    // -moz-appearance: 'none',
                                    // justifyContent: 'center',
                                    // textDecoration: 'none',
                                    // backgroundColor: 'transparent',
                                    // -webkit-appearance: 'none',
                                    // -webkit-tap-highlight-color: 'transparent',
                                }
                            }}
                            onClick={handleReportDropdownClick}
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <React.Fragment>
                                        <span>Reports</span>
                                        <IconButton sx={{ padding: '0px', color: 'var(--primary-color)' }}
                                            size="large"
                                            aria-label="more"
                                            id="dropdown-menu"
                                            aria-controls="dropdown-menu"
                                            aria-haspopup="true"
                                            onClick={handleReportDropdownClick}
                                        >
                                            <ArrowDropDownIcon />
                                        </IconButton>
                                        <Menu
                                            id="dropdown-menu"
                                            anchorEl={anchorE2}
                                            open={Boolean(anchorE2)}
                                            onClose={handleReportDropdownClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'dropdown-menu',
                                            }}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            getContentAnchorE2={null}
                                            PaperProps={{
                                                style: {
                                                    marginTop: '81px',
                                                    marginLeft: '-31px'
                                                },
                                            }}
                                        >

                                            <MenuItem onClick={() => { handleReportsClick() }} style={{ fontSize: '14px', color: 'var(--primary-color)', display: 'flex', justifyContent: 'space-between' }}>
                                                {reportloading && <CircularProgress size="1rem" />}
                                                Download Report
                                            </MenuItem>

                                        </Menu>
                                        <a ref={anchorRef} style={{ display: 'none' }} download />
                                    </React.Fragment>
                                </Box>
                            }
                        />


                        <Tab label="Account / Community Info" {...a11yProps(6)}
                            sx={{
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                color: 'var(--primary-color)',
                                fontWeight: '600',
                                // padding : '12px 18px',


                                '&.Mui-selected': {
                                    // color: '#6688CC !important'
                                    color: 'var(--tertiary-color) ',
                                    // padding : '12px 18px',

                                },

                                '&.MuiButtonBase-root': {
                                    // color: 'inherit',
                                    // border: '0',
                                    // cursor: 'pointer',
                                    // margin: '0',
                                    // display: "inline-flex",
                                    // outline: '0',
                                    padding: '12px 18px',
                                    // position: 'relative',
                                    // alignItems: 'center',
                                    // userSelect: 'none',
                                    // borderRadius: '0',
                                    // verticalAlign: 'middle',
                                    // -moz-appearance: 'none',
                                    // justifyContent: 'center',
                                    // textDecoration: 'none',
                                    // backgroundColor: 'transparent',
                                    // -webkit-appearance: 'none',
                                    // -webkit-tap-highlight-color: 'transparent',
                                }

                            }}
                        />


                    </Tabs>
                </div>

            </div>

            <TabPanel value={value} index={0}>
                <DashboardTabs setLoading={setLoading} loading={loading} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DataEntryTab setLoading={setLoading} loading={loading} />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
                item 3
            </TabPanel> */}
            <TabPanel value={value} index={3}>
                <ComponentDetailTab setLoading={setLoading} loading={loading} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <ExpenditureTab setLoading={setLoading} loading={loading} />
            </TabPanel>
            {/* <TabPanel value={value} index={5}>
                <Reports />
            </TabPanel> */}
            <TabPanel value={value} index={6}>
                <AccountInfo setLoading={setLoading} loading={loading} />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <CurrentFundingPlans setLoading={setLoading} loading={loading} />
            </TabPanel>
            <TabPanel value={value} index={8}>
                <ThresholdFundingPlan setLoading={setLoading} loading={loading} />
            </TabPanel>
            <TabPanel value={value} index={9}>
                <FullFundingPlan setLoading={setLoading} loading={loading} />
            </TabPanel>
            {/* <TabPanel value={value} index={10}>
                <Reports setLoading={setLoading} onClick={handleReportsClick} loading={loading}/>
            </TabPanel> */}
        </>
    );
}

export default HeaderTab;