const User = {
    INITIALPARAMETERLISTINGDATA: 'INITIALPARAMETERLISTINGDATA',
    INPEDITEDDATA:'INPEDITEDDATA',
    COMPONENTSLISTINGDATA:'COMPONENTSLISTINGDATA',
    COMEDITEDDATA:'COMEDITEDDATA',
    ACTIVESCENARIOID: 'ACTIVESCENARIOID',
    SATONEDATA: 'SATONEDATA',
    SATTWODATA: 'SATTWODATA',
    SATTHREEDATA: 'SATTHREEDATA',
    SATYEARDATA:'SATYEARDATA',
    UNITSVARIABLEDATA:'UNITSVARIABLEDATA',
    UNITSVARIABLEEDITEDDATA:'UNITSVARIABLEEDITEDDATA',
    LOANSANDEXPDATA:'LOANSANDEXPDATA',
    LOANSANDEXPEDITEDDATA:'LOANSANDEXPEDITEDDATA',
    MCEDATA:'MCEDATA',
    MCEEDITEDDATA:'MCEEDITEDDATA',
    CFPTABLEDATA: 'CFPTABLEDATA',
    CFPCHECKDATA: 'CFPCHECKDATA',
    CFPPERCENTDATA: 'CFPPERCENTDATA',
    CFPUNIFORMPERCENTDATA: 'CFPUNIFORMPERCENTDATA',
    CFPADVANCEDPARAMETERDATA: 'CFPADVANCEDPARAMETERDATA',
    CFPSELECTEDROWDATA: 'CFPSELECTEDROWDATA',
    CFPCUSTOMDATAVALUE: 'CFPCUSTOMDATAVALUE',
    TFPTABLEDATA: 'TFPTABLEDATA',
    TFPADVANCEPARAMETERDATA : 'TFPADVANCEPARAMETERDATA',
    TFPMINIMALENDINGDATA : 'TFPMINIMALENDINGDATA',
    TFPPERCENTCHANGEDATA: 'TFPPERCENTCHANGE',
    TFPSELECTEDROWDATA : 'TFPSELECTEDROWDATA',
    TFPDEFAULTVALUEDATA: 'TFPDDEFAULTVALUEDATA',
    TFPCUSTOMOBJDATA: 'TFPCUSTOMOBJDATA',
    FFPTABLEDATA: 'FFPTABLEDATA',
    FFPADVANCEPARAMETERDATA : 'FFPADVANCEPARAMETERDATA',
    FFPMINIMALENDINGDATA : 'FFPMINIMALENDINGDATA',
    FFPPERCENTCHANGEDATA: 'FFPPERCENTCHANGE',
    FFPSELECTEDROWDATA : 'FFPSELECTEDROWDATA',
    FFPMINIMALPERCENTDATA: 'FFPMINIMALPERCENTDATA',
    TFPMINENDINGBALANCEYEARDATA : 'TFPMINENDINGBALANCEYEARDATA',
    TFPSPECIALASSYEARDATA : 'TFPSPECIALASSYEARDATA',
    TFPFUNDINGPRIORITYDATA:'TFPFUNDINGPRIORITYDATA', 
    FFPMINENDINGBALANCEYEARDATA : 'FFPMINENDINGBALANCEYEARDATA',
    FFPSPECIALASSYEARDATA : 'FFPSPECIALASSYEARDATA',
    FFPFUNDINGPRIORITYDATA:'FFPFUNDINGPRIORITYDATA',
    FFPDEFAULTVALUEDATA: 'FFPDEFAULTVALUEDATA',
    FFPCUSTOMOBJDATA:'FFPCUSTOMOBJDATA',
    FFPCUSTOMOBJDATAMIN: 'FFPCUSTOMOBJDATAMIN',
    FFPCUSTOMOBJDATAMAX:'FFPCUSTOMOBJDATAMAX',
    LOGINTOKENDATA: 'LOGINTOKENDATA',
    ISAUTH : 'ISAUTH',
    ACCOUNTDATA : 'ACCOUNTDATA',
    COMMUNITYINFO:'COMMUNITYINFO',
    FULLFUNDING:'FULLFUNDING',
    THRESHOLDFUNDING:'THRESHOLDFUNDING',
    TFPCUSTOMOBJDATAMIN:'TFPCUSTOMOBJDATAMIN',
    TFPCUSTOMOBJDATAMAX:'TFPCUSTOMOBJDATAMAX',
    UNITRESERVE:'UNITRESERVE',
    UNITRESERVETABLECOUNT:'UNITRESERVETABLECOUNT',
    UNITRESERVEROWS:"UNITRESERVEROWS",
    UNITSVARIABLEROWS:"UNITSVARIABLEROWS",
    COMMUNITYID: "COMMUNITYID",
};
export default User;