import React, { useEffect } from 'react'
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { dispatchActiveScenarioId, dispatchCommunityId, dispatchComponentData, dispatchInitialParametersListing, dispatchLoansAndExpData, dispatchMCEData, dispatchUnitReserve, dispatchUnitsVariableData } from '../../../../redux/actions/scenarioManagementAction';

import { Accordion, AccordionSummary, AccordionDetails, Snackbar, Alert, CircularProgress } from '@mui/material';

// import CachedIcon from '@mui/icons-material/Cached';
import SaveIcon from '@mui/icons-material/Save';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import EditScenarios from '../../dataEntryModel/EditScenario';

import { copyScenarioTableListing, createAndUpdateComponentTable, createAndUpdateMCETable, createAndUpdateUnitsVariableTable, createAndUpdateloansAndExpTable, createNewScenarioTableListing, getAccountCommunityInfo, getCommunityInfoID, getComponentTableListing, getIntialParameterTableListing, getMCETableListing, getScenariosList, getUnitWiseReserveTableListing, getUnitsVariableTableListing, getloansAndExpTableListing, recordScenerios, refreshToken, updateScenerioActive, updateSpecialAssessmentTable, updateUnitWiseContribution } from '../../../../services/Services';

import '../scenarioManagementTable/ScenarioManagementTable.css';
import '../../../../assets/styles/style.css';
import 'handsontable/dist/handsontable.full.min.css';
import { updateInitialParameterTable } from '../../../../services/Services';
import UnitsTableUpdate from '../../dataEntryModel/UnitsTableUpdate';
import AddScenarios from '../../dataEntryModel/AddScenarios';
import { dispatchAccountData } from "../../../../redux/actions/accountInfoAction";
import { Button, Modal } from 'react-bootstrap';
import showNotification from '../../../common/Notification';
// import { toast } from 'react-toastify';

const ScenarioManagementTable = (props) => {

    //redux hooks
    const dispatch = useDispatch();
    const { inpEditedData, comEditedData, satOneData, satTwoData, satThreeData, unitsVariableEditedData,
        loansAndExpEditedData, mceEditedData, activeScenarioId, initialParameterListingData, unitreserve    //eslint-disable-line
        , unitreserverows, unitreservetablecount
    } = useSelector((state) => state.initialParameter);
    const CommunityDetails = useSelector((state) => state?.accountInfo?.email?.community_info);
    let satmergeData = [...satOneData, ...satTwoData, ...satThreeData];
    //useStates
    const [scenerioEditListing, setScenarioEditListing] = useState('');
    const [openAddScenario, setAddScenario] = useState(false);
    const [openEditScenario, setEditScenario] = useState(false);
    const [scenarioListingData, setScenarioListingData] = useState([]);
    const [scenarioItem, setScenarioItem] = useState('');
    const [copyScenerioChecked, setCopyScenerioChecked] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [copyScenerioSnackbar, setCopyScenerioSnackbar] = useState(false);
    const [newScenerioSnackbar, setNewScenerioSnackbar] = useState(false);
    const [scenarioDropDownActiveId, setscenarioDropDownActiveId] = useState(activeScenarioId);
    const [inpFormatedData, setInpFormatedData] = useState([]);
    const [componetsApiData, setComponetsApiData] = useState([]);
    const [unitReserveApiData, setUnitReserveApiData] = useState([]);  // eslint-disable-line
    const [openUnitModel, setOpenUnitsModel] = useState(false)
    const [alerttrue, SetAlerttrue] = useState(false)
    const [reserveContributionSum, setReserveContributionSum] = useState(null)
    const CommunityToken = '3f11a7eb-bf78-4ddc-8668-7252a5d4a5eb';
    let componentParamsData = comEditedData?.map((item) => {
        return (
            {
                'id': item[0],
                "category": item[1],
                "component_title": item[2],
                "description": item[3],
                "useful_life_year": item[4],
                "remaining_useful_life_years": item[5],
                "current_replacement_cost": item[6],
                "assessement": item[7],
                "fund_component": item[8],
                "notes": item[9],
                "scenario_id": scenarioDropDownActiveId
            }
        )
    })
    let unitsVariableParamsData = unitsVariableEditedData?.map((item) => {
        return (
            {
                'id': item[0],
                "unit": item[1],
                "building": item[2],
                "address": item[3],
                "square_footage": item[4],
                "percentage": item[5],
                "scenario_id": scenarioDropDownActiveId
            }
        )
    })


    let unitsWiseReserveContribution = unitreservetablecount?.map((item) => {
        return (
            {
                "id": item[3] ? item[3] : '',
                "current_yearly_reserve_contribution": item[1] ? item[1] : '0',
                "status": item[2] ? item[2] : "occupied",
                "unit": item[0] ? item[0] : 'Unit',
                "omit_vacant_units_reserve": unitreserverows?.Omit_button_enable ? unitreserverows?.Omit_button_enable : false,
                "scenario_id": item[4] ? item[4] : activeScenarioId,
            }
        )
    })

    let loansAndExpParamsData = loansAndExpEditedData?.map((item) => {
        return (
            {
                'id': item[0],
                "year": item[1],
                "amount_due": item[2],
                "description": item[3],
                "fund_component": item[4],
                "year_id": item[5],
                "scenario_id": scenarioDropDownActiveId,
            }
        )
    })

    let mceParamsData = mceEditedData?.map((item) => {
        return (
            {

                'id': item[0],
                "category": item[1],
                "component_title": item[2],
                "description": item[3],
                "monthly_replacement_cost": item[4],
                "notes": item[5],
                "scenario_id": scenarioDropDownActiveId,
            }
        )
    })


    //useEffect

    const auth_token = localStorage.getItem("token")
    useEffect(() => {
        if (auth_token)
            fetchCommunityInfoID();
    }, [auth_token])

    const [communityID, setCommunityID] = useState(null);
    useEffect(() => {
        communityID;
    }, [communityID])

    const fetchCommunityInfoID = () => {
        let params = { "community_uuid": CommunityToken }
        getCommunityInfoID(params).then((res) => {
            setCommunityID(res?.data?.community_id);
            dispatch(dispatchCommunityId(res?.data?.community_id))
            getAccountCommunityInfo(res?.data?.community_id).then((res) => {
                setPrimaryColor(res.data.community_info.primary_color);
                setSecondaryColor(res.data.community_info.secondary_color);
                setTertiaryColor(res.data.community_info.tertiary_color);
                dispatch(dispatchAccountData(res?.data));
            })
        }).catch((err) => {
            if (err?.response?.status == 401) {
                showNotification("danger", "Session expired, to continue please login again")
            }
        })
    }
    const loginRefreshToken = localStorage.getItem('refreshToken');
    const payload =
    {
        "refresh": loginRefreshToken
    }

    useEffect(() => {
        fetchScenarioListingData();
    }, [communityID])

    useEffect(() => {
        if (inpFormatedData != undefined) {
            let values = [...inpFormatedData];
            inpFormatedData?.map((item, index) => {
                let startformateDate = new Date(item.fiscal_year_start);
                let endformateDate = new Date(item.fiscal_year_end);
                values[index]['fiscal_year_start'] = startformateDate;
                values[index]['fiscal_year_end'] = endformateDate;
            })
            dispatch(dispatchInitialParametersListing(values));
        }

    }, [inpFormatedData])

    useEffect(() => {
        dispatch(dispatchComponentData(componetsApiData));
    }, [componetsApiData])



    //functions
    const handleScenario = () => {
        if (scenarioListingData?.length > 4) {
            setAddScenario(true);
        }
        else {
            if (copyScenerioChecked == false) {
                createNewScenarioTableListing(communityID).then((res) => {
                    setNewScenerioSnackbar(true)
                    setScenarioListingData([res?.data?.scenario_Management, ...scenarioListingData]);
                    return res;
                }).catch(() => {
                    showNotification("danger", "Scenario not created")
                })
            }
            else {
                const params = {
                    "copy": copyScenerioChecked
                }

                copyScenarioTableListing(params, communityID).then((res) => {
                    setCopyScenerioSnackbar(true);
                    fetchScenarioListingData();
                    return res;
                }).catch(() => {
                    showNotification("danger", "Copy Scenario not created")
                })
            }
        }
    }

    const handleEditScenarioTable = (item) => {
        setScenarioItem(item)
        recordScenerios(item?.id).then((res) => {
            setScenarioEditListing(res?.data);
        }).catch(() => {
            showNotification("danger", "Unable to edit scenario please try again")
        })
        setTimeout(() => {
            setEditScenario(true);
        }, 500);
    }

    const fetchScenarioListingData = async () => {
        if (communityID) {
            getScenariosList(communityID).then((res) => {
                props.setLoading(true);
                setScenarioListingData(res?.data);
                dispatch(dispatchActiveScenarioId(scenarioDropDownActiveId == '' ? res?.data[0]?.id : scenarioDropDownActiveId));
                props.setLoading(false);

                getIntialParameterTableListing(scenarioDropDownActiveId == '' ? res?.data[0]?.id : scenarioDropDownActiveId).then((res) => {
                    setInpFormatedData(res?.data?.data);
                })
                getUnitWiseReserveTableListing(scenarioDropDownActiveId == '' ? res?.data[0]?.id : scenarioDropDownActiveId).then((res) => {
                    setUnitReserveApiData(res?.data)
                    dispatch(dispatchUnitReserve(res?.data));
                })

                getUnitsVariableTableListing(scenarioDropDownActiveId == '' ? res?.data[0]?.id : scenarioDropDownActiveId).then((res) => {
                    dispatch(dispatchUnitsVariableData(res?.data))
                })

                getComponentTableListing(scenarioDropDownActiveId == '' ? res?.data[0]?.id : scenarioDropDownActiveId).then((res) => {
                    setComponetsApiData(res?.data?.data);
                }).catch((err) => {
                    showNotification("danger", "Component Table Data is not loading", err)
                })


            }).catch((err) => {
                props.setLoading(true);
                if (err?.response?.status == 401) {
                    refreshToken(payload).then((res) => {
                        localStorage.setItem('token', res.data.access);
                    })
                        .catch((err) => {
                            showNotification("danger", "Session Expired, please login again to continue", err)
                        })
                }
            })
        }


    };

    const handleScenerioActive = (e) => {
        setscenarioDropDownActiveId(e?.target?.value);
        dispatch(dispatchActiveScenarioId(e?.target?.value));
        updateScenerioActive(e?.target?.value).then((res) => {
            return res;
        }).catch((err) => {
            showNotification("danger", "Getting error while loading the resources", err)
        })

        fetchIntialParameterList(e?.target?.value);
        fetchUnitWiseReserveContribution(e?.target?.value)
        fetchComponentsList(e?.target?.value);
        fetchUnitsVariableList(e?.target?.value);
        fetchLoansAndExpenditureList(e?.target?.value);
        fetchMCEList(e?.target?.value)

    }

    let errors = [];
    const fetchIntialParameterList = async (scenarioActiveId) => {
        getIntialParameterTableListing(scenarioActiveId).then((res) => {
            setInpFormatedData(res?.data?.data);
        }).catch((err) => {
            errors.push(err);
        })
    };

    const fetchComponentsList = async (scenarioActiveId) => {
        getComponentTableListing(scenarioActiveId).then((res) => {
            setComponetsApiData(res?.data?.data);
        }).catch((err) => {
            errors.push(err);
        })

    }
    const fetchUnitWiseReserveContribution = async (scenarioActiveId) => {
        getUnitWiseReserveTableListing(scenarioActiveId).then((res) => {
            dispatch(dispatchUnitReserve(res?.data));
        }).catch((err) => {
            errors.push(err);
        })

    }

    const fetchUnitsVariableList = async (scenarioActiveId) => {
        getUnitsVariableTableListing(scenarioActiveId).then((res) => {
            dispatch(dispatchUnitsVariableData(res?.data))
        }).catch((err) => {
            errors.push(err);
        })

    }

    const fetchLoansAndExpenditureList = async (scenarioActiveId) => {
        getloansAndExpTableListing(scenarioActiveId).then((res) => {
            dispatch(dispatchLoansAndExpData(res?.data))
        }).catch((err) => {
            errors.push(err);
        })

    }
    const fetchMCEList = async (scenarioActiveId) => {
        getMCETableListing(scenarioActiveId).then((res) => {
            dispatch(dispatchMCEData(res.data));
            // setComponetsApiData(res?.data?.data);
        }).catch((err) => {
            errors.push(err);
            if (errors.length > 0) {
                showNotification("danger", "Getting error while loading the resources")
            }
        })

    }

    const handleCopyScenerio = (e) => {
        setCopyScenerioChecked(e.target.checked);
    }

    const handleClick = () => {
        setExpanded(!expanded);
    };

    const handleCloseSnackbar = () => {
        setCopyScenerioSnackbar(false);
        setNewScenerioSnackbar(false);

    };

    const handleSaveAllChanges = () => {
        const mappedArray = unitreservetablecount.map((item) => {
            const contribution = parseFloat(item[1]);
            return isNaN(contribution) ? 0 : contribution;
        });
        const SumOfReserveContribution = mappedArray.reduce((acc, currentValue) => acc + currentValue, 0);
        setReserveContributionSum(SumOfReserveContribution)
        if (inpEditedData?.length != 0 && unitreserverows?.number_of_rows == inpEditedData[0][7] && SumOfReserveContribution == inpEditedData[0][4]) {

            let saveErrors = [];
            let saveAlert = [];

            if (inpEditedData?.length != 0) {
                let params =
                {
                    // "id": inpEditedData[0][0],
                    "fiscal_year_start": inpEditedData[0][1],
                    "fiscal_year_end": inpEditedData[0][2],
                    "starting_balance": inpEditedData[0][3],
                    "current_yearly_reserve_contribution": inpEditedData[0][4],
                    "proposed_yearly_reserve_contribution": inpEditedData[0][5],
                    "inflation": inpEditedData[0][6],
                    "number_of_units": inpEditedData[0][7],
                    "default_interest_rate": inpEditedData[0][8],
                    "total_assessment_amount_per_month": inpEditedData[0][10],
                    'delinquent_discount': inpEditedData[0][9],
                    'minus_delinquent_payments': initialParameterListingData[0]?.minus_delinquent_payments,

                }

                updateInitialParameterTable(params, activeScenarioId).then((res) => {
                    fetchIntialParameterList(activeScenarioId)
                    saveAlert.push(res);
                    return res;
                }).catch((err) => {
                    saveErrors.push(err)
                })
            }


            let satParams = {
                data: satmergeData
            }

            updateSpecialAssessmentTable(satParams, activeScenarioId).then((res) => {
                saveAlert.push(res);
                return res;
            }).catch((err) => {
                saveErrors.push(err);
            })


            let comParams = {
                data: componentParamsData
            }

            createAndUpdateComponentTable(comParams, activeScenarioId).then((res) => {
                fetchComponentsList(activeScenarioId);
                saveAlert.push(res);
                return res;
            }).catch((err) => {
                saveErrors.push(err);

            })

            if (props.datachange == true) {
                let unitParam = {
                    data: unitsWiseReserveContribution
                }
                updateUnitWiseContribution(unitParam, activeScenarioId).then((res) => {
                    fetchUnitWiseReserveContribution(activeScenarioId);
                    fetchUnitsVariableList(activeScenarioId);
                    saveAlert.push(res);
                    return res;
                }).catch((err) => {
                    saveErrors.push(err);

                })
            }
            else {

                let unitParam = {
                    data: unitsWiseReserveContribution
                }


                updateUnitWiseContribution(unitParam, activeScenarioId).then((res) => {
                    fetchUnitWiseReserveContribution(activeScenarioId);
                    saveAlert.push(res);
                    return res;
                }).catch((err) => {
                    saveErrors.push(err);

                })

                let unitsVariableParams = {
                    data: unitsVariableParamsData
                }
                const getIdOfUV = unitsVariableParamsData?.filter((item) => item.id === '');
                console.log(unitsVariableParamsData, "unitsVariableParamsData")
                if (getIdOfUV != undefined) {
                    if (getIdOfUV[0]?.id == '') {
                        setOpenUnitsModel(true);
                    }
                    else {
                        createAndUpdateUnitsVariableTable(unitsVariableParams, activeScenarioId).then((res) => {
                            fetchUnitsVariableList(activeScenarioId);
                            return res;
                        }).catch((err) => {
                            saveErrors.push(err);

                        })
                    }
                }
            }

            let loansAndExpParams = {
                data: loansAndExpParamsData
            }

            createAndUpdateloansAndExpTable(loansAndExpParams, activeScenarioId).then((res) => {
                fetchLoansAndExpenditureList(activeScenarioId)
                saveAlert.push(res);
                return res;
            }).catch((err) => {
                saveErrors.push(err);
            })


            let mceParams = {
                data: mceParamsData
            }

            createAndUpdateMCETable(mceParams, activeScenarioId).then((res) => {
                fetchMCEList(activeScenarioId)
                saveAlert.push(res);
                if (saveAlert.length <= 6) {
                    showNotification("success", "Updated Successfully !!")
                }
                return res;
            }).catch((err) => {
                saveErrors.push(err);
                if (saveErrors.length > 0) {
                    showNotification("success", "Getting issues while saving the changes")
                }
            })
        }
        else {
            if (inpEditedData?.length != 0 && SumOfReserveContribution != inpEditedData[0][4]) {
                SetAlerttrue(true)
            }
            else if (inpEditedData?.length != 0 && unitreserverows?.number_of_rows != inpEditedData[0][7]) {
                showNotification("danger", "Number of Units in Initial Parameter must be equal to the Number of Rows in Unit Wise Reserve Contribution")

            }
            else {
                showNotification("danger", "Check Initial Parameters Values")
            }
        }

    }

    const [primaryColor, setPrimaryColor] = useState("0f100f");  //eslint-disable-line
    const [secondaryColor, setSecondaryColor] = useState("#ffffff"); //eslint-disable-line
    const [tertiaryColor, setTertiaryColor] = useState("#e6e6fa"); //eslint-disable-line

    useEffect(() => {
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--tertiary-color', tertiaryColor);
    }, [primaryColor, secondaryColor, tertiaryColor])


    // useEffect(() => {
    //     // getAccountCommunityInfo(communityID).then((res) => {
    //     //     setPrimaryColor(res.data.community_info.primary_color);
    //     //     setSecondaryColor(res.data.community_info.secondary_color);
    //     //     setTertiaryColor(res.data.community_info.tertiary_color);
    //     //     dispatch(dispatchAccountData(res?.data));
    //     // })
    // }, [communityID])
    const handleCloseModal = () => {
        SetAlerttrue(false)
    }

    return (
        <>
            {props.loading && <CircularProgress className='circular_loader' />}
            <div className='main_div_scenario'>
                {
                    alerttrue &&
                    <Modal show={alerttrue} onHide={handleCloseModal}>
                        <Modal.Body>The Sum of Reserve Contribution {reserveContributionSum} must be equal to the Current yearly reserve contribution {inpEditedData[0][4]} in Initial Parameter</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleCloseModal}>
                                Continue Editing
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
                {!props.loading &&
                    <>
                        <div className='row' style={{ padding: '30px 0px' }}>
                            <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8' style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='scenarios_inner_div'>
                                    <p className="scenarios_title">Scenarios Management</p>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <div className='row' style={{ alignItems: 'end' }}>
                                    <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                                        <label htmlFor="exampleFormControlInput1" style={{ fontSize: '14px' }} className="form-label">Select Active Scenario</label>
                                        <select className="form-select" aria-label="Default select example" style={{ fontSize: '14px' }} onChange={handleScenerioActive} value={scenarioDropDownActiveId}>
                                            {scenarioListingData?.map((item, index) => {
                                                return (
                                                    (item?.can_read || item?.can_write) &&
                                                    <option key={index} style={{ fontSize: '14px' }} value={item?.id}>{item?.scenario_name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                        {/* <div className='d-flex active_scenario_btn' style={{ gap: '4%' }}>
                                            <div>
                                                <button type="button" className="scenario_button">
                                                    <CachedIcon style={{ fontSize: '20px', margin: '2px 2px' }} />
                                                    Update Plans
                                                </button>
                                            </div> */}
                                        <div>
                                            <button type="button" className="scenario_button" onClick={handleSaveAllChanges}>
                                                <SaveIcon style={{ fontSize: '20px', margin: '2px 2px' }} />
                                                Save Changes
                                            </button>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                    <UnitsTableUpdate setOpenUnitsModel={setOpenUnitsModel} openUnitModel={openUnitModel} unitsVariableParamsData={unitsVariableParamsData} fetchUnitsVariableLis={fetchUnitsVariableList} scenarioDropDownActiveId={scenarioDropDownActiveId} fetchIntialParameterList={fetchIntialParameterList} />
                                </div>
                            </div>
                        </div>

                        <div >
                            <Accordion className='scenario_container buttonright' expanded={expanded}
                                sx={{
                                    my: '1rem',
                                    backgroundColor: '#FAFAFA '
                                }}>
                                <AccordionSummary expandIcon={expanded ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={handleClick}>
                                    <div className='community-div' style={{ fontSize: "18px", color: "var(--primary-color)" }}>
                                        Community: <span>{CommunityDetails?.community_name}</span>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="row">
                                        <div
                                        // className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3"
                                        >
                                            <div className='d-flex' style={{ padding: '0px 0px', gap: '30px' }}>
                                                <div>
                                                    <div className='update_btn'>
                                                        <button type="button" className="scenario_button" onClick={handleScenario} disabled={scenarioListingData?.length>4} style ={{backgroundColor:scenarioListingData?.length>4 && 'gray'}}><AddIcon style={{ fontSize: '20px', margin: '2px 2px' }} />Add New Scenarios</button>
                                                        <AddScenarios openAddScenario={openAddScenario} setAddScenario={setAddScenario} />
                                                    </div>
                                                    {scenarioListingData?.length >4 && (
                                                        <div><p style={{color: 'red'}}>Maximum allowed Scenario {scenarioListingData?.length}</p></div>
                                                    )}
                                                </div>
                                                <div className='scenarios_text' style={{ paddingTop: '7px', justifyContent: 'center' }}>
                                                    {/* <input type='checkbox' onChange={handleCopyScenerio} /> */}
                                                    <span style={{ padding: '0px 10px' }}>make a copy of the current active scenario :</span>
                                                    <input type='checkbox' onChange={handleCopyScenerio} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <table
                                                className="table table_font" style={{ width: '100%', marginTop: '28px' }}
                                            >
                                                <thead className="table_1">
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                        <th scope="col">Scenario Name</th>
                                                        <th scope="col">Notes</th>
                                                        <th scope="col">Last Saved Date</th>
                                                        <th scope="col">Last Saved By</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {scenarioListingData?.map((item, index) => {
                                                        return (
                                                            <>
                                                                {item?.can_read && (
                                                                    <tr key={index}>
                                                                        <th className='updatebtn_re' scope="row">{index + 1}</th>
                                                                        <th className='update_btn updatebtn_re'>
                                                                            {item?.can_write &&
                                                                                <button type="button" className="scenario_button" onClick={() => handleEditScenarioTable(item)}><EditIcon className='icon_1' />
                                                                                </button>}</th>
                                                                        <td>{item?.scenario_name}</td>
                                                                        <td>{item?.notes}</td>
                                                                        {/* <td>{startFormatedDate}</td> */}
                                                                        <td>{item?.last_saved_date}</td>
                                                                        <td>{item?.last_saved_by_email}</td>
                                                                        <td><span>{item?.status == true ? (<FontAwesomeIcon icon={faLockOpen} style={{ color: 'rgb(60, 118, 61)' }} />) : <FontAwesomeIcon icon={faLock} style={{ color: 'rgb(169, 68, 66)' }} />}</span></td>
                                                                    </tr>
                                                                )}
                                                            </>
                                                        )
                                                    })}
                                                    <EditScenarios openEditScenario={openEditScenario} setEditScenario={setEditScenario} scenarioItem={scenarioItem} fetchScenarioListingData={fetchScenarioListingData} scenerioEditListing={scenerioEditListing} />
                                                </tbody>
                                                <Snackbar
                                                    open={copyScenerioSnackbar}
                                                    onClose={handleCloseSnackbar}
                                                >
                                                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                                                        Scenerio Copied Succesfully.
                                                    </Alert>
                                                </Snackbar>
                                                <Snackbar
                                                    open={newScenerioSnackbar}
                                                    onClose={handleCloseSnackbar}
                                                >
                                                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                                                        New Scenerio Created Succesfully.
                                                    </Alert>
                                                </Snackbar>
                                            </table>

                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion></div>
                    </>

                }
            </div>

        </>
    );
}

export default ScenarioManagementTable;