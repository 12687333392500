import React, { useState } from "react";
import "./otp.css";
import { Link, useNavigate } from "react-router-dom";
import { verifyOtp } from "../../services/Services";

function OTP() {

    const [email,setEmail] = useState("");
    const [emailError, setEmailError] = useState("")
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const navigate = useNavigate();
    const handleSubmitClick = (e) => {
        e.preventDefault()
        let payload = {
            "email" : email,
            "otp" : parseInt(otp)
        }
        navigate("/changePassword");
        verifyOtp(payload).then((res) => {
            alert(res.data.message)
            navigate("/changePassword");
        }).catch((err) => {
            alert(err.response.data.error)            
        })
    }
    const handleEmailChange = (e) => {
       const EMAIL = e.target.value;
       setEmail(EMAIL);
       validateEmail(EMAIL);
    }
    const handleOTPChange = (e) => {
       const OTP = e.target.value;
       setOtp(OTP);
       validateOTP(OTP);
    }
    const validateEmail = (_email) => {
        if (
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(_email) &&
            _email !== ""
        ){
            setEmailError("");
        }else{
            setEmailError("Enter the valid email");
        }
    }
    const validateOTP = (_otp) => {
        if(_otp.length > 0){
            setOtpError("")
        }else{
            setOtpError("Please enter the OTP");
        }
    }

    return (
        <>
            <div className="main_login">
                <div className="inner_div">
                    <div style={{ padding: '20px' }}>
                        <div className="login_header">
                            <h3>Community Planning Header</h3>
                        </div>
                        <div>
                            <form action="" className="login_form" >
                                <div className="input_div">
                                    <input type="email" name="email" id="email" onChange={handleEmailChange} required placeholder="Enter email" />
                                </div>
                                { emailError ? <div className="error">{emailError}</div> : ""}
                                <div className="input_div"> 
                                    <input type="text" name="otp" id="otp" onChange={handleOTPChange}required placeholder="Enter OTP"/>
                                </div>
                                { otpError ? <div className="error">{otpError}</div> : ""}
                                <div className="login_btn">
                                    <button className='form-control btn' onClick={handleSubmitClick}>Submit</button>
                                </div>
                            </form>
                            <div style={{ display: 'flex', justifyContent: 'right', margin: '10px' }}>
                                <Link to={"/"}>Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OTP;