import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { loginUser } from "../../services/Services";
import { dispatchAuthentication, dispatchLogInToken } from "../../redux/actions/logInAction";

import './LogInPage.css';
import { Typography } from "@material-ui/core";
import Alert from '@mui/material/Alert';
// import { dispatchAccountData } from "../../redux/actions/accountInfoAction";


const LogInPage = () => {
    //usestates
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    //redux hook
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const handleEmail = (e) => {
        let email = e.target.value
        setEmail(email != null ? email : '');
        validateEmail(email)
        // dispatch(dispatchAccountData(email));
    }

    const handlePassword = (e) => {
        let pass = e.target.value;
        setPassword(pass != null ? pass : 0);
        validatePassword(pass)
    }

    const [emailValid, setEmailValid] = useState(null);
    const [passwordValid, setPasswordValid] = useState(null);
    const [showAlert, setShowAlert] = useState(null);
    const validateEmail = (_email) => {
        if (
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(_email) &&
            _email !== ""
        ) {
            setEmailValid(true);
            return true;
        }
        setEmailValid(false);
        return false;
    }
    const validatePassword = (_password) => {
        if (_password !== "" && _password.length >= 5) {
            setPasswordValid(true);
            return true;
        }
        setPasswordValid(false);
        return false;
    };

    useEffect(() => {
        if (isLoggedIn == true) {
            dispatch(dispatchAuthentication(isLoggedIn));
        }
    }, [isLoggedIn])

    const alertContent = (name) => (
        <Typography variant="body2" color="white">
            {name}
        </Typography>
    );
    const handleSubmit = (e) => {
        if (validateEmail(email) && validatePassword(password)) {
            e.preventDefault();

            let payload = {
                "email": email,
                "password": parseInt(password)
            }

            loginUser(payload).then((res) => {
                setIsLoggedIn(true);
                localStorage.setItem('token', res.data.access);
                localStorage.setItem('refreshToken', res.data.refresh);
                localStorage.setItem('userEmail', email)
                sessionStorage.setItem('userEmail', email)
                sessionStorage.setItem('refreshToken', res.data.refresh)
                sessionStorage.setItem('token', res.data.access)
                // console.log("LOGIN RESPONSE",res.data);
                dispatch(dispatchLogInToken(res.data));
                // dispatch(dispatchAuthentication(isLoggedIn));

                //  window.location.reload();
                navigate("/dashboard");
            }).catch((err) => {
                alert("login failed");
                setEmail('');
                setPassword('');
                if (err.response.status == 400) {
                    setShowAlert("Invalid Credentials");
                }
            })
        }
    }

    // const handleRegisterClick = () => {
    //     navigate("../registrationPage");
    // }

    return (
        <>
            <div className="main_login">

                <div className="inner_div">
                    {showAlert && (
                        <Alert
                            severity="error"
                            dismissible
                            onClick={() => {
                                setShowAlert(null);
                            }}
                        >
                            {alertContent(showAlert)}
                        </Alert>
                    )}
                    <div style={{ padding: '20px' }}>
                        <div className="login_header">
                            <h3>Community Planning Application</h3>
                        </div>
                        <div>
                            <form action="" className="login_form" onSubmit={handleSubmit}>
                                <div className="input_div">
                                    <i className="fa fa-envelope"></i>
                                    <input type="email" name="email" id="email" value={email} placeholder="UserId" onChange={handleEmail} required />
                                </div>
                                {/* <Input className='input_div' type="email" error={emailValid == null ? false : !emailValid} fullWidth name="email" id="email" value={email} placeholder="UserId" onChange={handleEmail} required ><Email /></Input> */}
                                {emailValid === false && <div className="error">Invalid email address</div>}
                                <div className="input_div">
                                    <i className="fa fa-key"></i>
                                    <input type="password" name="pass" id="pass" value={password} placeholder="Password" onChange={handlePassword} required />
                                </div>
                                {passwordValid === false && <div className="error">Password must contain 5 letter or more</div>}

                                <div style={{display :'flex', justifyContent:'right', margin:'10px'}}>
                                    <Link to={"../resetPassword"}>Change Password</Link>
                                </div>
                                <div className="login_btn">
                                    <button type="submit" className='form-control btn' >LOG IN <ArrowForwardIosIcon sx={{ color: '#fff', fontSize: '18px' }} /></button>
                                </div>
                                <div style={{display :'flex', justifyContent: 'center',margin:'10px'}}>
                                    <Link to={"../registrationPage"}>Register Yourself ?</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LogInPage;