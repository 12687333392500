import React from "react"
// import Dashboard from "./pages/dashboard/Dashboard";
import Dashboard from "../../pages/dashboard/Dashboard"
import Permission from "../PermissionDenied/permission";
const PrivateRoute = () => {
    const token = localStorage.getItem("token")
    if (!token) {
        return <Permission />  
    }

    return <Dashboard />
}

export default PrivateRoute