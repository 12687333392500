import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { TablePagination, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    table: {
        '& th, & td': {
            padding: '3px 3px',
        },
        verticalAlign: 'middle',
        fontSize: '13px'
    },
    
    oddRows: {
        backgroundColor: '#f9f9f9',
    },
    evenRows: {
        backgroundColor: '#fff',
    },
    hoverRow: {
        '&:hover': {
            backgroundColor: '#f9f9f9'
        }
    },
    rowSelect: {
        backgroundColor: '#aab7d1'
    },
    bodyRow: {
        borderBottom: '1px solid #ddd !important'
    },

    tableCell: {
        fontSize: '14px !important',
        fontWeight: '600 !important',
    },
    tableHeader: {
        borderBottom: '2px solid black',
        borderTop: '1px solid black',
    },
    searchBody: {
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'end'
    },
    searchBar: {
        width: '20%',
        height: '40px',
        marginTop: '10px'
    }
})


const CDFundDistributionTable = (props) => {

    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedRows, setSelectedRows] = useState('');

    const classes = useStyles();

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectedRows = (index) => {
        if (selectedRows?.includes(index)) {
            setSelectedRows(selectedRows?.filter(data => data !== index));
            // dispatch(dispatchCFPSelectedRowData(selectedRows.filter(data => data !== index)));
        } else {
            setSelectedRows([...selectedRows, index]);
            // dispatch(dispatchCFPSelectedRowData([...selectedRows, index]));
        }
    };

    const sortedData = [...props.FundDistributionTable]?.sort((a, b) => {
        if (orderBy) {
            if (order === 'asc') {
                return a[orderBy] > b[orderBy] ? 1 : -1;
            } else {
                return a[orderBy] < b[orderBy] ? 1 : -1;
            }
        } else {
            return 0;
        }
    });
    const paginatedData = sortedData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const filteredData = paginatedData?.filter((item) => item[props?.tableKeys[0]?.key]?.toLowerCase()?.includes(props.searchValue?.toLowerCase())
    );
    return (
        <>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.tableHeader}>
                            {props?.tableKeys?.map((item, index) => (
                                <TableCell key={index} className={classes.tableCell} >
                                    <TableSortLabel active={orderBy == item.key} direction={orderBy == item.key ? order : 'asc'} onClick={() => handleSort(item.key)}>
                                        {item.value}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData?.map((row, index) => (
                            <TableRow onClick={() => handleSelectedRows(index)} className={`${index % 2 == 0 ? classes.evenRows : classes.oddRows} ${selectedRows?.includes(index) ? '' : classes.hoverRow} ${selectedRows?.includes(index) ? classes.rowSelect : ''} ${classes.bodyRow}`} key={index}>
                                <TableCell>{row[props?.tableKeys[0]?.key]}</TableCell>
                                <TableCell>{row[props?.tableKeys[1]?.key]}</TableCell>
                                <TableCell>{row[props?.tableKeys[2]?.key]}</TableCell>
                                <TableCell>{row[props?.tableKeys[3]?.key]}</TableCell>
                                <TableCell>{row[props?.tableKeys[4]?.key]}</TableCell>
                                <TableCell>{row[props?.tableKeys[5]?.key]}</TableCell>
                                <TableCell>{row[props?.tableKeys[6]?.key]}</TableCell>
                                <TableCell>{row[props?.tableKeys[7]?.key]}</TableCell>

                            </TableRow>
                        ))}


                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={props?.FundDistributionTable?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
        </>
    );
}

export default CDFundDistributionTable;