import React, {  useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import ReactApexChart from "react-apexcharts";

const PercentFunded = (props) => {
    const [expanded, setExpanded] = useState(false);

    const options = {
        chart: {
            type: 'line',
        },
        xaxis: {
            categories: props?.percentFundedData[0]?.values?.map(item => item?.category),
        },
        stroke: {
          width: [3, 3, 3],                       // Customize the line width for each series
        },
        colors: ['#d42020', '#0f913c', '#1338a8'],
        markers: {
          size: 4,
          shape: 'circle',
          radius: 2,
          strokeWidth: 2,
        },
    };

    const series = props?.percentFundedData?.map(seriesData => ({
        name: seriesData.name,
        data: seriesData?.values?.map(item => ((item.value)/6)?.toFixed(0)),
    }));

    const handleClick = () => {
        setExpanded(!expanded);
    }


    return (
        <>
            <div className="main_div_data_entry_tables buttonright">
                <Accordion expanded={expanded} style={{  borderTop: '3px solid var(--primary-color)'}} >
                    <AccordionSummary style={{color:'var(--primary-color)'}} expandIcon={expanded ? <RemoveIcon style={{ color: 'var(--primary-color)' }} /> : <AddIcon style={{ color: 'var(--primary-color)' }} />} onClick={handleClick}>
                        Percent Funded (%)
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="line"
                            height={350}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}

export default PercentFunded;