import User from "../../configurations/Constant";

export const dispatchInitialParametersListing = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.INITIALPARAMETERLISTINGDATA,
      initialParameterListingData: json,
    })
  } catch (e) {
    dispatch({
      type: User.INITIALPARAMETERLISTINGDATA,
      initialParameterListingData: [],
    });
  }
}

export const dispatchINPEditedData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.INPEDITEDDATA,
      inpEditedData: json,
    })
  } catch (e) {
    dispatch({
      type: User.INPEDITEDDATA,
      inpEditedData: [],
    });
  }
}

export const dispatchComponentData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.COMPONENTSLISTINGDATA,
      componentListingData: json,
    })
  } catch (e) {
    dispatch({
      type: User.COMPONENTSLISTINGDATA,
      componentListingData: [],
    });
  }
}


export const dispatchComponentEditedData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.COMEDITEDDATA,
      comEditedData: json,
    })
  } catch (e) {
    dispatch({
      type: User.COMEDITEDDATA,
      comEditedData: [],
    });
  }
}

export const dispatchActiveScenarioId = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.ACTIVESCENARIOID,
      activeScenarioId: json,
    })
  } catch (e) {
    dispatch({
      type: User.ACTIVESCENARIOID,
      activeScenarioId: '',
    });
  }
}

export const dispatchSATOneData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.SATONEDATA,
      satOneData: json,
    })
  } catch (e) {
    dispatch({
      type: User.SATONEDATA,
      satOneData: [],
    });
  }
}

export const dispatchSATTwoData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.SATTWODATA,
      satTwoData: json,
    })
  } catch (e) {
    dispatch({
      type: User.SATTWODATA,
      satTwoData: [],
    });
  }
}

export const dispatchSATThreeData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.SATTHREEDATA,
      satThreeData: json,
    })
  } catch (e) {
    dispatch({
      type: User.SATTHREEDATA,
      satThreeData: [],
    });
  }
}

export const dispatchSATYearData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.SATYEARDATA,
      satYearData: json,
    })
  } catch (e) {
    dispatch({
      type: User.SATYEARDATA,
      satYearData: [],
    });
  }
}

export const dispatchUnitsVariableData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.UNITSVARIABLEDATA,
      unitsVariableData: json,
    })
  } catch (e) {
    dispatch({
      type: User.UNITSVARIABLEDATA,
      unitsVariableData: [],
    });
  }
}

export const dispatchUnitsVariableEditedData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.UNITSVARIABLEEDITEDDATA,
      unitsVariableEditedData: json,
    })
  } catch (e) {
    dispatch({
      type: User.UNITSVARIABLEEDITEDDATA,
      unitsVariableEditedData: [],
    });
  }
}

export const dispatchUnitsVariableRows = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.UNITSVARIABLEROWS,
      unitvariablerows: json,
    })
  } catch (e) {
    dispatch({
      type: User.UNITSVARIABLEROWS,
      unitvariablerows: {},
    });
  }
}

export const dispatchLoansAndExpData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.LOANSANDEXPDATA,
      loansAndExpData: json,
    })
  } catch (e) {
    dispatch({
      type: User.LOANSANDEXPDATA,
      loansAndExpData: [],
    });
  }
}

export const dispatchLoansAndExpEditedData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.LOANSANDEXPEDITEDDATA,
      loansAndExpEditedData: json,
    })
  } catch (e) {
    dispatch({
      type: User.LOANSANDEXPEDITEDDATA,
      loansAndExpEditedData: [],
    });
  }
}

export const dispatchMCEData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.MCEDATA,
      mceData: json,
    })
  } catch (e) {
    dispatch({
      type: User.MCEDATA,
      mceData: [],
    });
  }
}

export const dispatchMCEEditedData = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.MCEEDITEDDATA,
      mceEditedData: json,
    })
  } catch (e) {
    dispatch({
      type: User.MCEEDITEDDATA,
      mceEditedData: [],
    });
  }
}

export const dispatchUnitReserve = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.UNITRESERVE,
      unitreserve: json,
    })
  } catch (e) {
    dispatch({
      type: User.UNITRESERVE,
      unitreserve: [],
    });
  }
}

export const dispatchUnitReserveTableCount = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.UNITRESERVETABLECOUNT,
      unitreservetablecount: json,
    })
  } catch (e) {
    dispatch({
      type: User.UNITRESERVETABLECOUNT,
      unitreservetablecount: [],
    });
  }
}

export const dispatchUnitReserveRows = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.UNITRESERVEROWS,
      unitreserverows: json,
    })
  } catch (e) {
    dispatch({
      type: User.UNITRESERVEROWS,
      unitreserverows: {
        "number_of_rows": '',
        "Omit_button_enable": '',
        "new_data":[],
        "index":''
      },
    });
  }
}

export const dispatchCommunityId = (json) => async (dispatch) => {
  try {
    dispatch({
      type: User.COMMUNITYID,
      community_id: json,
    })
  } catch (e) {
    dispatch({
      type: User.COMMUNITYID,
      community_id: '',
    });
  }
}