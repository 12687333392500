import React, { useRef, useState } from "react";

// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import {Typography, Tab, Tabs, Box} from '@mui/material';
// import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { HotTable } from "@handsontable/react";
import { Slider } from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const FFPAdvancedFundingParameters = (props) => {
    const [value, setValue] = useState(0);
    const hotTableComponent = useRef(null);
    const percentageChange = ['Uniform', 'Custom'];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="AFP_main_div">
                <h3>Advanced Funding Parameters</h3>

                <div className="AFP_second_input">
                    <label>Minimal Ending balance to Expenditures Ratio</label>
                    <input type="number"  onChange={props.handleEndingBalance} value={props.endingBalance !==0 ? props?.endingBalance: props?.mindata} step="0.01" min='0'/>
                </div>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Reserve Contribution" {...a11yProps(0)}
                        sx={{
                            border: '1px solid grey',
                            borderBottom: 'none',
                            borderRadius: '5%',
                            marginRight: "2px",
                            textTransform: 'none !important',
                            color: 'var(--primary-color) !important',
                            background: 'var(--secondary-color) !important',
                            '&.Mui-selected': {
                                background: 'var(--primary-color) !important',
                                color: 'var(--secondary-color) !important'
                            }
                        }} />
                    <Tab label="Sp. Assessments" {...a11yProps(1)}
                        sx={{
                            border: '1px solid grey',
                            borderBottom: 'none',
                            borderRadius: '5%',
                            marginRight: "2px",
                            textTransform: 'none !important',
                            color: 'var(--primary-color) !important',
                            background: 'var(--secondary-color) !important',
                            '&.Mui-selected': {
                                background: 'var(--primary-color) !important',
                                color: 'var(--secondary-color) !important'
                            }
                        }} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <div>
                        <div>
                            <label>
                                <input type='checkbox' checked />
                                <span style={{ fontWeight: '600', fontSize: '14px', paddingLeft: '5px' }}>Auto Reserve Contribution for the 1-st year</span>
                            </label>
                        </div>

                        <div style={{ marginBottom: '10px', marginTop: '5px' }}>
                            <label htmlFor="exampleFormControlInput1" className="form-label">Percent change over 30 years</label>
                            <select className="form-select" style={{ fontSize: '14px' }} onChange={props.handlePercentChange} value={props.percentChange} >
                                {percentageChange?.map((item, index) => {
                                    return (
                                        <option selected style={{ fontSize: '14px' }} value={item} key={index}> {item} </option>
                                    )
                                })}
    
                            </select>
                        </div>
                        {props.percentChange == 'Custom' ? (
                            <HotTable
                                ref={hotTableComponent}
                                data={props.customData}
                                columns={[
                                    {
                                        title: 'Year',
                                        type: 'text',
                                    },
                                    {
                                        title: 'Min',
                                        type: 'numeric',
                                        renderer: props.customRenderer,
                                        numericFormat: {
                                            pattern: '0.00',
                                            culture: 'en-US',
                                        },
                                    },
                                    {
                                        title: 'Max',
                                        type: 'numeric',
                                        renderer: props.customRenderer,
                                        numericFormat: {
                                            pattern: '0.00',
                                            culture: 'en-US',
                                        },
                                    }
                                ]}
                                rowHeaders={false}
                                stretchH="all"
                                height="auto"
                                afterChange={props.afterChangeCustom}
                                licenseKey="non-commercial-and-evaluation"
                            />
                        ) : (

                        <div>
                            <span style={{ fontSize: '14px' }}>Limiting values of percent change for yearly Reserve contribution</span>
                            <HotTable
                                ref={hotTableComponent}
                                data={props.defaultVale}
                                // data={defaultdata}
                                style={{ marginTop: '10px', marginBottom: '20px'}}
                                columns={[
                                    {
                                        title: 'Min',
                                        type: 'numeric',
                                        numericFormat: {
                                            pattern: '0.00',
                                            culture: 'en-US',
                                        },
                                    },
                                    {
                                        title: 'Max',
                                        type: 'numeric',
                                        numericFormat: {
                                            pattern: '0.00',
                                            culture: 'en-US',
                                        },
                                    }
                                ]}
                                afterChange={props.afterChangeDefault}
                                rowHeaders={false}
                                stretchH="all"
                                height="auto"
                                // afterChange={props.afterChange}
                                licenseKey="non-commercial-and-evaluation"
                            />
                        </div>
                        )}
                        <div className="AFP_reser_btn">
                            <button onClick={props.handleReset}>Reset to default</button>
                        </div>
                        <div className="AFP_second_input" style={{marginTop:'10px',marginBottom:'0'}}>
                            <label>Minimal Percent Funded for the last year</label>
                            <input type="number" onChange={props.handleMinimalFundBalance} defaultValue={props.minimalFundBalance}  />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                <div style={{ fontSize: '14px', marginTop:'10px' }}>
                        <label>Guarantee the required minimal Ending Balance during the years</label>
                        <select className="form-select" style={{ fontSize: '14px', width: '40%' }}  onChange={props.handleMinEndingBalanceYear} value={props.minEndingBalanceYear}>
                            {props.yearFundingPlan?.map((item, index) => {
                                return (
                                    <option selected style={{ fontSize: '14px' }} value={item} key={index}> {item} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div style={{ fontSize: '14px', marginTop:'10px' }}>
                        <label>with Special Assessments, which can be added for years</label>
                        <select className="form-select" style={{ fontSize: '14px', width: '40%' }} onChange={props.handleSpecialAssessmentAddedForYears} value={props.specialAssessmentsAddedForYears}>
                            {props.filteredSpecialAssessmentData?.map((item, index) => {
                                return (
                                    <option selected style={{ fontSize: '14px' }} value={item} key={index}> {item} </option>
                                )
                            })}
                        </select>
                    </div>

                    <div style={{ fontSize: '14px', marginTop:'20px' }}>
                        <label style={{ fontWeight: '600' }}>Funding Priority for Ending Balance</label>
                        <Slider
                            value={props.progress}
                            onChange={props.handleProgressChange}
                            min={0}
                            max={100}
                            marks={[
                                { value: 0, label: '0' },
                                { value: 10, label: '10' },
                                { value: 20, label: '20' },
                                { value: 30, label: '30' },
                                { value: 40, label: '40' },
                                { value: 50, label: '50' },
                                { value: 60, label: '60' },
                                { value: 70, label: '70' },
                                { value: 80, label: '80' },
                                { value: 90, label: '90' },
                                { value: 100, label: '100' },
                            ]}
                            step={1}
                            valueLabelDisplay="auto"
                            aria-labelledby="progress-bar"
                            style={{width:'80%'}}
                        />
                        <label>(Specifies how to fund Ending Balance: 0% - use Yearly Reserve Contributions only, 100% - add Special Assessments only)</label>
                    </div>
                </TabPanel>
            </div>
        </>
    );
}
export default FFPAdvancedFundingParameters;