
import React from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { createAndUpdateUnitsVariableTable, getUnitWiseReserveTableListing, getUnitsVariableTableListing, updateInitialParameterTable } from '../../../services/Services';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchUnitReserve, dispatchUnitsVariableData } from '../../../redux/actions/scenarioManagementAction';
import showNotification from '../../common/Notification';
function UnitsTableUpdate(props) {
    //redux hook 
    const { inpEditedData, initialParameterListingData, activeScenarioId } = useSelector((state) => state.initialParameter);
    //useStates
    //declare variables
    let unitsTableLength = props.unitsVariableParamsData?.length;
    let unitNumber = '';
    let getUnitsNumber = inpEditedData?.map((item) => { // eslint-disable-line
        if (item != 0) {
            unitNumber = item[7];
        }
    })

    //functions
    const handleClose = () => {
        props.setOpenUnitsModel(false);
    }

    const dispatch = useDispatch();
    const handleUpdateParameters = () => {
        let unitsVariableParams = {
            data: props.unitsVariableParamsData
        }
        createAndUpdateUnitsVariableTable(unitsVariableParams, activeScenarioId).then((res) => {
            // alert("createAndUpdateUnitsVariableTable");
            // props.fetchUnitsVariableList(activeScenarioId);
            getUnitsVariableTableListing(activeScenarioId).then((res) => {
                dispatch(dispatchUnitsVariableData(res?.data))
            }).catch(() => {
                showNotification("danger","Getting error while loading the resources");
            })
            getUnitWiseReserveTableListing(activeScenarioId).then((res) => {
                dispatch(dispatchUnitReserve(res?.data));
            }).catch(() => {
                showNotification("danger","Getting error while loading the resources");
            })
            return res;
        }).catch((err) => {
            console.error(err);
        })
        if (inpEditedData != undefined && inpEditedData?.length != 0) {
            let params =
            {
                "id": inpEditedData[0][0],
                "fiscal_year_start": inpEditedData[0][1],
                "fiscal_year_end": inpEditedData[0][2],
                "starting_balance": inpEditedData[0][3],
                "current_yearly_reserve_contribution": inpEditedData[0][4],
                "proposed_yearly_reserve_contribution": inpEditedData[0][5],
                "inflation": inpEditedData[0][6],
                "Number_of_units": unitsTableLength,
                "Default_interest_rate": inpEditedData[0][8],
                "Total_assessment_amount_per_month": inpEditedData[0][10],
                "scenario_id": initialParameterListingData[0]?.scenario_id,
                'delinquent_discount': inpEditedData[0][9],
                'minus_delinquent_payments': initialParameterListingData[0]?.minus_delinquent_payments
            }
        


        updateInitialParameterTable(params, activeScenarioId).then((res) => {
            props.fetchIntialParameterList(activeScenarioId)
            return res;
        }).catch((err) => {
            console.error(err);
        })
    
        props.setOpenUnitsModel(false);
    }
    }
    return (
        <>
            <Dialog
                open={props.openUnitModel}
                onClose={handleClose}
                maxWidth='lg'
                PaperProps={{
                    sx: {
                        width: "50%",
                        overflow: 'hidden !important',
                    }
                }}
            >
                <DialogTitle style={{ cursor: 'move', fontWeight: '600', borderBottom: '1px solid lightgray' }}>
                    <div className="d-flex">
                        <div style={{ width: '100%' }}>
                            Please Check the Units table
                        </div>
                        <div>
                            <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
                        </div>
                    </div>

                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <div className="edit_modal_inputs" >
                            <p> {unitsTableLength} units are defined in the Units table, but {unitNumber} units are set in Starting Parameters table.
                                Would you like to update the Starting Parameters (set Number of Units = {unitsTableLength} )</p>
                        </div>

                    </DialogContentText>



                </DialogContent>

                <DialogActions sx={{ borderTop: '1px solid lightgray' }}>
                    <div>
                        <Button className='save_button' autoFocus onClick={() => props.setOpenUnitsModel(false)} >
                            Continue editing
                        </Button>
                    </div>
                    <div>
                        <Button className='save_button' autoFocus onClick={handleUpdateParameters}>
                            Update Starting Parameters
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default UnitsTableUpdate;