import User from '../../configurations/Constant'

const initialState = {
    initialParameterListingData: [],
    inpEditedData: [],
    componentListingData: [],
    comEditedData: [],
    activeScenarioId: '',
    satOneData: [],
    satTwoData: [],
    satThreeData: [],
    satYearData: [],
    unitsVariableData: [],
    unitsVariableEditedData: [],
    unitvariablerows: {
        "new_data": [],
        "index": ''
    },
    loansAndExpData: [],
    loansAndExpEditedData: [],
    mceData: [],
    mceEditedData: [],
    unitreserve: [],
    unitreservetablecount: [],
    unitreserverows: {
        "number_of_rows": '',
        "Omit_button_enable": '',
        "new_data": [],
        "index": ''
    },
    community_id: '',

}

const initialParameterReducer = (state = initialState, action) => {
    switch (action.type) {
        case User.INITIALPARAMETERLISTINGDATA:
            return {
                ...state,
                initialParameterListingData: action.initialParameterListingData,
            };
        case User.INPEDITEDDATA:
            return {
                ...state,
                inpEditedData: action.inpEditedData,
            };
        case User.COMPONENTSLISTINGDATA:
            return {
                ...state,
                componentListingData: action.componentListingData,
            };
        case User.COMEDITEDDATA:
            return {
                ...state,
                comEditedData: action.comEditedData,
            };
        case User.ACTIVESCENARIOID:
            return {
                ...state,
                activeScenarioId: action.activeScenarioId,
            };

        case User.SATONEDATA:
            return {
                ...state,
                satOneData: action.satOneData,
            };
        case User.SATTWODATA:
            return {
                ...state,
                satTwoData: action.satTwoData,
            };
        case User.SATTHREEDATA:
            return {
                ...state,
                satThreeData: action.satThreeData,
            };
        case User.SATYEARDATA:
            return {
                ...state,
                satYearData: action.satYearData,
            };
        case User.UNITSVARIABLEDATA:
            return {
                ...state,
                unitsVariableData: action.unitsVariableData,
            };
        case User.UNITSVARIABLEEDITEDDATA:
            return {
                ...state,
                unitsVariableEditedData: action.unitsVariableEditedData,
            };
        case User.UNITSVARIABLEROWS:
            return {
                ...state,
                unitvariablerows: action.unitvariablerows,
            };

        case User.LOANSANDEXPDATA:
            return {
                ...state,
                loansAndExpData: action.loansAndExpData,
            };
        case User.LOANSANDEXPEDITEDDATA:
            return {
                ...state,
                loansAndExpEditedData: action.loansAndExpEditedData,
            };
        case User.MCEDATA:
            return {
                ...state,
                mceData: action.mceData,
            };
        case User.MCEEDITEDDATA:
            return {
                ...state,
                mceEditedData: action.mceEditedData,
            };
        case User.UNITRESERVE:
            return {
                ...state,
                unitreserve: action.unitreserve,
            };
        case User.UNITRESERVETABLECOUNT:
            return {
                ...state,
                unitreservetablecount: action.unitreservetablecount,
            };
        case User.UNITRESERVEROWS:
            return {
                ...state,
                unitreserverows: action.unitreserverows,
            };
        case User.COMMUNITYID:
            return {
                ...state,
                community_id: action.community_id,
            };
        default:
            return state;
    }
};

export default initialParameterReducer;